import {extractPlainTextFromMdastNode} from './markdown-util'
import {IndexedSoundMarker} from '../model/IndexedSoundMarker'
import {extractSoundMarkerFromMdastNode} from './sound-marker-markdown-util'
// @ts-ignore
import visit from 'unist-util-visit'
import {TocEntry} from '../components/SheetStructureList'
// @ts-ignore
import GithubSlugger from 'github-slugger'

export function extractSheetTitle(sheetNode: any) {
  const firstLevelHeading = sheetNode.children
    .find((n: any) => n.type === 'heading' && n.depth === 1)
  if (!firstLevelHeading) {
    return undefined
  }
  return extractPlainTextFromMdastNode(firstLevelHeading)
}

export function extractSheetSoundMarkers(sheetNode: any) {
  const soundMarkers: IndexedSoundMarker[] = []
  let i = 0
  visit(sheetNode, 'link', (linkNode: any) => {
    const soundMarker = extractSoundMarkerFromMdastNode(linkNode)
    if (!soundMarker) {
      return
    }
    const indexedSoundMarker: IndexedSoundMarker = {
      ...soundMarker,
      index: i
    }
    soundMarkers.push(indexedSoundMarker)
    i += 1
  })
  return soundMarkers
}

export function extractSheetTocEntries(sheetNode: any) {
  const slugger = new GithubSlugger()
  const headingNodes = sheetNode.children
    .filter((n: any) =>
      n.type === 'heading' && n.depth >= 2 && n.children && n.children[0] && n.children[0].type === 'text'
    )
  const rootEntry: TocEntry = {
    label: '',
    elementId: '',
    subEntries: []
  }
  const entryStack = [rootEntry]
  headingNodes.forEach((headingNode: any) => {
    // normalizedDepth >= 1
    const normalizedDepth = headingNode.depth - 1
    const currentSubEntries = entryStack[entryStack.length - 1].subEntries
    const currentHeadingIsOneLevelHigherThanPreviousOne =
      normalizedDepth === entryStack.length + 1 && currentSubEntries.length > 0
    if (currentHeadingIsOneLevelHigherThanPreviousOne) {
      // Go down one level. From now on push entries as children of previous entry.
      entryStack.push(currentSubEntries[currentSubEntries.length - 1])
    } else if (normalizedDepth < entryStack.length) {
      // Go up some levels
      const difference = entryStack.length - normalizedDepth
      for (let i = 0; i < difference; i++) {
        entryStack.pop()
      }
    }
    const heading = headingNode.children[0].value
    const tocEntry = {
      label: heading,
      elementId: slugger.slug(heading),
      subEntries: []
    }
    if (normalizedDepth <= entryStack.length || currentHeadingIsOneLevelHigherThanPreviousOne) {
      entryStack[entryStack.length - 1].subEntries.push(tocEntry)
    }
  })
  return rootEntry.subEntries
}