// @ts-ignore
import lowClickFile from './sounds/click-low-01.mp3'
// @ts-ignore
import highClick1File from './sounds/click-high-01.mp3'
// @ts-ignore
import highClick3File from './sounds/click-high-03.mp3'
// @ts-ignore
import highClick4File from './sounds/click-high-04.mp3'
// @ts-ignore
import highClick5File from './sounds/click-high-05.mp3'

const lowClick = new Audio(lowClickFile)
const highClicks = [highClick1File, undefined, highClick3File, highClick4File, highClick5File]
  .map(file => file ? new Audio(file) : undefined)

export function playLowClick() {
  lowClick.play()
}

export function playHighClick(times: number = 1) {
  const highClick = highClicks[times - 1]
  if (!highClick) {
    return
  }
  highClick.play()
}