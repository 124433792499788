import * as actions from './actions'
import {ActionType, getType} from 'typesafe-actions'
import {PlayerState} from './PlayerState'
import {Reducer} from 'redux'
import {PlayState} from '../../services/SoundPlayer'
import {SoundMarkerListType} from '../../model/SoundMarkerListType'
import {IndexedSoundMarkerWithSheetListInfo} from '../../model/IndexedSoundMarkerWithListInfo'

type PlayerAction = ActionType<typeof actions>

const initialState: PlayerState = {
  soundLoaded: false,
  loading: false,
  playing: false,
  publicSoundAddress: undefined,
  typedPublicSoundAddress: '',
  streamingSoundUrl: undefined,
  soundLengthInSeconds: 0,
  soundPositionInSeconds: 0,
  soundMetadata: {},
  playbackRate: 1,
  volume: 100,
  currentPlaylistType: undefined,
  activeSheetSoundMarker: undefined,
  activeDeviceSoundMarker: undefined,
  offlineSoundAddresses: [],
  failedSoundAddresses: [],
  isDownloadingSounds: false,
  soundDownloadProgress: undefined,
  offlineSoundQueue: [],
}

export const playerReducer: Reducer<PlayerState, PlayerAction> = (state = initialState, action) => {
  switch (action.type) {
    case getType(actions.publicSoundAddressTyped):
      return {
        ...state,
        typedPublicSoundAddress: action.payload.address
      }
    case getType(actions.streamingSoundUrlChanged):
      return {
        ...state,
        streamingSoundUrl: action.payload.url
      }
    case getType(actions.soundLoading):
      return {
        ...state,
        loading: true,
        soundLengthInSeconds: 0,
        soundMetadata: {}
      }
    case getType(actions.soundLoaded):
      return {
        ...state,
        soundLoaded: true,
        loading: false,
        soundLengthInSeconds: action.payload.soundLengthInSeconds
      }
    case getType(actions.soundLoadingFailed):
      return {
        ...state,
        soundLoaded: false,
        loading: false,
        soundLengthInSeconds: 0
      }
    case getType(actions.playbackRateChanged):
      return {
        ...state,
        playbackRate: action.payload.playbackRate
      }
    case getType(actions.volumeChanged):
      return {
        ...state,
        volume: action.payload.volume
      }
    case getType(actions.soundMetadataLoaded):
      return {
        ...state,
        soundMetadata: action.payload.soundMetadata
      }
    case getType(actions.playStateChanged):
      return {
        ...state,
        playing: action.payload.playState === PlayState.Playing
      }
    case getType(actions.playPositionChanged):
      if (state.loading) {
        return state
      }
      return {
        ...state,
        soundPositionInSeconds: action.payload.playPositionInSeconds
      }
    case getType(actions.switchedBackToOtherMarkerList):
      return {
        ...state,
        publicSoundAddress: action.payload.continueFrom.publicSoundAddress,
        typedPublicSoundAddress: action.payload.continueFrom.publicSoundAddress,
        soundPositionInSeconds: action.payload.continueFrom.positionInSeconds,
        currentPlaylistType: action.payload.newList,
        positionBeforeMarkerListSwitch: action.payload.positionOnCurrentMarkerList
      }
    case getType(actions.soundMarkerActivated):
      const {soundMarker, changeListAndPosition, positionBeforeMarkerListSwitch, playlistType} = action.payload
      const commonState: PlayerState = changeListAndPosition ? {
        ...state,
        publicSoundAddress: soundMarker.publicSoundAddress,
        typedPublicSoundAddress: soundMarker.publicSoundAddress,
        soundPositionInSeconds: soundMarker.positionInSeconds || 0,
        positionBeforeMarkerListSwitch: positionBeforeMarkerListSwitch || state.positionBeforeMarkerListSwitch,
        currentPlaylistType: changeListAndPosition ? playlistType : state.currentPlaylistType
      } : state
      if (soundMarker.listInfo && soundMarker.index !== undefined) {
        const qualifiedSoundMarker = soundMarker as IndexedSoundMarkerWithSheetListInfo
        if (soundMarker.listInfo.type === SoundMarkerListType.Sheet) {
          return {
            ...commonState,
            activeSheetSoundMarker: qualifiedSoundMarker
          }
        } else {
          return {
            ...commonState,
            activeDeviceSoundMarker: qualifiedSoundMarker
          }
        }
      } else {
        // URL marker. Marker information is derived from URL path.
        return commonState
      }
    case getType(actions.offlineSoundsDetected):
      return {
        ...state,
        offlineSoundAddresses: action.payload.offlineSoundAddresses
      }
    case getType(actions.offlineSoundAdded):
      return {
        ...state,
        offlineSoundAddresses: [...state.offlineSoundAddresses, action.payload.publicSoundAddress]
      }
    case getType(actions.offlineSoundRemoved):
      return {
        ...state,
        offlineSoundAddresses: state.offlineSoundAddresses.filter(it => it !== action.payload.publicSoundAddress)
      }
    case getType(actions.soundDownloadFailed):
      return {
        ...state,
        failedSoundAddresses: [...state.failedSoundAddresses, action.payload.publicSoundAddress]
      }
    case getType(actions.soundDownloadStateChanged):
      return {
        ...state,
        isDownloadingSounds: action.payload.isDownloading
      }
    case getType(actions.soundDownloadProgressed):
      return {
        ...state,
        soundDownloadProgress: action.payload.progress
      }
    case getType(actions.soundsEnqueuedForOffline):
      return {
        ...state,
        offlineSoundQueue: [
          ...action.payload.publicSoundAddresses.filter(a =>
            !state.offlineSoundQueue.includes(a) && !state.offlineSoundAddresses.includes(a)
          ).reverse(),
          ...state.offlineSoundQueue,
        ]
      }
    case getType(actions.offlineSoundDequeued):
      return {
        ...state,
        offlineSoundQueue: state.offlineSoundQueue.filter(a => a !== action.payload.publicSoundAddress)
      }
    case getType(actions.cancelSoundDownload):
      return {
        ...state,
        offlineSoundQueue: []
      }
    default:
      return state
  }
}