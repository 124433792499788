// @ts-ignore
import parseDomain from 'parse-domain'

// Returns undefined if there's no subdomain or if subdomain is wwww
export function getCurrentSpace(): string | undefined {
  const domain = parseDomain(window.location.href, {
    customTlds: ['localhost']
  })
  if (!domain) {
    return undefined
  }
  if (!domain.subdomain) {
    return undefined
  }
  const firstPartOfSubDomain = untilFirstDot(domain.subdomain)
  if (firstPartOfSubDomain === 'www') {
    return undefined
  }
  return firstPartOfSubDomain
}

export function isLocalSpace(space?: string) {
  return space === undefined
}

function untilFirstDot(text: string) {
  const firstDotIndex = text.indexOf('.')
  return firstDotIndex === -1 ? text : text.substring(0, firstDotIndex)
}