/* eslint import/no-webpack-loader-syntax: off */
import {helpSheetId} from '../../../util/sheet-ids'
// @ts-ignore
import initialHomeSectionsMarkdown from '!raw-loader!./initial-home-sections.md'
// @ts-ignore
import aboutIntroSectionMarkdown from '!raw-loader!./about-intro-section.md'
// @ts-ignore
import aboutGetStartedSectionMarkdown from '!raw-loader!./get-started-section.md'
// @ts-ignore
import aboutMainSectionsMarkdown from '!raw-loader!./about-main-sections.md'
// @ts-ignore
import helpSectionsMarkdown from '!raw-loader!./help-sections.md'

const appVersion = process.env.REACT_APP_VERSION

const appInfoSectionMarkdown = `
## About
- **Version**: ${appVersion}
`

export function getBuiltInSheetContent(sheetId: string): string | undefined {
  switch (sheetId) {
    case helpSheetId:
      return [
        '# Help',
        aboutIntroSectionMarkdown,
        aboutMainSectionsMarkdown,
        helpSectionsMarkdown,
        appInfoSectionMarkdown
      ].join('\n\n')
    default:
      return undefined
  }
}

export function getWelcomeSheetContent() {
  return [
    '# Welcome to Jam Pad',
    aboutIntroSectionMarkdown,
    aboutGetStartedSectionMarkdown,
    aboutMainSectionsMarkdown,
    appInfoSectionMarkdown
  ].join('\n\n')
}

export function getInitialSheetContent(sheetId: string) {
  return [
    `# ${capitalizeFirstLetter(sheetId)}`,
    'Please adjust the content of your new sheet as you please!'
  ].join('\n\n')
}

export function getInitialHomeSheetContent() {
  return [
    '# Home',
    initialHomeSectionsMarkdown
  ].join('\n\n')
}

function capitalizeFirstLetter(text: string) {
  return text.charAt(0).toUpperCase() + text.slice(1)
}