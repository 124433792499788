import {concat, fromEvent, merge, Observable, of} from 'rxjs'
import {distinctUntilChanged, ignoreElements, map, shareReplay, switchMap} from 'rxjs/operators'
import {ControllerConnectionState} from '../../model/controller/ControllerConnectionState'
import {ControllerButton} from '../../model/controller/ControllerButton'
import {ControllerTouchpadAxis} from '../../model/controller/ControllerTouchpadAxis'
import {
  ControllerChangeEvent,
  createButtonEvent,
  createConnectionEvent,
  createTouchpadEvent
} from '../../model/controller/ControllerChangeEvent'

export const daydreamServiceUuid = '0000fe55-0000-1000-8000-00805f9b34fb'
const daydreamCharacteristicUuid = '00000001-1000-1000-8000-00805f9b34fb'

export function obtainEventsFromDaydreamController(connectedGattServer$: Observable<BluetoothRemoteGATTServer>): Observable<ControllerChangeEvent> {
  const daydreamService$ = connectedGattServer$.pipe(
    switchMap(gatt => gatt.getPrimaryService(daydreamServiceUuid))
  )
  const daydreamCharacteristic$ = daydreamService$.pipe(
    switchMap(dev => dev.getCharacteristic(daydreamCharacteristicUuid)),
    shareReplay()
  )
  const notificationsStarted = daydreamCharacteristic$.pipe(
    switchMap(characteristic => characteristic.startNotifications()),
    ignoreElements()
  )
  const connectedEvent$ = concat(
    notificationsStarted,
    of(createConnectionEvent(ControllerConnectionState.Connected))
  )
  const rawEvents$ = daydreamCharacteristic$.pipe(
    switchMap(characteristic => fromEvent(characteristic as any, 'characteristicvaluechanged'))
  )
  const daydreamEvents$ = rawEvents$.pipe(
    map(evt => new DaydreamEvent(evt as any)),
    shareReplay()
  )
  return merge(
    connectedEvent$,
    extractButtonChangeEvents(daydreamEvents$, ControllerButton.VolumeDown, evt => evt.volumeDownIsPressed,),
    extractButtonChangeEvents(daydreamEvents$, ControllerButton.VolumeUp, evt => evt.volumeUpIsPressed),
    extractButtonChangeEvents(daydreamEvents$, ControllerButton.Home, evt => evt.homeIsPressed),
    extractButtonChangeEvents(daydreamEvents$, ControllerButton.App, evt => evt.appIsPressed),
    extractButtonChangeEvents(daydreamEvents$, ControllerButton.Touchpad, evt => evt.touchpadIsPressed),
    extractTouchpadChangeEvents(daydreamEvents$, ControllerTouchpadAxis.X, evt => evt.touchpadX),
    extractTouchpadChangeEvents(daydreamEvents$, ControllerTouchpadAxis.Y, evt => evt.touchpadY),
  )
}

class DaydreamEvent {
  private readonly value: DataView

  constructor(event: Event) {
    const target = event.target as any
    this.value = target.value
  }

  get touchpadIsPressed() {
    return extractBit(this.getByte(18), 0)
  }

  get homeIsPressed() {
    return extractBit(this.getByte(18), 1)
  }

  get appIsPressed() {
    return extractBit(this.getByte(18), 2)
  }

  get volumeDownIsPressed() {
    return extractBit(this.getByte(18), 3)
  }

  get volumeUpIsPressed() {
    return extractBit(this.getByte(18), 4)
  }

  get touchpadX() {
    const byte16 = this.getByte(16)
    const byte17 = this.getByte(17)
    return (((byte16 & 0x1f) << 3) | ((byte17 & 0xe0) >> 5)) / 255
  }

  get touchpadY() {
    const byte17 = this.getByte(17)
    const byte18 = this.getByte(18)
    return (((byte17 & 0x1f) << 3) | ((byte18 & 0xe0) >> 5)) / 255
  }

  private getByte(byteIndex: number) {
    return this.value.getUint8(byteIndex)
  }
}

function extractButtonChangeEvents(
  daydreamEvents: Observable<DaydreamEvent>,
  button: ControllerButton,
  obtainButtonIsPressed: (evt: DaydreamEvent) => boolean
) {
  return daydreamEvents.pipe(
    map(obtainButtonIsPressed),
    distinctUntilChanged(),
    map(buttonIsPressed => createButtonEvent(button, buttonIsPressed))
  )
}

function extractTouchpadChangeEvents(
  daydreamEvents: Observable<DaydreamEvent>,
  axis: ControllerTouchpadAxis,
  obtainValue: (evt: DaydreamEvent) => number
) {
  return daydreamEvents.pipe(
    map(obtainValue),
    distinctUntilChanged(),
    map(value => createTouchpadEvent(axis, value))
  )
}


function extractBit(byte: number, bitIndex: number): boolean {
  const mask = 1 << bitIndex
  return (byte & mask) !== 0
}