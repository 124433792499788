import * as localforage from 'localforage'
import {SoundStreamSource} from '../model/SoundStreamSource'
import {Observable, Subject} from 'rxjs'
import {buildStreamUrlFromGoogleDriveUrl, isGoogleDriveUrl} from '../util/cloud/google-drive'
import {buildStreamUrlFromDropboxUrl, isDropboxUrl} from '../util/cloud/dropbox'
import {tryParseUrl} from '../util/url-util'

const offlineSoundsStore = localforage.createInstance({
  // TODO Rename to "files"
  name: 'sounds'
})

export async function soundIsAvailableOffline(publicAddress: string): Promise<boolean> {
  const offlineAddresses = await getOfflineSoundAddresses()
  return offlineAddresses.indexOf(publicAddress) !== -1
}

export async function getOfflineSoundAddresses(): Promise<string[]> {
  return await offlineSoundsStore.keys()
}

// Some URLs, for example Googl Drive direct downloads, don't like streaming. It works at first but there are very low
// per-file or global quotas. The bad thing is that as soon as the quota is exceeded, *everybody* needs to wait until
// Google decides to lift the quota again, which can take minutes, hours, ...
// So it's more safe to make the file available offline first and then play it.
export function urlIsKnownToHaveQuotaIssues(url: URL): boolean {
  return url.host === "www.googleapis.com"
}

const offlineSoundAddedSubject = new Subject<string>()
const offlineSoundRemovedSubject = new Subject<string>()
const offlineSoundsPurgedSubject = new Subject<void>()
const blobUrlByPublicAddress = new Map<string, URL>()

export async function determineStreamSource(publicAddress: string): Promise<SoundStreamSource> {
  if (await soundIsAvailableOffline(publicAddress)) {
    const blob = await offlineSoundsStore.getItem(publicAddress) as Blob
    let blobUrl = blobUrlByPublicAddress.get(publicAddress)
    if (!blobUrl) {
      blobUrl = new URL(URL.createObjectURL(blob))
      blobUrlByPublicAddress.set(publicAddress, blobUrl)
    }
    return {
      url: blobUrl,
      blob
    }
  } else {
    return {
      url: buildRemoteStreamUrl(publicAddress)
    }
  }
}

export async function purgeOfflineCache() {
  await offlineSoundsStore.clear()
  offlineSoundsPurgedSubject.next()
}

export async function removeOfflineSound(publicSoundAddress: string) {
  await offlineSoundsStore.removeItem(publicSoundAddress)
  offlineSoundRemovedSubject.next(publicSoundAddress)
}

export function downloadSound(publicAddress: string): Observable<number> {
  return new Observable(subscriber => {
    const streamUrl = buildRemoteStreamUrl(publicAddress)
    const xhr = new XMLHttpRequest()
    xhr.open('GET', streamUrl.toString())
    xhr.responseType = 'arraybuffer'
    xhr.onprogress = ev => {
      const progress = ev.lengthComputable ? Math.floor(100 * ev.loaded / ev.total) : undefined
      subscriber.next(progress)
    }
    xhr.onload = () => {
      if (xhr.status !== 200) {
        subscriber.error(`Download failed with status ${xhr.status}`)
        return
      }
      const contentType = xhr.getResponseHeader('content-type') || 'audio/mp3'
      const blob = new Blob([xhr.response], {type: contentType})
      saveBlobInLocalForage(publicAddress, blob)
      subscriber.complete()
    }
    xhr.onerror = () => {
      subscriber.error('Download failed')
    }
    xhr.ontimeout = () => {
      subscriber.error('Download failed because of timeout')
    }
    xhr.onabort = () => {
      subscriber.error('Download aborted')
    }
    xhr.send()
    return () => {
      if (xhr.readyState !== XMLHttpRequest.DONE) {
        xhr.abort()
      }
    }
  })
}

export function offlineSoundAdded() {
  return offlineSoundAddedSubject.asObservable()
}

export function offlineSoundRemoved() {
  return offlineSoundRemovedSubject.asObservable()
}

export function offlineSoundsPurged() {
  return offlineSoundsPurgedSubject.asObservable()
}

function saveBlobInLocalForage(publicSoundAddress: string, blob: Blob) {
  offlineSoundsStore.setItem(publicSoundAddress, blob).then(() => {
    offlineSoundAddedSubject.next(publicSoundAddress)
  })
}

function buildRemoteStreamUrl(publicAddress: string): URL {
  function fromArbitraryUrl(url: URL): URL {
    if (isGoogleDriveUrl(url)) {
      return buildStreamUrlFromGoogleDriveUrl(url)
    } else if (isDropboxUrl(url)) {
      return buildStreamUrlFromDropboxUrl(url)
    } else {
      return url
    }
  }

  const publicUrl = tryParseUrl(publicAddress)
  if (!publicUrl) {
    throw new Error('Not a valid URL')
  }
  try {
    return fromArbitraryUrl(publicUrl)
  } catch (error) {
    return publicUrl
  }
}