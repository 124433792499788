import {ApplicationState} from '../store/ApplicationState'
import {connect, Omit} from 'react-redux'
import {DataProps, ThemedApp} from '../components/ThemedApp'
import {currentThemeSelector} from '../store/settings/selectors'

function mapStateToProps(state: ApplicationState): Omit<DataProps, 'history'> {
  return {
    theme: currentThemeSelector(state),
  }
}

export const ThemedAppContainer = connect(mapStateToProps)(ThemedApp)