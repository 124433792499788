/* eslint import/no-webpack-loader-syntax: off */
import {SoundMarker} from '../../model/SoundMarker'
import {template} from 'lodash/fp'
// @ts-ignore
import reaperItemTemplate from '!raw-loader!./reaper-item-template.RTrackTemplate'

export function generateReaperItemChunk(
  soundMarker: SoundMarker, markerName: string, soundFileName: string, teaserLengthInSeconds: number) {
  const startPosInSeconds = soundMarker.positionInSeconds || 0
  return template(reaperItemTemplate)({
    markerName,
    soundFileName,
    startPosInSeconds,
    lengthInSeconds: soundMarker.regionLengthInSeconds || teaserLengthInSeconds
  })
}