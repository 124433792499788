import {SoundStreamSource} from '../model/SoundStreamSource'
import {SoundMetadata} from '../model/SoundMetadata'
import {Observable} from 'rxjs'
// @ts-ignore
import jsmediatags from 'jsmediatags'

export function fetchSoundMetadata(source: SoundStreamSource): Observable<SoundMetadata> {
  return new Observable(subscriber => {
    jsmediatags.read(source.blob || source.url.toString(), {
      onSuccess: (tag: any) => {
        const tags = tag.tags
        subscriber.next({
          title: tags.title,
          artist: tags.artist,
          album: tags.album,
          track: tags.track
        })
        subscriber.complete()
      },
      onError: (error: any) => {
        subscriber.error(error)
      }
    })
  })
}