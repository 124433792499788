import {ApplicationState} from '../store/ApplicationState'
import {connect} from 'react-redux'
import {Dispatch} from 'redux'
import {DataProps, DispatchProps, SeekSliderPanel} from '../components/SeekSliderPanel'
import {seek} from '../store/player/actions'
import {
  activeSoundMarkerInCurrentSoundSelector,
  currentPlaylistTypeSelector,
  deviceSoundMarkersInCurrentSoundSelector,
  currentOrAllSheetSoundMarkersInCurrentSoundSelector,
  soundLengthSelector,
  soundPositionSelector
} from '../store/player/selectors'
import {Duration} from 'js-joda'

function mapStateToProps(state: ApplicationState): DataProps {
  return {
    soundLoaded: state.player.soundLoaded,
    loading: state.player.loading,
    soundLength: soundLengthSelector(state),
    currentSoundPosition: state.layout.screenIsVisible ? soundPositionSelector(state) : Duration.ZERO,
    activeSoundMarker: activeSoundMarkerInCurrentSoundSelector(state),
    currentPlaylistType: currentPlaylistTypeSelector(state),
    soundMarkersInSheet: currentOrAllSheetSoundMarkersInCurrentSoundSelector(state),
    soundMarkersOnDevice: deviceSoundMarkersInCurrentSoundSelector(state),
  }
}

function mapDispatchToProps(dispatch: Dispatch): DispatchProps {
  return {
    seekToSoundPosition: soundPosition => dispatch(seek({playPositionInSeconds: soundPosition.seconds()})),
  }
}

export const SeekSliderPanelContainer = connect(mapStateToProps, mapDispatchToProps)(SeekSliderPanel)