import * as actions from './actions'
import {ActionType, getType} from 'typesafe-actions'
import {Reducer} from 'redux'
import {SheetState} from './SheetState'

type SheetAction = ActionType<typeof actions>

const initialState: SheetState = {
  sheetContent: undefined,
  sheetIsLoading: false,
  expandedSectionElementIds: [],
  sheetInfos: {},
  sheetInfosAreLoading: true,
  scrollTop: 0,
}

export const sheetReducer: Reducer<SheetState, SheetAction> = (state = initialState, action) => {
  switch (action.type) {
    case getType(actions.sheetLoadingStarted):
      return {
        ...state,
        sheetIsLoading: true
      }
    case getType(actions.sheetContentChanged):
    case getType(actions.visibleSheetChanged):
      return {
        ...state,
        sheetContent: action.payload.sheetContent,
        sheetIsLoading: false,
        visibleSheetId: action.payload.sheetId,
        expandedSectionElementIds: []
      }
    case getType(actions.sheetContentCleared):
      return {
        ...state,
        sheetContent: undefined,
        sheetIsLoading: false,
        visibleSheetId: undefined,
        expandedSectionElementIds: []
      }
    case getType(actions.toggleSectionExpanded):
      const elementId = action.payload.sectionElementId
      const sectionExpanded = state.expandedSectionElementIds.indexOf(elementId) >= 0
      return {
        ...state,
        expandedSectionElementIds: sectionExpanded
          ? state.expandedSectionElementIds.filter(it => it !== elementId)
          : [...state.expandedSectionElementIds, elementId]
      }
    case getType(actions.focusedLineChanged):
      return {
        ...state,
        focusedLineNumber: action.payload
      }
    case getType(actions.sheetInfosLoadingFinished):
      return {
        ...state,
        sheetInfosAreLoading: false
      }
    case getType(actions.sheetInfoUpdated):
      return {
        ...state,
        sheetInfos: {
          ...state.sheetInfos,
          [action.payload.sheetId]: action.payload.info
        }
      }
    case getType(actions.scrollTopChanged):
      return {
        ...state,
        scrollTop: action.payload.scrollTop
      }
    default:
      return state
  }
}