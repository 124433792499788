const GOOGLE_DRIVE_FILE_URL_REGEX = /https:\/\/drive.google.com\/file\/d\/(.+)\/view.*/
const GOOGLE_DRIVE_FOLDER_URL_REGEX = /https:\/\/drive.google.com\/drive\/folders\/([^?]+).*/
const FOLDER_MIME_TYPE = 'application/vnd.google-apps.folder'
const apiKey = process.env.REACT_APP_GOOGLE_DRIVE_API_KEY

export function isGoogleDriveUrl(url: URL) {
  return url.host === 'drive.google.com'
}

export function buildStreamUrlFromGoogleDriveUrl(url: URL): URL {
  let id = tryToExtractIdFromShareableLinkUrl(url)
  if (!id) {
    id = tryToExtractIdFromFileUrl(url)
  }
  if (!id) {
    return url
  }
  return createDirectDownloadGoogleDriveUrl(id)
}

// File/folder URLs shared via "Get shareable link" have the form "https://drive.google.com/open?id=<ID>"
function tryToExtractIdFromShareableLinkUrl(url: URL) {
  return url.searchParams.get('id') || undefined
}

// File URLs shared via "Share..." have the form "https://drive.google.com/file/d/<ID>/view?usp=sharing
function tryToExtractIdFromFileUrl(url: URL) {
  const matches = url.toString().match(GOOGLE_DRIVE_FILE_URL_REGEX)
  if (!matches) {
    return undefined
  }
  return matches[1]
}

function extractIdFromFolderUrl(fileUrl: URL) {
  const matches = fileUrl.toString().match(GOOGLE_DRIVE_FOLDER_URL_REGEX)
  if (!matches) {
    return undefined
  }
  return matches[1]
}

export function isGoogleDriveFolderUrl(url: URL) {
  // Folder URLs shared via "Share..." have the form "https://drive.google.com/drive/folders/<ID>?usp=sharing
  return url.toString().match(GOOGLE_DRIVE_FOLDER_URL_REGEX) !== null
}

export interface GoogleDriveDocument {
  id: string
  name: string
  mimeType: string
}

export async function getGoogleDriveFilesFromFolderUrl(url: URL): Promise<GoogleDriveDocument[]> {
  const id = extractIdFromFolderUrl(url)
  if (!id) {
    return []
  }
  return getGoogleDriveFilesInFolder(id)
}

async function getGoogleDriveFilesInFolder(folderId: string): Promise<GoogleDriveDocument[]> {
  const allChildren = await accumulateGoogleDriveFolderEntries(folderId, [], undefined)
  return allChildren.filter(e => e.mimeType !== FOLDER_MIME_TYPE)
}

async function accumulateGoogleDriveFolderEntries(
  folderId: string, accumulatedEntries: GoogleDriveDocument[], pageToken: string | undefined): Promise<GoogleDriveDocument[]> {
  const url = `https://www.googleapis.com/drive/v3/files?q=%27${folderId}%27+in+parents&orderBy=name&key=${apiKey}&pageToken=${pageToken || ''}`
  const response = await fetch(url)
  const result = await response.json()
  const entries = result.files as GoogleDriveDocument[]
  const newAccumulatedEntries = [
    ...accumulatedEntries,
    ...entries
  ]
  const nextPageToken = result.nextPageToken as string | undefined
  if (nextPageToken) {
    return accumulateGoogleDriveFolderEntries(folderId, newAccumulatedEntries, nextPageToken)
  } else {
    return newAccumulatedEntries
  }
}

export function createPublicGoogleDriveAddress(docId: string): URL {
  return new URL(`https://drive.google.com/file/d/${docId}/view?usp=sharing`)
}

function createDirectDownloadGoogleDriveUrl(docId: string) {
  return new URL(`https://www.googleapis.com/drive/v3/files/${docId}?key=${apiKey}&alt=media`)
}