import {ApplicationState} from '../store/ApplicationState'
import {connect} from 'react-redux'
import {Dispatch} from 'redux'
import {DataProps, DispatchProps, SheetEditor} from '../components/SheetEditor'
import {requestedSheetIdSelector, sheetContentSelector} from '../store/sheet/selectors'
import {updateSheetContent} from '../store/sheet/actions'
import {allSoundMarkersDeleted} from '../store/marker/actions'
import {newSoundMarkerAtCurrentPositionSelector} from '../store/player/selectors'

function mapStateToProps(state: ApplicationState): DataProps {
  return {
    text: sheetContentSelector(state),
    // TODO Is this a good idea? This updates like every second but is only used when inserting
    // sound marker link.
    newSoundMarkerAtCurrentPosition: newSoundMarkerAtCurrentPositionSelector(state),
    soundMarkers: state.marker.soundMarkers,
    focusedLineNumber: state.sheet.focusedLineNumber,
    sheetId: requestedSheetIdSelector(state),
  }
}

function mapDispatchToProps(dispatch: Dispatch): DispatchProps {
  return {
    contentChanged: (sheetId, content, change) => dispatch(updateSheetContent({
      sheetId,
      sheetContent: content,
      change
    })),
    deleteAllSoundMarkers: () => dispatch(allSoundMarkersDeleted())
  }
}

export const SheetEditorContainer = connect(mapStateToProps, mapDispatchToProps)(SheetEditor)