/* eslint import/no-webpack-loader-syntax: off */
import {SoundMarker} from '../../model/SoundMarker'
import {template} from 'lodash/fp'
// @ts-ignore
import liveClipTemplate from '!raw-loader!./live-clip-template.xml'

export function generateLiveClipXml(
  soundMarker: SoundMarker, markerName: string, soundFileName: string, teaserLengthInSeconds: number) {
  const startPosInSeconds = soundMarker.positionInSeconds || 0
  return template(liveClipTemplate)({
    markerName,
    soundFileName,
    startPosInSeconds,
    endPosInSeconds: startPosInSeconds + (soundMarker.regionLengthInSeconds || teaserLengthInSeconds)
  })
}