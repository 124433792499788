import {generatePath, matchPath} from 'react-router'
import {homeSheetId} from '../util/sheet-ids'

export enum Routes {
  Home = '/',
  SoundMarker = '/play/:publicSoundAddress/:markerExpression?',
  SheetView = '/sheets/:sheetId',
  SheetEdit = '/sheets/:sheetId/edit',
  CreateHomeSheet = '/create-home-sheet',
}

export function createSheetViewPath(sheetId: string) {
  if (sheetId === homeSheetId) {
    return '/'
  }
  return generatePath(Routes.SheetView, {sheetId})
}

export function createSheetEditPath(sheetId: string) {
  return generatePath(Routes.SheetEdit, {sheetId})
}

export function extractSheetIdFromPath(path: string) {
  if (path === '/' || path === '/index.html') {
    return homeSheetId
  }
  const match = matchPath<any>(path, {path: Routes.SheetView}) ||
    matchPath<any>(path, {path: Routes.SheetEdit})
  if (!match) {
    return undefined
  }
  return match.params.sheetId as string
}