import * as React from 'react'
import {Component} from 'react'
import {createStyles, IconButton, TextField, Theme, Tooltip, withStyles, WithStyles} from '@material-ui/core'
import {ControlRow} from './ControlRow'
import PasteIcon from '@material-ui/icons/InsertDriveFile'

export interface DataProps {
  address: string
}

export interface DispatchProps {
  addressChanged: (address: string) => void
  commit: () => void
}

const styles = (theme: Theme) => createStyles({
  root: {
    backgroundColor: theme.palette.common.white,
    border: '1px solid #ced4da',
    borderRadius: 4,
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    '&:focus': {
      borderColor: '#80bdff',
      boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
    }
  },
  textFieldInput: {
    fontFamily: 'monospace',
    fontSize: 16,
    textAlign: 'center',
    padding: '8px 0 8px 10px',
  },
  button: {
    padding: '0 4px',
    opacity: 0.8,
    '&:hover': {
      backgroundColor: 'transparent',
      opacity: 1.0
    }
  },
})

class RawSoundSourcePanel extends Component<DataProps & DispatchProps & WithStyles<typeof styles>> {
  public render() {
    const classes = this.props.classes
    return (
      <ControlRow className={classes.root}>
        <TextField
          onKeyPress={this.keyPressed}
          onBlur={this.blurred}
          onChange={this.textTyped}
          fullWidth={true}
          placeholder="Paste address of MP3 CBR file here!"
          title="MP3 because it works in almost every browser, CBR because it enables accurate seeking"
          value={this.props.address}
          InputProps={{
            disableUnderline: true,
            classes: {
              input: classes.textFieldInput,
            },
          }}
        />
        <Tooltip title="Paste address from clipboard">
          <IconButton onClick={this.loadFromClipboard} className={classes.button} disableRipple={true}>
            <PasteIcon/>
          </IconButton>
        </Tooltip>
      </ControlRow>
    )
  }

  private textTyped = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.props.addressChanged(event.target.value)
  }

  private loadFromClipboard = async () => {
    const nav = navigator as any
    if (!nav.clipboard) {
      return
    }
    const textInClipboard = await nav.clipboard.readText()
    this.props.addressChanged(textInClipboard)
    this.props.commit()
  }

  private keyPressed = (ev: React.KeyboardEvent<Element>) => {
    if (ev.key === 'Enter') {
      this.props.commit()
    }
  }

  private blurred = () => {
    this.props.commit()
  }
}

export const SoundSourcePanel = withStyles(styles)(RawSoundSourcePanel)