/* eslint import/no-webpack-loader-syntax: off */
import 'hacktimer'
import './index.css'
import * as serviceWorker from './serviceWorker'
import {createBrowserHistory, History} from 'history'
import {ApplicationState} from './store/ApplicationState'
import {connectRouter, routerMiddleware} from 'connected-react-router'
import {applyMiddleware, createStore, Store} from 'redux'
import {rootEpic, rootReducer, selectors} from './store'
import {composeWithDevTools} from 'redux-devtools-extension'
import {createEpicMiddleware} from 'redux-observable'
import * as React from 'react'
import {Provider} from 'react-redux'
import * as ReactDOM from 'react-dom'
import * as soundPlayer from './services/SoundPlayer'
import * as sheetService from './services/SheetService'
import {
  handleRegionEndReached,
  offlineSoundAdded,
  offlineSoundRemoved,
  offlineSoundsDetected,
  playNextSoundMarker,
  playPositionChanged,
  playPreviousSoundMarker,
  playStateChanged,
  seekRelatively
} from './store/player/actions'
import * as soundStreamSourceService from './services/SoundStreamSourceService'
import {initSheetData, processUpdatedSheets} from './store/sheet/actions'
import {handleError, installPromptAvailable, onlineStateChanged, setScreenIsVisible} from './store/layout/actions'
import {createHistoryHashObserver} from './util/history-hash-observer'
import {checkIfOnline} from './util/layout-util'
import * as desktopService from './services/DesktopService'
import {distinctUntilChanged, map} from 'rxjs/operators'
import {magicButtonPressed} from './store/controller/actions'
// @ts-ignore
import {getStateWith, registerSelectors} from 'reselect-tools'
import {getCurrentSpace, isLocalSpace} from './util/space-util'
import {ThemedAppContainer} from './containers/ThemedAppContainer'
import * as log from 'loglevel'
// So user can change loglevel on console
import 'expose-loader?loglevel!loglevel'
import {Persistor, persistStore} from 'redux-persist'
import {PersistGate} from 'redux-persist/integration/react'

start()

function start() {
  initLogging()
  const history = createBrowserHistory()
  createHistoryHashObserver(history, 'sheet')
  const store = configureStore(history)
  const persistor = persistStore(store)
  addSupportForReselectDevtools(store)
  initLocalSpace(store)
  syncDesktopServiceToStore(store)
  syncSoundPlayerToStore(store)
  syncOfflineSoundsToStore(store)
  syncMediaKeysToStore(store)
  syncOnlineStateToStore(store)
  syncVisibilityToStore(store)
  syncSheetServiceToStore(store)
  registerConsoleCommands()
  renderDom(store, history, persistor)
  serviceWorker.register()
}

function registerConsoleCommands() {
  const win = window as any
  win.sync = sheetService.sync
}

function initLogging() {
  log.setDefaultLevel('warn')
}

function initLocalSpace(store: Store<ApplicationState>) {
  if (isLocalSpace(getCurrentSpace())) {
    store.dispatch(initSheetData())
  }
}

function addSupportForReselectDevtools(store: Store<ApplicationState>) {
  registerSelectors(selectors)
  getStateWith(() => store.getState())
}

function syncVisibilityToStore(store: Store<ApplicationState>) {
  document.addEventListener('visibilitychange', () => {
    store.dispatch(setScreenIsVisible(!document.hidden))
  })
}

async function syncOnlineStateToStore(store: Store<ApplicationState>) {
  function handleOnlineStateChange() {
    const isOnline = checkIfOnline()
    store.dispatch(onlineStateChanged({isOnline}))
    if (isOnline) {
      sheetService.sync()
    }
  }
  window.addEventListener('online', handleOnlineStateChange)
  window.addEventListener('offline', handleOnlineStateChange)
}

async function syncMediaKeysToStore(store: Store<ApplicationState>) {
  const nav = navigator as any
  if (!nav.mediaSession) {
    return
  }
  nav.mediaSession.setActionHandler('play', () => store.dispatch(magicButtonPressed()))
  nav.mediaSession.setActionHandler('pause', () => store.dispatch(magicButtonPressed()))
  nav.mediaSession.setActionHandler('seekbackward', () => store.dispatch(seekRelatively({direction: -1})))
  nav.mediaSession.setActionHandler('seekforward', () => store.dispatch(seekRelatively({direction: +1})))
  nav.mediaSession.setActionHandler('previoustrack', () => store.dispatch(playPreviousSoundMarker()))
  nav.mediaSession.setActionHandler('nexttrack', () => store.dispatch(playNextSoundMarker()))
}

async function syncOfflineSoundsToStore(store: Store<ApplicationState>) {
  const offlineSoundAddresses = await soundStreamSourceService.getOfflineSoundAddresses()
  store.dispatch(offlineSoundsDetected({offlineSoundAddresses}))
  soundStreamSourceService.offlineSoundAdded().subscribe(publicSoundAddress => {
    store.dispatch(offlineSoundAdded({publicSoundAddress}))
  })
  soundStreamSourceService.offlineSoundRemoved().subscribe(publicSoundAddress => {
    store.dispatch(offlineSoundRemoved({publicSoundAddress}))
  })
  soundStreamSourceService.offlineSoundsPurged().subscribe(publicSoundAddress => {
    store.dispatch(offlineSoundsDetected({offlineSoundAddresses: []}))
  })
}

function syncDesktopServiceToStore(store: Store<ApplicationState>) {
  desktopService.installPromptAvailable().then(() => {
    store.dispatch(installPromptAvailable())
  })
}

function syncSheetServiceToStore(store: Store<ApplicationState>) {
  sheetService.sheetsUpdated().subscribe(sheetIds => {
    store.dispatch(processUpdatedSheets({sheetIds}))
  })
}

function syncSoundPlayerToStore(store: Store<ApplicationState>) {
  soundPlayer.playStateChanged().subscribe(playState => {
    store.dispatch(playStateChanged({playState}))
  })
  soundPlayer.stalled().subscribe(playState => {
    store.dispatch(handleError('Player stalled'))
  })
  soundPlayer.playPositionChanged().pipe(
    map(playPosition => playPosition.seconds()),
    distinctUntilChanged()
  ).subscribe(playPositionInSeconds => {
    store.dispatch(playPositionChanged({playPositionInSeconds}))
  })
  soundPlayer.soundEndReached().subscribe(() => {
    store.dispatch(handleRegionEndReached())
  })
}

function renderDom(store: Store<ApplicationState>, history: History, persistor: Persistor) {
  ReactDOM.render(
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <ThemedAppContainer history={history}/>
      </PersistGate>
    </Provider>,
    document.getElementById('root')!
  )
}

function configureStore(history: History): Store<ApplicationState> {
  const epicMiddleware = createEpicMiddleware()
  const store = createStore(
    connectRouter(history)(rootReducer),
    composeWithDevTools({})(
      applyMiddleware(
        routerMiddleware(history),
        epicMiddleware
      )
    )
  )
  epicMiddleware.run(rootEpic)
  return store
}