import * as React from 'react'
import {Component} from 'react'
import {createStyles, IconButton, Theme, withStyles, WithStyles, WithTheme, withTheme} from '@material-ui/core'
import TimelapseIcon from '@material-ui/icons/Timelapse'
import VolumeUpIcon from '@material-ui/icons/VolumeUp'
import 'rc-slider/assets/index.css'
import {ControlRow} from './ControlRow'
import {ControlStack} from './ControlStack'
import Slider from 'rc-slider'

export interface DataProps {
  playbackRate: number
  volume: number
}

export interface DispatchProps {
  changePlaybackRate: (playbackRate: number) => void
  changeVolume: (volume: number) => void
}

const styles = (theme: Theme) => createStyles({
  icon: {
  },
  label: {
    width: '3em',
  },
  sliderContainer: {
    width: '95%',
    margin: '-5px 0'
  },
  slider: {
    marginRight: theme.spacing(1),
    '& .rc-slider-handle': {
      '&:active': {
        boxShadow: `0 0 5px ${theme.palette.primary.main}`
      }
    }
  },
})

class RawTuningPanel extends Component<DataProps & DispatchProps & WithStyles<typeof styles> & WithTheme> {
  public render() {
    return (
      <ControlRow>
        <ControlStack>
          {this.VolumeControls()}
        </ControlStack>
        <ControlStack>
          {this.PlaybackRateControls()}
        </ControlStack>
      </ControlRow>
    )
  }

  private PlaybackRateControls = () => {
    const classes = this.props.classes
    const theme = this.props.theme
    return (
      <ControlRow className={classes.sliderContainer}>
        <IconButton onClick={this.togglePlaybackRate}>
          <TimelapseIcon className={classes.icon}/>
        </IconButton>
        <Slider
          className={classes.slider}
          onChange={this.handlePlaybackRateSliderChanged}
          min={5}
          max={40}
          value={this.props.playbackRate * 10}
          handleStyle={{
            borderColor: theme.palette.primary.light,
          }}
          trackStyle={{
            backgroundColor: theme.palette.primary.light,
          }}
          dotStyle={{
            borderColor: theme.palette.primary.light,
          }}
          marks={{5: '', 10: '', 40: ''}}
        />
        <div className={classes.label} onWheel={this.mouseWheeledOnPlaybackRate}>
          {this.props.playbackRate.toFixed(1)}
        </div>
      </ControlRow>
    )
  }

  private VolumeControls = () => {
    const classes = this.props.classes
    const theme = this.props.theme
    return (
      <ControlRow className={classes.sliderContainer}>
        <IconButton onClick={this.toggleVolume}>
          <VolumeUpIcon className={classes.icon}/>
        </IconButton>
        <Slider
          className={classes.slider}
          onChange={this.handleVolumeSliderChanged}
          min={0}
          max={100}
          value={this.props.volume}
          handleStyle={{
            borderColor: theme.palette.primary.light,
          }}
          trackStyle={{
            backgroundColor: theme.palette.primary.light,
          }}
          dotStyle={{
            borderColor: theme.palette.primary.light,
          }}
          marks={{0: '', 100: ''}}
        />
        <div className={classes.label} onWheel={this.mouseWheeledOnVolume}>
          {this.props.volume}
        </div>
      </ControlRow>
    )
  }

  private handlePlaybackRateSliderChanged = (value: number) => {
    this.changePlaybackRate(value / 10)
  }

  private handleVolumeSliderChanged = (value: number) => {
    this.changeVolume(value)
  }

  private changePlaybackRate = (value: number) => {
    this.props.changePlaybackRate(value)
  }

  private changeVolume = (value: number) => {
    this.props.changeVolume(value)
  }

  private decreasePlaybackRate = () => {
    this.changePlaybackRate(this.props.playbackRate - 0.1)
  }

  private increasePlaybackRate = () => {
    this.changePlaybackRate(this.props.playbackRate + 0.1)
  }

  private decreaseVolume = () => {
    this.changeVolume(this.props.volume - 5)
  }

  private increaseVolume = () => {
    this.changeVolume(this.props.volume + 5)
  }

  private togglePlaybackRate = () => {
    this.changePlaybackRate(this.props.playbackRate < 1 ? 1 : (this.props.playbackRate < 2 ? 4 : 0.5))
  }

  private toggleVolume = () => {
    this.changeVolume(this.props.volume < 50 ? 100 : 0)
  }

  private mouseWheeledOnPlaybackRate = (evt: React.WheelEvent<any>) => {
    if (evt.deltaY < 0) {
      this.increasePlaybackRate()
    } else if (evt.deltaY > 0) {
      this.decreasePlaybackRate()
    }
  }

  private mouseWheeledOnVolume = (evt: React.WheelEvent<any>) => {
    if (evt.deltaY < 0) {
      this.increaseVolume()
    } else if (evt.deltaY > 0) {
      this.decreaseVolume()
    }
  }
}

export const TuningPanel = withTheme(withStyles(styles)(RawTuningPanel))