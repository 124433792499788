import * as React from 'react'
import {Component, ReactNode} from 'react'
import {createStyles, Theme, withStyles, WithStyles} from '@material-ui/core'
import {SoundMetadata} from '../model/SoundMetadata'
import {grey} from '@material-ui/core/colors'
import {ControlRow} from './ControlRow'
import {ControlStack} from './ControlStack'
import {FileMarkerIcon, formatDuration, getMarkerIconComponent, PlaylistIcon} from '../util/layout-util'
import {Duration} from 'js-joda'
import {generateMarkerTitle} from '../util/sound-marker-util'
import {SoundMarkerListType} from '../model/SoundMarkerListType'
import {LimitedSpacePanel} from './LimitedSpacePanel'
import {RatingDecorator} from './RatingDecorator'
import {IndexedSoundMarkerWithListInfo} from '../model/IndexedSoundMarkerWithListInfo'

export interface DataProps {
  metadata: SoundMetadata
  loading: boolean
  soundLength: Duration
  soundLoaded: boolean
  currentSoundPosition: Duration
  activeSoundMarker?: IndexedSoundMarkerWithListInfo
  currentPlaylistIndex?: number
  currentPlaylistSize?: number
  currentlyPlayingSheetTitle?: string
}

export interface DispatchProps {
}

const styles = (theme: Theme) => createStyles({
  info: {
    // fontFamily: 'monospace',
    color: grey[500],
    fontSize: theme.typography.subtitle2.fontSize
  },
  mainInfo: {
    width: '100%',
    justifyContent: 'center'
  },
  outerReadOnlyInput: {
    paddingBottom: 0
  },
  disabled: {
    color: theme.palette.text.disabled
  },
  soundLoading: {
    color: '#aaa'
  },
  mainInfoItem: {
    display: 'flex',
    margin: '0 1em',
    minWidth: 0,
  },
  mainInfoItemContent: {
    whiteSpace: 'nowrap',
    overflow: 'auto',
    scrollbarWidth: 'none', // Firefox
    '-ms-overflow-style': 'none', // IE 10+
    '&::-webkit-scrollbar': {
      width: 0,
      height: 0,
    },
  },
  timeInfoItem: {
    flex: '0 0 auto'
  },
  ratingBadge: {
    opacity: 0.6,
    height: '15px',
    minWidth: '15px',
    fontSize: '0.7rem',
    top: '1px',
    right: '5px',
  },
  ratingBadgeRoot: {
    minWidth: 0,
  }
})

class RawSoundInfoPanel extends Component<DataProps & DispatchProps & WithStyles<typeof styles>> {
  public render() {
    const classes = this.props.classes
    return (
      <ControlRow>
        <ControlStack className={classes.timeInfoItem} style={{alignItems: 'flex-start'}}>
          <span className={classes.info}>
            {
              this.props.loading
                ? this.loadingSpan
                : <span className={this.disabledClassIfSoundNotLoaded()}>{this.soundPositionInfo}</span>
            }
          </span>
        </ControlStack>
        <ControlStack>
          <ControlRow className={[classes.info, classes.mainInfo].join(' ')}>
            <LimitedSpacePanel>
              {this.createMarkerInfoMainItem()}
              {this.createFileInfoMainItem()}
              {this.createPlaylistTitleInfoMainItem()}
              {this.createPlaylistPositionInfoMainItem()}
            </LimitedSpacePanel>
          </ControlRow>
        </ControlStack>
        <ControlStack className={classes.timeInfoItem} style={{alignItems: 'flex-end'}}>
          <span className={classes.info}>
            {
              this.props.loading
                ? this.loadingSpan
                : <span className={this.disabledClassIfSoundNotLoaded()}>{this.soundLengthInfo}</span>
            }
          </span>
        </ControlStack>
      </ControlRow>
    )
  }

  private createMarkerInfoMainItem() {
    const m = this.props.activeSoundMarker
    if (!m || m.positionInSeconds === undefined) {
      return undefined
    }
    const markerIconComponent = getMarkerIconComponent(m, m.listInfo.type)
    return (
      <RatingDecorator rating={m.rating}
                       classes={{
                         root: this.props.classes.ratingBadgeRoot,
                         badge: this.props.classes.ratingBadge,
                       }}>
        {this.createMainInfoItem(markerIconComponent, generateMarkerTitle(m, m.index))}
      </RatingDecorator>
    )
  }

  private createPlaylistTitleInfoMainItem() {
    return this.createMainInfoItem(PlaylistIcon, this.playlistTitleInfo)
  }

  private createPlaylistPositionInfoMainItem() {
    return this.createMainInfoItem(undefined, this.playlistPosInfo)
  }

  private createFileInfoMainItem() {
    return this.createMainInfoItem(FileMarkerIcon, this.fileInfo)
  }

  private disabledClassIfSoundNotLoaded = () => {
    return this.props.soundLoaded ? undefined : this.props.classes.disabled
  }

  private get soundLengthInfo() {
    return formatDuration(this.props.soundLength)
  }

  private get soundPositionInfo() {
    return formatDuration(this.currentSoundPositionInSeconds)
  }

  private get currentSoundPositionInSeconds() {
    return Duration.ofSeconds(this.props.currentSoundPosition.seconds())
  }

  private get loadingSpan() {
    return <span className={[this.props.classes.soundLoading, 'blinking'].join(' ')}>Loading...</span>
  }

  private get playlistTitleInfo() {
    const m = this.props.activeSoundMarker
    if (!m) {
      return undefined
    }
    switch (m.listInfo.type) {
      case SoundMarkerListType.Device:
        return `Quick markers`
      case SoundMarkerListType.Sheet:
        return this.props.currentlyPlayingSheetTitle || 'Unnamed sheet'
      default:
        return undefined
    }
  }

  private get playlistPosInfo() {
    const {currentPlaylistIndex, currentPlaylistSize} = this.props
    if (currentPlaylistIndex === undefined || !currentPlaylistSize === undefined) {
      return undefined
    }
    return `${currentPlaylistIndex + 1} / ${currentPlaylistSize}`
  }

  private get fileInfo() {
    const m = this.props.activeSoundMarker
    if (!m || m.positionInSeconds !== undefined) {
      return this.metadataInfo
    }
    return [m.name, embrace(this.metadataInfo)].filter(text => text).join(' ')
  }

  private get metadataInfo() {
    return this.props.metadata.title
  }

  private createMainInfoItem(Icon: any, text: ReactNode | undefined) {
    if (!text) {
      return undefined
    }
    return <div className={this.props.classes.mainInfoItem}>
      {Icon && <Icon fontSize="inherit"/>}
      <div className={this.props.classes.mainInfoItemContent}>{text}</div>
    </div>
  }

}

function embrace(text: string | undefined) {
  if (text === undefined) {
    return undefined
  }
  return `(${text})`
}

export const SoundInfoPanel = withStyles(styles)(RawSoundInfoPanel)