import {remoteSpaceDocRef} from '../util/firebase'
import {UserPermissions} from '../model/UserProfile'

export async function getUserPermissions(uid: string): Promise<UserPermissions> {
  const memberSnapshot = await remoteSpaceDocRef().collection('members').doc(uid).get()
  if (!memberSnapshot.exists) {
    return {
      read: false,
      write: false
    }
  }
  return memberSnapshot.data() as UserPermissions
}