import {ApplicationState} from '../ApplicationState'
import {createSelector} from 'reselect'
import {filterIsActive} from '../../model/SoundMarkerFilter'
import {createMuiTheme} from '@material-ui/core'
import {getThemeDescription} from '../../util/theme-util'

export const showSoundSourceInputSelector = (state: ApplicationState) => state.settings.showSoundSourceInput
export const showSoundStreamUrlSelector = (state: ApplicationState) => state.settings.showSoundStreamUrl
export const showSoundMetadataSelector = (state: ApplicationState) => state.settings.showSoundMetadata
export const filterEnabledSelector = (state: ApplicationState) => state.settings.filterEnabled
export const filterSelector = (state: ApplicationState) => state.settings.filter
export const offlineModeSelector = (state: ApplicationState) => state.settings.filter.availableOffline
export const markerToggleSluggishnessInSecondsSelector =
  (state: ApplicationState) => state.settings.markerToggleSluggishnessInSeconds
export const markerToggleLookBehindTimeInSecondsSelector =
  (state: ApplicationState) => state.settings.markerToggleLookBehindTimeInSeconds
export const themeIdSelector = (state: ApplicationState) => state.settings.themeId

export const filterIsActiveSelector = createSelector(
  [filterEnabledSelector, filterSelector],
  (filterEnabled, filter) => {
    if (!filterEnabled) {
      return false
    }
    return filterIsActive(filter)
  }
)

export const currentThemeSelector = createSelector(
  themeIdSelector,
  themeId => {
    return createMuiTheme(getThemeDescription(themeId).themeOptions)
  }
)
