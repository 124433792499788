import * as React from 'react'
import {Component} from 'react'
import {createStyles, Input, Theme, withStyles, WithStyles} from '@material-ui/core'
import {grey} from '@material-ui/core/colors'

export interface DataProps {
  streamingUrl?: URL
}

const styles = (theme: Theme) => createStyles({
  innerReadOnlyInput: {
    textAlign: 'center',
    fontFamily: 'monospace',
    color: grey[500],
    padding: 0,
  },
})

class RawSoundStreamingUrlPanel extends Component<DataProps & WithStyles<typeof styles>> {
  public render() {
    return (
      <Input
        className={this.props.classes.innerReadOnlyInput}
        inputProps={{
          className: this.props.classes.innerReadOnlyInput
        }}
        value={this.props.streamingUrl ? this.props.streamingUrl.toString() : ''}
        disabled={true}
        disableUnderline={true}
        fullWidth={true}
      />
    )
  }
}

export const SoundStreamingUrlPanel = withStyles(styles)(RawSoundStreamingUrlPanel)