import {ApplicationState} from '../store/ApplicationState'
import {connect} from 'react-redux'
import {Dispatch} from 'redux'
import {DataProps, DispatchProps, SheetMarkdownRenderer} from '../components/SheetMarkdownRenderer'
import {playSoundMarker} from '../store/player/actions'
import {
  activeSheetSoundMarkerSelector,
  coarseSoundPositionInSecondsSelector,
  currentPlaylistTypeSelector,
  failedSoundAddressSetSelector,
  filteredCurrentSheetSoundMarkerIndexesSelector,
  offlineSoundAddressSetSelector,
  publicSoundAddressSelector
} from '../store/player/selectors'
import {focusedLineChanged, handleScroll} from '../store/sheet/actions'
import {
  currentlyVisibleSheetIdSelector,
  currentlyVisibleSheetNodeSelector,
  numMarkersByPublicSoundAddressSelector,
  sheetTitlesSelector
} from '../store/sheet/selectors'
import {targetedSheetSoundMarkerSelector} from '../store/layout/selectors'
import {scrollingToTargetedSoundMarkerDone} from '../store/layout/actions'
import {PlaylistType} from '../model/PlaylistType'

function mapStateToProps(state: ApplicationState): DataProps {
  return {
    sheetNode: currentlyVisibleSheetNodeSelector(state),
    currentPublicSoundAddress: publicSoundAddressSelector(state),
    currentPositionInSeconds: state.layout.screenIsVisible && !state.settings.energySavingMode
      ? coarseSoundPositionInSecondsSelector(state)
      : undefined,
    filteredSoundMarkerIndexes: filteredCurrentSheetSoundMarkerIndexesSelector(state),
    offlineSoundAddressSet: offlineSoundAddressSetSelector(state),
    failedSoundAddressSet: failedSoundAddressSetSelector(state),
    maxMarkerColoringDistanceInSeconds: state.settings.maxMarkerColoringDistanceInSeconds,
    activeSoundMarker: activeSheetSoundMarkerSelector(state),
    currentPlaylistType: currentPlaylistTypeSelector(state),
    numSheetMarkersByPublicSoundAddress: numMarkersByPublicSoundAddressSelector(state),
    sheetTitles: sheetTitlesSelector(state),
    scrollTop: state.sheet.scrollTop,
    sheetId: currentlyVisibleSheetIdSelector(state),
    targetedSoundMarker: targetedSheetSoundMarkerSelector(state),
  }
}

function mapDispatchToProps(dispatch: Dispatch): DispatchProps {
  return {
    playSoundMarker: soundMarker => dispatch(playSoundMarker({soundMarker, playlistType: PlaylistType.Sheet})),
    focusedLineChanged: lineNumber => dispatch(focusedLineChanged(lineNumber)),
    scrollTopChanged: scrollTop => dispatch(handleScroll({scrollTop})),
    scrollDone: () => dispatch(scrollingToTargetedSoundMarkerDone())
  }
}

export const SheetViewerContainer = connect(mapStateToProps, mapDispatchToProps)(SheetMarkdownRenderer)