import {ApplicationState} from '../store/ApplicationState'
import {connect} from 'react-redux'
import {Dispatch} from 'redux'
import {DataProps, DispatchProps, FilterPanel} from '../components/FilterPanel'
import {
  clearFilter,
  setFilterAuthor,
  setFilterAvailableOffline,
  setFilterEnabled,
  setFilterMinRating,
  setFilterSearchExpression,
  setFilterSoundMarkerType
} from '../store/settings/actions'
import {filterIsActiveSelector} from '../store/settings/selectors'

function mapStateToProps(state: ApplicationState): DataProps {
  return {
    filterEnabled: state.settings.filterEnabled,
    filterIsActive: filterIsActiveSelector(state),
    availableOffline: state.settings.filter.availableOffline,
    minRating: state.settings.filter.minRating,
    searchExpression: state.settings.filter.searchExpression,
    author: state.settings.filter.author,
    soundMarkerType: state.settings.filter.soundMarkerType,
  }
}

function mapDispatchToProps(dispatch: Dispatch): DispatchProps {
  return {
    setFilterEnabled: filterEnabled => dispatch(setFilterEnabled({filterEnabled})),
    setAvailableOffline: availableOffline => dispatch(setFilterAvailableOffline({offlineMode: availableOffline})),
    setMinRating: minRating => dispatch(setFilterMinRating({minRating})),
    setSearchExpression: searchExpression => dispatch(setFilterSearchExpression({searchExpression})),
    setAuthor: author => dispatch(setFilterAuthor({author})),
    setSoundMarkerType: soundMarkerType => dispatch(setFilterSoundMarkerType({soundMarkerType})),
    clear: () => dispatch(clearFilter()),
  }
}

export const FilterPanelContainer = connect(mapStateToProps, mapDispatchToProps)(FilterPanel)