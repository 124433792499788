import {Reducer} from 'redux'
import {ActionType, getType} from 'typesafe-actions'
import * as actions from './actions'
import {ControllerState} from './ControllerState'
import {ControllerConnectionState} from '../../model/controller/ControllerConnectionState'

type ControllerAction = ActionType<typeof actions>

const initialState: ControllerState = {
  connectionState: ControllerConnectionState.Disconnected
}

export const controllerReducer: Reducer<ControllerState, ControllerAction> = (state = initialState, action) => {
  switch (action.type) {
    case getType(actions.controllerConnectionStateChanged):
      return {
        ...state,
        connectionState: action.payload.connectionState
      }
    default:
      return state
  }
}