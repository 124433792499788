import * as React from 'react'
import {Component, ReactNode} from 'react'
import {createStyles, Theme, withStyles, WithStyles} from '@material-ui/core'
import 'rc-slider/assets/index.css'
import {CSSProperties} from '@material-ui/core/styles/withStyles'

export interface DataProps {
  children: ReactNode
}

export const controlRowCssProps: CSSProperties = {
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  minWidth: 0,
}

const styles = (theme: Theme) => createStyles({
  controlRow: controlRowCssProps
})

class RawControlRow extends Component<React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> & WithStyles<typeof styles>> {
  public render() {
    return (
      <div className={[this.props.classes.controlRow, this.props.className].join(' ')}
           style={this.props.style}
           children={this.props.children}/>
    )
  }
}

export const ControlRow = withStyles(styles)(RawControlRow)