import {History} from 'history'
import {Theme} from '@material-ui/core'
import * as React from 'react'
import {Component} from 'react'
import {AppContainer} from '../containers/AppContainer'
import {MuiThemeProvider} from '@material-ui/core/styles'

export interface DataProps {
  theme: Theme
  history: History
}

export class ThemedApp extends Component<DataProps> {
  public render() {
    return (
      <MuiThemeProvider theme={this.props.theme}>
        <AppContainer history={this.props.history}/>
      </MuiThemeProvider>
    )
  }
}