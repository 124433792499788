import * as React from 'react'
import {Component} from 'react'
import {createStyles, Theme, withStyles, WithStyles} from '@material-ui/core'
import 'rc-slider/assets/index.css'

const styles = (theme: Theme) => createStyles({
  controlStack: {
    flex: 8,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    textAlign: 'center',
    minWidth: 0,
  },
})

class RawControlStack extends Component<React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> & WithStyles<typeof styles>> {
  public render() {
    return (
      <div className={[this.props.classes.controlStack, this.props.className].join(' ')}
           style={this.props.style}
           children={this.props.children}/>
    )
  }
}

export const ControlStack = withStyles(styles)(RawControlStack)