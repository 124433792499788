import {SoundMarkerType} from './SoundMarkerType'

export interface SoundMarkerFilter {
  minRating: number
  searchExpression?: string
  availableOffline: boolean
  author?: string
  soundMarkerType?: SoundMarkerType
}

export function filterIsActive(f: SoundMarkerFilter) {
  return f.availableOffline || f.minRating > 0 || f.searchExpression !== undefined || f.author !== undefined ||
    f.soundMarkerType !== undefined
}