import {createStandardAction} from 'typesafe-actions'
import {PlaylistMode} from '../../model/PlaylistMode'
import {SoundMarkerType} from '../../model/SoundMarkerType'

export const showOrHideSoundSourceInput = createStandardAction('settings/showOrHideSoundSourceInput')<boolean>()
export const showOrHideSoundSoundStreamUrl = createStandardAction('settings/showOrHideSoundSoundStreamUrl')<boolean>()
export const showOrHideSoundMetadata = createStandardAction('settings/showOrHideSoundMetadata')<boolean>()
export const showOrHideSeekSliderPanel = createStandardAction('settings/showOrHideSeekSliderPanel')<boolean>()
export const showOrHideSoundPlayerPanel = createStandardAction('settings/showOrHideSoundPlayerPanel')<boolean>()
export const showOrHideToolPanel = createStandardAction('settings/showOrHideToolPanel')<boolean>()
export const showOrHidePlaybackRateControls = createStandardAction('settings/showOrHidePlaybackRateControls')<boolean>()
export const setPlaylistMode = createStandardAction('settings/setPlaylistMode')<{ playlistMode: PlaylistMode }>()
export const clearFilter = createStandardAction('settings/clearFilter')()
export const setFilterAvailableOffline = createStandardAction('settings/setFilterAvailableOffline')<{ offlineMode: boolean }>()
export const setFilterEnabled = createStandardAction('settings/setFilterEnabled')<{ filterEnabled: boolean }>()
export const setFilterMinRating = createStandardAction('settings/setFilterMinRating')<{ minRating: number }>()
export const setFilterSearchExpression =
  createStandardAction('settings/setFilterSearchExpression')<{ searchExpression: string | undefined }>()
export const setFilterAuthor = createStandardAction('settings/setFilterAuthor')<{ author: string | undefined }>()
export const setFilterSoundMarkerType =
  createStandardAction('settings/setFilterSoundMarkerType')<{ soundMarkerType: SoundMarkerType | undefined }>()
export const setEnergySavingMode = createStandardAction('settings/setEnergySavingMode')<{ energySavingMode: boolean }>()
export const enableOrDisableVibrations = createStandardAction('settings/enableOrDisableVibrations')<boolean>()
export const enableOrDisableBeeps = createStandardAction('settings/enableOrDisableBeeps')<boolean>()
export const enableOrDisableSpeech = createStandardAction('settings/enableOrDisableSpeech')<boolean>()
export const setUseRealFullScreen = createStandardAction('settings/setUseRealFullScreen')<boolean>()
export const changeTheme = createStandardAction('settings/changeTheme')<{ themeId: string }>()