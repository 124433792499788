import {ApplicationState} from '../store/ApplicationState'
import {connect} from 'react-redux'
import {Dispatch} from 'redux'
import {DataProps, DispatchProps, SheetStructureList} from '../components/SheetStructureList'
import {
  currentlyVisibleSheetTitleSelector,
  currentlyVisibleSheetTocEntriesSelector,
  currentlyVisibleSheetTypeSelector
} from '../store/sheet/selectors'
import {toggleSectionExpanded} from '../store/sheet/actions'
import {showSwipeableDrawer} from '../store/layout/actions'

function mapStateToProps(state: ApplicationState): DataProps {
  return {
    tocEntries: currentlyVisibleSheetTocEntriesSelector(state),
    sheetTitle: currentlyVisibleSheetTitleSelector(state),
    expandedSectionElementIds: state.sheet.expandedSectionElementIds,
    sheetType: currentlyVisibleSheetTypeSelector(state),
    sheetIsLoading: state.sheet.sheetIsLoading,
  }
}

function mapDispatchToProps(dispatch: Dispatch): DispatchProps {
  return {
    toggleSectionExpanded: sectionElementId => dispatch(toggleSectionExpanded({sectionElementId})),
    entryChosen: () => dispatch(showSwipeableDrawer(false))
  }
}

export const SheetStructureListContainer = connect(mapStateToProps, mapDispatchToProps)(SheetStructureList)