import {ApplicationState} from '../store/ApplicationState'
import {connect} from 'react-redux'
import {Dispatch} from 'redux'
import {DataProps, DispatchProps, SettingsList} from '../components/SettingsList'
import {
  changeTheme,
  enableOrDisableBeeps,
  enableOrDisableSpeech,
  enableOrDisableVibrations,
  setEnergySavingMode, setUseRealFullScreen,
  showOrHidePlaybackRateControls,
  showOrHideSeekSliderPanel,
  showOrHideSoundMetadata,
  showOrHideSoundPlayerPanel,
  showOrHideSoundSoundStreamUrl,
  showOrHideSoundSourceInput,
  showOrHideToolPanel
} from '../store/settings/actions'
import {themeDescriptions} from '../util/theme-util'

function mapStateToProps(state: ApplicationState): DataProps {
  return {
    distanceVisualization: !state.settings.energySavingMode,
    vibrationsAreEnabled: state.settings.vibrationsAreEnabled,
    beepsAreEnabled: state.settings.beepsAreEnabled,
    speechIsEnabled: state.settings.speechIsEnabled,
    showSoundSourceInput: state.settings.showSoundSourceInput,
    showSoundStreamUrl: state.settings.showSoundStreamUrl,
    showSoundMetadata: state.settings.showSoundMetadata,
    showSoundPlayerPanel: state.settings.showSoundPlayerPanel,
    showToolPanel: state.settings.showToolPanel,
    showPlaybackRateControls: state.settings.showPlaybackRateControls,
    showSeekSliderPanel: state.settings.showSeekSliderPanel,
    currentThemeId: state.settings.themeId,
    availableThemeDescriptions: themeDescriptions,
    useRealFullScreen: state.settings.useRealFullScreen
  }
}

function mapDispatchToProps(dispatch: Dispatch): DispatchProps {
  return {
    setDistanceVisualization: distanceVisualization => dispatch(setEnergySavingMode({energySavingMode: !distanceVisualization})),
    enableOrDisableVibrations: enable => dispatch(enableOrDisableVibrations(enable)),
    enableOrDisableBeeps: enable => dispatch(enableOrDisableBeeps(enable)),
    enableOrDisableSpeech: enable => dispatch(enableOrDisableSpeech(enable)),
    showOrHideSoundSourceInput: show => dispatch(showOrHideSoundSourceInput(show)),
    showOrHideSoundSoundStreamUrl: show => dispatch(showOrHideSoundSoundStreamUrl(show)),
    showOrHideSoundMetadata: show => dispatch(showOrHideSoundMetadata(show)),
    showOrHideSeekSliderPanel: show => dispatch(showOrHideSeekSliderPanel(show)),
    showOrHideSoundPlayerPanel: show => dispatch(showOrHideSoundPlayerPanel(show)),
    showOrHideToolPanel: show => dispatch(showOrHideToolPanel(show)),
    showOrHidePlaybackRateControls: show => dispatch(showOrHidePlaybackRateControls(show)),
    changeTheme: themeId => dispatch(changeTheme({themeId})),
    setUseRealFullScreen: enable => dispatch(setUseRealFullScreen(enable)),
  }
}

export const SettingsListContainer = connect(mapStateToProps, mapDispatchToProps)(SettingsList)