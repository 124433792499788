import * as actions from './actions'
import {soundMarkerAdded, soundMarkerDeleted, soundMarkerUpdated} from './actions'
import {ActionType, isActionOf} from 'typesafe-actions'
import {ApplicationState} from '../ApplicationState'
import {combineEpics, Epic} from 'redux-observable'
import {debounceTime, filter, map, mapTo, scan, share, switchMap} from 'rxjs/operators'
import {EMPTY, merge, Observable, of} from 'rxjs'
import {
  activeSoundMarkerSelector,
  lookBehindPosInSecondsSelector,
  publicSoundAddressSelector,
  toggleableDeviceSoundMarkerSelector
} from '../player/selectors'
import {playHighClick, playLowClick} from '../../util/beep'
import {soundMarkerActivated} from '../player/actions'
import {SoundMarkerListType} from '../../model/SoundMarkerListType'
import {deviceSoundMarkersSelector} from './selectors'
import {IndexedSoundMarkerWithListInfo} from '../../model/IndexedSoundMarkerWithListInfo'
import {createDefaultSoundMarker} from '../../util/sound-marker-util'
import {enableOrDisableUfoAlarm} from '../layout/actions'

const extendedActions = {
  ...actions,
  soundMarkerActivated,
  showUfos: enableOrDisableUfoAlarm
}
type MarkerAction = ActionType<typeof extendedActions>
type MarkerEpic = Epic<MarkerAction, MarkerAction, ApplicationState>

const toggleSoundMarkerEpic: MarkerEpic = (action$, state$) => action$.pipe(
  filter(isActionOf(actions.toggleSoundMarker)),
  switchMap(action => {
    return toggleSoundMarkerInternal(state$.value, action.payload.rating)
  })
)

const toggleOrPushUpSoundMarkerEpic: MarkerEpic = (action$, state$) => {
  const filteredActions$ = action$.pipe(
    filter(isActionOf(actions.toggleOrPushUpSoundMarker)),
    share()
  )
  return withResettingCount(filteredActions$).pipe(
    switchMap(([, count]) => {
      if (count === 1) {
        // Pressed first time. Simply toggle marker.
        return toggleSoundMarkerInternal(state$.value, undefined)
      } else if (count >= 3 && count <= 5) {
        // Pressed second or more time.
        const existingSoundMarker = toggleableDeviceSoundMarkerSelector(state$.value)
        if (!existingSoundMarker) {
          return EMPTY
        }
        return of(
          enableOrDisableUfoAlarm(true),
          soundMarkerUpdated({
            soundMarker: {
              ...existingSoundMarker,
              rating: count
            }
          })
        )
      } else {
        return EMPTY
      }
    })
  )
}

function withResettingCount<T>(actions$: Observable<T>): Observable<[T, number]> {
  const resets$ = actions$.pipe(
    debounceTime(250),
    mapTo(undefined)
  )
  return merge(actions$, resets$).pipe(
    scan<T | undefined, [T | undefined, number]>(
      ([prevAction, count], action) => [action, action ? count + 1 : 0],
      [undefined, 0]
    ),
    filter(([action, count]) => action !== undefined),
    map(tuple => tuple as [T, number])
  )
}

function toggleSoundMarkerInternal(state: ApplicationState, rating: number | undefined) {
  const publicSoundAddress = publicSoundAddressSelector(state)
  if (!publicSoundAddress) {
    return EMPTY as Observable<MarkerAction>
  }
  const existingSoundMarker = toggleableDeviceSoundMarkerSelector(state)
  if (existingSoundMarker) {
    // Sound marker already exists. Delete it.
    if (document.hidden && state.settings.beepsAreEnabled) {
      playLowClick()
    }
    if (state.settings.vibrationsAreEnabled) {
      vibrate([100, 100])
    }
    return of(
      enableOrDisableUfoAlarm(true),
      soundMarkerDeleted({soundMarker: existingSoundMarker})
    )
  } else {
    // Sound marker doesn't already exist. Add it.
    const activeSoundMarker = activeSoundMarkerSelector(state)
    const existingSoundMarkers = deviceSoundMarkersSelector(state)
    const newMarker: IndexedSoundMarkerWithListInfo = {
      ...createDefaultSoundMarker(publicSoundAddress),
      positionInSeconds: lookBehindPosInSecondsSelector(state),
      rating,
      name: activeSoundMarker ? activeSoundMarker.name : undefined,
      index: existingSoundMarkers.length,
      listInfo: {
        type: SoundMarkerListType.Device,
        index: existingSoundMarkers.length
      }
    }
    if (document.hidden && state.settings.beepsAreEnabled) {
      playHighClick(rating || 1)
    }
    if (state.settings.vibrationsAreEnabled) {
      vibrate(500)
    }
    return of(
      enableOrDisableUfoAlarm(true),
      soundMarkerAdded({soundMarker: newMarker}),
      soundMarkerActivated({
        soundMarker: newMarker,
        changeListAndPosition: false,
      }),
    )
  }
}

export const markerEpic = combineEpics(
  toggleSoundMarkerEpic,
  toggleOrPushUpSoundMarkerEpic,
)

function vibrate(pattern: number | number[]) {
  if (navigator.vibrate === undefined) {
    return
  }
  navigator.vibrate(pattern)
}