import {ApplicationState} from '../store/ApplicationState'
import {connect} from 'react-redux'
import {Dispatch} from 'redux'
import {DataProps, DispatchProps, TuningPanel} from '../components/TuningPanel'
import {changePlaybackRate, changeVolume} from '../store/player/actions'

function mapStateToProps(state: ApplicationState): DataProps {
  return {
    playbackRate: state.player.playbackRate,
    volume: state.player.volume,
  }
}

function mapDispatchToProps(dispatch: Dispatch): DispatchProps {
  return {
    changePlaybackRate: playbackRate => dispatch(changePlaybackRate({playbackRate})),
    changeVolume: volume => dispatch(changeVolume({volume})),
  }
}

export const TuningPanelContainer = connect(mapStateToProps, mapDispatchToProps)(TuningPanel)