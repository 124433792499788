import {createStandardAction} from 'typesafe-actions'
import {SheetInfo} from '../../model/SheetInfo'
import {TextChange} from '../../model/TextChange'

export const enterSheetEditMode = createStandardAction('sheet/enterSheetEditMode')()
export const leaveSheetEditMode = createStandardAction('sheet/leaveSheetEditMode')<void>()
export const createNewSheet = createStandardAction('sheet/createNewSheet')<void>()
export const createHomeSheet = createStandardAction('sheet/createHomeSheet')<void>()
export const loadOrCleanUpSheet = createStandardAction('sheet/loadOrCleanUpSheet')<void>()
export const refresh = createStandardAction('sheet/refresh')()
export const updateSheetContent = createStandardAction('sheet/updateSheetContent')<{
  sheetId: string
  sheetContent: string
  change: TextChange
}>()
export const sheetLoadingStarted = createStandardAction('sheet/sheetLoadingStarted')<void>()
export const sheetContentChanged = createStandardAction('sheet/sheetContentChanged')<{
  sheetId: string
  sheetContent: string
}>()
export const visibleSheetChanged = createStandardAction('sheet/visibleSheetChanged')<{
  sheetId: string
  sheetContent: string
}>()
export const sheetContentCleared = createStandardAction('sheet/sheetContentCleared')<void>()
export const focusedLineChanged = createStandardAction('sheet/focusedLineChanged')<number>()
export const toggleSectionExpanded = createStandardAction('sheet/toggleSectionExpanded')<{ sectionElementId: string }>()
export const sheetInfoUpdated = createStandardAction('sheet/updateSheetInfo')<{ sheetId: string, info: SheetInfo }>()
export const processUpdatedSheets = createStandardAction('sheet/processUpdatedSheets')<{ sheetIds: string[] }>()
export const initSheetData = createStandardAction('sheet/initSheetData')<void>()
export const handleScroll = createStandardAction('sheet/handleScroll')<{ scrollTop: number }>()
export const scrollTopChanged = createStandardAction('sheet/scrollTopChanged')<{ scrollTop: number }>()
export const sheetInfosLoadingFinished = createStandardAction('sheet/sheetInfosLoadingFinished')<void>()