import mix from './mix'
import {Palette} from '@material-ui/core/styles/createPalette'
import {Duration} from 'js-joda'
import {darken, rgbToHex} from '@material-ui/core/styles/colorManipulator'
import VolumeUpIcon from '@material-ui/icons/VolumeUp'
import FavoriteIcon from '@material-ui/icons/Favorite'
import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder'
import AudiotrackIcon from '@material-ui/icons/Audiotrack'
import StarIcon from '@material-ui/icons/Star'
import StarBorderIcon from '@material-ui/icons/StarBorder'
import StarHalfIcon from '@material-ui/icons/StarHalf'
import LocationOnIcon from '@material-ui/icons/LocationOn'
import EditLocationIcon from '@material-ui/icons/EditLocation'
import PlaylistPlayIcon from '@material-ui/icons/PlaylistPlay'
import {SoundMarkerListType} from '../model/SoundMarkerListType'
import {SoundMarker} from '../model/SoundMarker'

const scheme = 0

export const personalMarkerSymbol = ['♥', '♡', '☆', '☆', '📌', '♥'][scheme]
export const PersonalMarkerIcon = [FavoriteIcon, FavoriteBorderIcon, StarHalfIcon, StarBorderIcon, EditLocationIcon, FavoriteIcon][scheme]
export const sheetMarkerSymbol = ['★', '♥', '★', '★', '📌', '♡'][scheme]
export const SheetMarkerIcon = [StarIcon, FavoriteIcon, StarIcon, StarIcon, LocationOnIcon, FavoriteBorderIcon][scheme]
export const anonymousMarkerSymbol = '🔊'
export const AnonymousMarkerIcon = VolumeUpIcon
export const fileMarkerSymbol = '♪'
export const FileMarkerIcon = AudiotrackIcon
export const PlaylistIcon = PlaylistPlayIcon
export const ratingStarSymbol = '★'

export function getMarkerIconComponent(m: SoundMarker, listType: SoundMarkerListType | undefined) {
  if (m.positionInSeconds === undefined) {
    return FileMarkerIcon
  }
  return getMarkerIconComponentByListType(listType)
}

export function getMarkerIconComponentByListType(listType: SoundMarkerListType | undefined) {
  switch (listType) {
    case SoundMarkerListType.Device: return PersonalMarkerIcon
    case SoundMarkerListType.Sheet: return SheetMarkerIcon
    default: return AnonymousMarkerIcon
  }
}

export function getMarkerSymbol(m: SoundMarker, listType: SoundMarkerListType | undefined) {
  if (m.positionInSeconds === undefined) {
    return fileMarkerSymbol
  }
  switch (listType) {
    case SoundMarkerListType.Device: return personalMarkerSymbol
    case SoundMarkerListType.Sheet: return sheetMarkerSymbol
    default: return anonymousMarkerSymbol
  }
}

// distance can be undefined. Then it means it's just the same file. Used for file markers.
export function calcSameFileMarkerDistanceColor(
  baseColor: string,
  // Undefined distance means it's impossible to calculate a distance because current pos or reference pos missing
  distance: number | undefined,
  palette: Palette,
  maxDistance: number,
  isActiveSoundMarker: boolean
): string {
  // We always darken base color at least a bit because we want to indicate that marker is same file.
  const darkeningFactor = isActiveSoundMarker ? 0.3 : 0.1
  const effectiveBaseColor = rgbToHex(darken(baseColor, darkeningFactor))
  if (distance === undefined || distance > maxDistance) {
    return effectiveBaseColor
  }
  return mix(effectiveBaseColor, rgbToHex(palette.secondary.light), (distance / maxDistance) * 100)
}

export function calcMarkerDistanceColor(
  soundMarker: SoundMarker,
  baseColor: string,
  currentPublicSoundAddress: string | undefined,
  currentPositionInSeconds: number | undefined,
  palette: Palette,
  maxDistance: number,
  isActiveSoundMarker: boolean
): string {
  if (!currentPublicSoundAddress) {
    return baseColor
  }
  if (soundMarker.publicSoundAddress !== currentPublicSoundAddress) {
    return baseColor
  }
  const distance = soundMarker.positionInSeconds !== undefined && currentPositionInSeconds !== undefined
    ? Math.abs(soundMarker.positionInSeconds - currentPositionInSeconds)
    : undefined
  return calcSameFileMarkerDistanceColor(baseColor, distance, palette, maxDistance, isActiveSoundMarker)
}

export function formatDuration(d: Duration) {
  const hours = d.toHours()
  const minutes = Math.floor((d.seconds() - (hours * 3600)) / 60)
  const seconds = d.seconds() - (hours * 3600) - (minutes * 60)
  const hourString = hours < 10 ? '0' + hours : hours
  const minuteString = minutes < 10 ? '0' + minutes : minutes
  const secondString = seconds < 10 ? '0' + seconds : seconds
  return `${hourString}:${minuteString}:${secondString}`
}

export function checkIfOnline() {
  if (navigator.onLine) {
    // return serverIsReachable()
    return true
  } else {
    return false
  }
}