import {ApplicationState} from '../store/ApplicationState'
import {connect} from 'react-redux'
import {DataProps, SoundStreamingUrlPanel} from '../components/SoundStreamingUrlPanel'
import {streamingSoundUrlSelector} from '../store/player/selectors'

function mapStateToProps(state: ApplicationState): DataProps {
  return {
    streamingUrl: streamingSoundUrlSelector(state),
  }
}

export const SoundStreamingUrlPanelContainer = connect(mapStateToProps)(SoundStreamingUrlPanel)