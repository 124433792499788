import {SoundMarker, soundMarkersEqual} from '../model/SoundMarker'
import {SoundMarkerFilter} from '../model/SoundMarkerFilter'
import {SheetInfo} from '../model/SheetInfo'
import {SoundMarkerType} from '../model/SoundMarkerType'
import {IndexedSoundMarkerWithListInfo} from '../model/IndexedSoundMarkerWithListInfo'
import {SoundMarkerListType} from '../model/SoundMarkerListType'
import {IndexedSoundMarker} from '../model/IndexedSoundMarker'

export function createDefaultSoundMarker(publicSoundAddress: string): SoundMarker {
  return {
    publicSoundAddress,
    creationTimestamp: Math.floor(new Date().getTime() / 1000)
  }
}

export function generateMarkerTitle(soundMarker: SoundMarker, index: number | undefined) {
  const position = index === undefined ? undefined : index + 1
  return soundMarker.name || (position !== undefined ? `Marker ${position}` : `Unnamed marker`)
}

export function soundMarkerPassesFilter(soundMarker: SoundMarker, filter: SoundMarkerFilter,
                                        offlineSoundAddressSet: Set<string>) {
  if (filter.minRating > 0 && (!soundMarker.rating || soundMarker.rating < filter.minRating)) {
    return false
  }
  if (filter.searchExpression && (!soundMarker.name || !textMatchesSearchExpression(soundMarker.name, filter.searchExpression))) {
    return false
  }
  if (filter.author && (!soundMarker.author || !textMatchesSearchExpression(soundMarker.author, filter.author))) {
    return false
  }
  if (filter.availableOffline && !offlineSoundAddressSet.has(soundMarker.publicSoundAddress)) {
    return false
  }
  if (filter.soundMarkerType && !soundMarkerMatchesType(soundMarker, filter.soundMarkerType)) {
    return false
  }
  return true
}

function textMatchesSearchExpression(text: string, searchExpression: string) {
  return text.toLowerCase().includes(searchExpression.toLowerCase())
}

function soundMarkerMatchesType(soundMarker: SoundMarker, type: SoundMarkerType) {
  return (type === SoundMarkerType.Position && soundMarker.positionInSeconds !== undefined) ||
    (type === SoundMarkerType.File && soundMarker.positionInSeconds === undefined)
}

export function combineSheetMarkersFromAllSheets(sheetInfos: { [sheetId: string]: SheetInfo }) {
  const soundMarkers: IndexedSoundMarkerWithListInfo[] = []
  let i = 0
  Object.keys(sheetInfos).forEach(sheetId => {
    const sheetInfo = sheetInfos[sheetId]
    sheetInfo.soundMarkers.forEach((m, indexWithinSheet)=> {
      soundMarkers.push({
        ...m,
        index: i,
        listInfo: {
          type: SoundMarkerListType.Sheet,
          sheetId,
          index: indexWithinSheet,
        }
      })
      i += 1
    })
  })
  return soundMarkers
}

// soundMarkers must be a complete list with gapless indexes, i.e. not a filtered list (because in a filtered list
// the array index is not equal to the sound marker index).
export function findSoundMarkerIndexInGaplessList(soundMarker: IndexedSoundMarker, soundMarkers: SoundMarker[]) {
  if (soundMarker.index < soundMarkers.length &&
    soundMarkersEqual(soundMarker, soundMarkers[soundMarker.index])) {
    // Active marker index is valid
    return soundMarker.index
  }
  // Active marker index is invalid (marker list has been changed in the meanwhile). Try to find new index.
  const newIndex = soundMarkers.findIndex(m => soundMarkersEqual(m, soundMarker))
  if (newIndex === -1) {
    return undefined
  }
  return newIndex
}

export function findSoundMarkerIndexInListWithGaps(soundMarker: IndexedSoundMarker, soundMarkers: SoundMarker[]) {
  const arrayIndex = soundMarkers.findIndex(m => m.index === soundMarker.index)
  if (arrayIndex === -1) {
    return undefined
  }
  return arrayIndex
}