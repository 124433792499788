import {Duration} from 'js-joda'
import {SoundAddressAndPosition} from '../model/SoundAddressAndPosition'
import {matchPath} from 'react-router'
import {Routes} from '../store/Routes'
import {SoundMarker} from '../model/SoundMarker'
import {parse} from 'query-string'
import {tryParseUrl} from './url-util'

export function parseSoundMarkerFromArbitraryAddress(originalAddress: string): SoundMarker {
  const trimmedOriginalAddress = originalAddress.trim()
  const url = tryParseUrl(trimmedOriginalAddress)
  if (url) {
    const parsedSoundMarker = parseSoundMarkerUrlPath(url.pathname)
    if (parsedSoundMarker) {
      // Is sound marker URL (such as "https://www.jampad.app/play/blabla/5"). Extract public sound address.
      return parsedSoundMarker
    } else {
      // Is another URL. Return it.
      return {
        publicSoundAddress: trimmedOriginalAddress
      }
    }
  } else {
    const parsedSoundMarker = parseSoundMarkerUrlPath(trimmedOriginalAddress)
    if (parsedSoundMarker) {
      // Is a sound marker URL path (such as "/play/blabla/5"). Extract public sound address.
      return parsedSoundMarker
    } else {
      // Is neither an URL nor a sound marker URL path. Last resort: Assume it's an URL-encoded URL (such as
      // "blabla" in "https://www.jampad.app/play/blabla/5"). Decode it.
      return {
        publicSoundAddress: decodeURIComponent(trimmedOriginalAddress)
      }
    }
  }
}

export function parseSoundMarkerUrl(address: string): SoundMarker | undefined {
  const url = tryParseUrl(address)
  if (!url) {
    return undefined
  }
  return parseSoundMarkerUrlPath(url.pathname)
}

export function generateSoundMarkerUrl(soundAddressAndPosition: SoundAddressAndPosition): URL {
  // TODO Detect if hash history or browser history used. If hash history, set url.hash instead of url.pathname
  const url = new URL(window.location.href)
  url.pathname = generateSoundMarkerUrlPath(soundAddressAndPosition)
  url.hash = ''
  return url
}

export function generateSoundMarkerUrlPath(soundMarker: SoundMarker): string {
  const escapedPublicSoundAddress = encodeURIComponent(soundMarker.publicSoundAddress)
  const lastPathComponent = soundMarker.positionInSeconds === undefined ? '' : `/${soundMarker.positionInSeconds}`
  return `/play/${escapedPublicSoundAddress}${lastPathComponent}`
}

export function parseSoundMarkerUrlQueryString(queryString: string): SoundMarker | undefined {
  const params = parse(queryString)
  const urls = params.url || params.text || undefined
  const url = urls instanceof Array ? urls[0] : urls
  if (!url) {
    return undefined
  }
  return {
    publicSoundAddress: url
  }
}

export function parseSoundMarkerUrlPath(path: string): SoundMarker | undefined {
  const match = matchPath<any>(path, {path: Routes.SoundMarker})
  if (!match) {
    return undefined
  }
  const publicSoundAddress = decodeURIComponent(match.params.publicSoundAddress)
  if (!match.params.markerExpression) {
    // This is a so-called file marker. It has no position.
    return {
      publicSoundAddress
    }
  }
  const positionAndLength = parseMarkerExpression(match.params.markerExpression)
  if (!positionAndLength) {
    // Same. Also just a file marker.
    return {
      publicSoundAddress
    }
  }
  return {
    publicSoundAddress,
    positionInSeconds: positionAndLength.position.seconds(),
    regionLengthInSeconds: positionAndLength.length ? positionAndLength.length.seconds() : undefined
  }
}


const MARKER_EXPRESSION_REGEX = /(?:(?:(\d+):)?(\d+):)?(\d+)(PT.+)?(-(?:(?:(\d+):)?(\d+):)?(\d+))?/

interface PositionAndLength {
  position: Duration
  length?: Duration
}

function parseMarkerExpression(markerExpression: string): PositionAndLength | undefined {
  const matches = markerExpression.trim().match(MARKER_EXPRESSION_REGEX)
  if (!matches) {
    return undefined
  }
  const startPosition = parseDurationFromComponents(matches[1], matches[2], matches[3])
  if (!startPosition) {
    return undefined
  }
  let length = parseDurationFromIsoPtExpression(matches[4])
  if (!length) {
    const endPosition = parseDurationFromComponents(matches[6], matches[7], matches[8])
    if (endPosition && endPosition.seconds() > startPosition.seconds()) {
      length = Duration.ofSeconds(endPosition.seconds() - startPosition.seconds())
    }
  }
  return {
    position: startPosition,
    length
  }
}

function parseDurationFromComponents(hourString: string, minuteString: string, secondString: string): Duration | undefined {
  const second = parseTimeComponent(secondString)
  if (second === undefined) {
    return undefined
  }
  const hour = parseTimeComponent(hourString) || 0
  const minute = parseTimeComponent(minuteString) || 0
  return Duration.ofSeconds(hour * 3600 + minute * 60 + second)
}

function parseDurationFromIsoPtExpression(lengthString: string | undefined) : Duration | undefined {
  if (!lengthString) {
    return undefined
  }
  try {
    return Duration.parse(lengthString)
  } catch(error) {
    return undefined
  }
}

function parseTimeComponent(timeComponentString: string | undefined): number | undefined {
  if (!timeComponentString) {
    return undefined
  }
  const result = parseInt(timeComponentString, 10)
  return isNaN(result) ? undefined : result
}
