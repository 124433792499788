import {ControllerChangeEventType} from './ControllerChangeEventType'
import {ControllerConnectionState} from './ControllerConnectionState'
import {ControllerTouchpadAxis} from './ControllerTouchpadAxis'
import {ControllerButton} from './ControllerButton'

export interface ControllerConnectionChangeEvent {
  type: ControllerChangeEventType.Connection
  connectionState: ControllerConnectionState
}

export interface ControllerButtonChangeEvent {
  type: ControllerChangeEventType.Button
  button: ControllerButton
  buttonIsPressed: boolean
}

export interface ControllerTouchpadChangeEvent {
  type: ControllerChangeEventType.Touchpad
  axis: ControllerTouchpadAxis
  value: number
}

export type ControllerChangeEvent =
  ControllerConnectionChangeEvent
  | ControllerButtonChangeEvent
  | ControllerTouchpadChangeEvent

export function createConnectionEvent(connectionState: ControllerConnectionState): ControllerConnectionChangeEvent {
  return {
    type: ControllerChangeEventType.Connection,
    connectionState
  }
}

export function createButtonEvent(button: ControllerButton, buttonIsPressed: boolean): ControllerButtonChangeEvent {
  return {
    type: ControllerChangeEventType.Button,
    button,
    buttonIsPressed
  }
}

export function createTouchpadEvent(axis: ControllerTouchpadAxis, value: number): ControllerTouchpadChangeEvent {
  return {
    type: ControllerChangeEventType.Touchpad,
    axis,
    value
  }
}