import {ApplicationState} from '../store/ApplicationState'
import {connect} from 'react-redux'
import {Dispatch} from 'redux'
import {DataProps, DispatchProps, SoundPlayerPanel} from '../components/SoundPlayerPanel'
import {playNextSoundMarker, playPreviousSoundMarker, seekRelatively, togglePlayPause} from '../store/player/actions'

function mapStateToProps(state: ApplicationState): DataProps {
  return {
    soundLoaded: state.player.soundLoaded,
    playing: state.player.playing,
  }
}

function mapDispatchToProps(dispatch: Dispatch): DispatchProps {
  return {
    fastRewind: () => dispatch(seekRelatively({direction: -1})),
    fastForward: () => dispatch(seekRelatively({direction: +1})),
    togglePlayPause: () => dispatch(togglePlayPause()),
    skipPrevious: () => dispatch(playPreviousSoundMarker()),
    skipNext: () => dispatch(playNextSoundMarker()),
  }
}

export const SoundPlayerPanelContainer = connect(mapStateToProps, mapDispatchToProps)(SoundPlayerPanel)