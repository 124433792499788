// @ts-ignore
import remark from 'remark'
// @ts-ignore
import remarkAttr from 'remark-attr'
// @ts-ignore
import visit from 'unist-util-visit'

export const sheetRemark = remark()
  .use({
    settings: {commonmark: true, gfm: true}
  })
  .use(remarkAttr, {
    elements: ['link'],
    extend: {
      link: ['rating', 'author', 'created', 'updated']
    },
    scope: 'extended'
  })

export function extractPlainTextFromMdastNode(node: any) {
  const textEntries: string[] = []
  visit(node, 'text', (textNode: any) => {
    textEntries.push(textNode.value)
  })
  if (textEntries.length === 0) {
    return undefined
  }
  return textEntries.join(' ')
}
