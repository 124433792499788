import {ApplicationState} from '../store/ApplicationState'
import {connect, Omit} from 'react-redux'
import {App, DataProps, DispatchProps} from '../components/App'
import {Dispatch} from 'redux'
import {
  clearSnackbarMessage,
  enteredTopView, initAudio,
  showSwipeableDrawer,
  showFilterPanel,
  signedOut,
  signIn, setFullScreen
} from '../store/layout/actions'
import {createHomeSheet, createNewSheet} from '../store/sheet/actions'
import {
  currentlyVisibleSheetTypeSelector,
  requestedSheetIdSelector,
  sheetContentSelector,
} from '../store/sheet/selectors'
import {isOnlineSelector, signInIsNecessarySelector, topViewSelector} from '../store/layout/selectors'

function mapStateToProps(state: ApplicationState): Omit<DataProps, 'history'> {
  return {
    audioIsReady: state.layout.audioIsReady,
    snackbarMessage: state.layout.snackbarMessage,
    snackbarOpen: state.layout.snackbarOpen,
    showSoundSourcePanel: state.settings.showSoundSourceInput,
    showSoundPlayerPanel: state.settings.showSoundPlayerPanel,
    showSeekSliderPanel: state.settings.showSeekSliderPanel,
    showToolPanel: state.settings.showToolPanel,
    showTuningPanel: state.settings.showPlaybackRateControls,
    showSoundMetadata: state.settings.showSoundMetadata,
    showSoundStreamingUrlPanel: state.settings.showSoundStreamUrl,
    topView: topViewSelector(state),
    persistentDrawerIsOpen: state.layout.persistentDrawerIsOpen,
    swipeableDrawerIsOpen: state.layout.swipeableDrawerIsOpen,
    filterPanelIsOpen: state.layout.filterPanelIsOpen,
    sheetIsLoading: state.sheet.sheetIsLoading,
    allSheetSoundMarkersAreLoading: state.sheet.sheetInfosAreLoading,
    isOnline: isOnlineSelector(state),
    signInIsNecessary: signInIsNecessarySelector(state),
    sheetContentIsSet: sheetContentSelector(state) !== undefined,
    sheetId: requestedSheetIdSelector(state),
    sheetType: currentlyVisibleSheetTypeSelector(state),
    isFullScreen: state.layout.isFullScreen,
    useRealFullScreen: state.settings.useRealFullScreen,
  }
}

function mapDispatchToProps(dispatch: Dispatch): DispatchProps {
  return {
    createNewSheet: () => dispatch(createNewSheet()),
    createHomeSheet: () => dispatch(createHomeSheet()),
    closeSnackbar: () => dispatch(clearSnackbarMessage()),
    openDrawer: () => dispatch(showSwipeableDrawer(true)),
    closeDrawer: () => dispatch(showSwipeableDrawer(false)),
    openFilterPanel: () => dispatch(showFilterPanel(true)),
    closeFilterPanel: () => dispatch(showFilterPanel(false)),
    signedIn: userProfile => dispatch(signIn({userProfile})),
    signedOut: () => dispatch(signedOut()),
    enterTopView: topView => dispatch(enteredTopView({topView})),
    initAudio: () => dispatch(initAudio()),
    setFullScreen: isEnabled => dispatch(setFullScreen(isEnabled))
  }
}

export const AppContainer = connect(mapStateToProps, mapDispatchToProps)(App)