// Taken from here: https://gist.github.com/gajus/0bbc78135d88a02c18366f12237011a5#gistcomment-2207357
// This fixes the problem that the browser can't find the anchor yet when navigating to a new page because element
// not yet there.
// Modified to also fix the problem that the browser doesn't use the correct element to scroll when back/forward button
// used (even if there's no DOM change):
// https://stackoverflow.com/questions/39885388/hash-anchor-scrolling-how-browsers-are-choosing-an-element-to-scroll-in
export function createHistoryHashObserver(history: any, mainSelector: string, timeout: number = 1000) {
  let observer: any
  let timeoutId: any

  if (!(window as any).MutationObserver) {
    return
  }

  const reset = () => {
    if (timeoutId) {
      clearTimeout(timeoutId)

      timeoutId = null
    }

    if (observer) {
      observer.disconnect()
    }
  }

  const createScrollToElement = (id: string) => {
    return () => {
      const element = document.getElementById(id)

      if (element) {
        element.scrollIntoView()

        reset()

        return true
      }

      return false
    }
  }

  function scroll(location: any) {

    if (typeof location.hash !== 'string') {
      return
    }

    const elementId = location.hash.slice(1)

    if (!elementId) {
      const contentArea = document.getElementById(mainSelector)
      if (contentArea) {
        contentArea.scrollTop = 0
      }
      return
    }

    const scrollToElement = createScrollToElement(elementId)

    setTimeout(() => {
      if (scrollToElement()) {
        return
      }

      observer = new MutationObserver(scrollToElement)

      observer.observe(document, {
        attributes: true,
        childList: true,
        subtree: true,
      })

      timeoutId = setTimeout(reset, timeout)
    })
  }

  history.listen((location: any, action: any) => {
    if (timeoutId) {
      reset()
    }

    scroll(location)
  })

  requestAnimationFrame(() => {
    scroll(window.location)
  })
}