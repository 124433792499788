import {createStandardAction} from 'typesafe-actions'
import {PlayState} from '../../services/SoundPlayer'
import {SoundMetadata} from '../../model/SoundMetadata'
import {SoundMarker} from '../../model/SoundMarker'
import {SoundAddressAndPosition} from '../../model/SoundAddressAndPosition'
import {PlaylistType} from '../../model/PlaylistType'

export const soundLoading = createStandardAction('player/soundLoading')<void>()
export const soundLoaded = createStandardAction('player/soundLoaded')<{ soundLengthInSeconds: number }>()
export const soundLoadingFailed = createStandardAction('player/soundLoadingFailed')<void>()
export const soundMetadataLoaded = createStandardAction('player/soundMetadataLoaded')<{ soundMetadata: SoundMetadata }>()
export const playStateChanged = createStandardAction('player/playStateChanged')<{ playState: PlayState }>()
export const playPositionChanged = createStandardAction('player/playPositionChanged')<{ playPositionInSeconds: number }>()
export const streamingSoundUrlChanged = createStandardAction('player/streamingSoundUrlChanged')<{ url: string }>()
export const publicSoundAddressTyped = createStandardAction('player/publicSoundAddressTyped')<{ address: string }>()
export const commitTypedPublicSoundAddress = createStandardAction('player/commitTypedPublicSoundAddress')<void>()
export const changePublicSoundAddress = createStandardAction('player/changePublicSoundAddress')<{ publicAddress?: string }>()
export const playSoundMarker = createStandardAction('player/playSoundMarker')<{
  soundMarker: SoundMarker
  playlistType?: PlaylistType
}>()
export const play = createStandardAction('player/play')<{ publicSoundAddress: string, positionInSeconds?: number }>()
export const togglePlayPause = createStandardAction('player/togglePlayPause')<void>()
export const handleRegionEndReached = createStandardAction('player/handleRegionEndReached')<void>()
export const seek = createStandardAction('player/seek')<{ playPositionInSeconds: number }>()
export const seekRelatively = createStandardAction('player/seekRelatively')<{ direction: number, amountInSeconds?: number }>()
export const playPreviousSheetSoundMarker = createStandardAction('player/playPreviousSheetSoundMarker')<void>()
export const playNextSheetSoundMarker = createStandardAction('player/playNextSheetSoundMarker')<void>()
export const playPreviousDeviceSoundMarker = createStandardAction('player/playPreviousDeviceSoundMarker')<void>()
export const playNextDeviceSoundMarker = createStandardAction('player/playNextDeviceSoundMarker')<void>()
export const playPreviousSoundMarker = createStandardAction('player/playPreviousSoundMarker')<void>()
export const playNextSoundMarker = createStandardAction('player/playNextSoundMarker')<void>()
export const changePlaybackRate = createStandardAction('player/changePlaybackRate')<{ playbackRate: number }>()
export const changeVolume = createStandardAction('player/changeVolume')<{ volume: number }>()
export const playbackRateChanged = createStandardAction('player/playbackRateChanged')<{ playbackRate: number }>()
export const volumeChanged = createStandardAction('player/volumeChanged')<{ volume: number }>()
export const makeCurrentSoundAvailableOffline = createStandardAction('player/makeCurrentSoundAvailableOffline')<void>()
export const downloadCurrentSound = createStandardAction('player/downloadCurrentSound')<void>()
export const downloadCurrentMarkerAsReaperItem = createStandardAction('player/downloadCurrentMarkerAsReaperItem')<void>()
export const downloadCurrentMarkerAsLiveClip = createStandardAction('player/downloadCurrentMarkerAsLiveClip')<void>()
export const downloadCurrentMarkerAsCroppedFile = createStandardAction('player/downloadCurrentMarkerAsCroppedFile')<void>()
export const soundMarkerActivated = createStandardAction('player/soundMarkerActivated')<{
  soundMarker: SoundMarker
  playlistType?: PlaylistType
  // False means it happens silently
  changeListAndPosition: boolean
  // Should be set only if this involves switching to another marker list
  positionBeforeMarkerListSwitch?: SoundAddressAndPosition
}>()
export const switchedBackToOtherMarkerList = createStandardAction('player/switchedBackToOtherMarkerList')<{
  newList: PlaylistType
  continueFrom: SoundAddressAndPosition
  positionOnCurrentMarkerList?: SoundAddressAndPosition
}>()
export const removeOfflineCopyOfCurrentSound = createStandardAction('player/removeOfflineCopyOfCurrentSound')<void>()
export const markerUrlCopiedToClipboard =
  createStandardAction('player/markerUrlCopiedToClipboard')<{ successful: boolean }>()
export const offlineSoundsDetected = createStandardAction('player/offlineSoundsDetected')<{ offlineSoundAddresses: string[] }>()
export const offlineSoundAdded = createStandardAction('player/offlineSoundAdded')<{ publicSoundAddress: string }>()
export const offlineSoundRemoved = createStandardAction('player/offlineSoundRemoved')<{ publicSoundAddress: string }>()
export const soundDownloadFailed = createStandardAction('player/soundDownloadFailed')<{ publicSoundAddress: string }>()
export const makeVisibleSoundsAvailableOffline = createStandardAction('player/makeVisibleSoundsAvailableOffline')<void>()
export const makeSoundsAvailableOffline = createStandardAction('player/makeSoundsAvailableOffline')<{ publicSoundAddresses: string[] }>()
export const soundDownloadStateChanged = createStandardAction('player/soundDownloadStateChanged')<{ isDownloading: boolean }>()
export const soundDownloadProgressed = createStandardAction('player/soundDownloadProgressed')<{ progress: number | undefined }>()
export const cancelSoundDownload = createStandardAction('player/cancelSoundDownload')()
export const offlineSoundDequeued = createStandardAction('player/offlineSoundDequeued')<{ publicSoundAddress: string }>()
export const purgeOfflineCache = createStandardAction('player/purgeOfflineCache')<void>()
export const removeVisibleOfflineSounds = createStandardAction('player/removeVisibleOfflineSounds')<void>()
export const soundsEnqueuedForOffline = createStandardAction('player/soundsEnqueuedForOffline')<{ publicSoundAddresses: string[] }>()
export const processOfflineSoundQueue = createStandardAction('player/processOfflineSoundQueue')()