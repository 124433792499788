import * as React from 'react'
import {Component} from 'react'
import {
  createStyles,
  Divider,
  FormControl,
  InputLabel,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  Select,
  Switch,
  Theme,
  WithStyles,
  withStyles,
  WithTheme,
  withTheme
} from '@material-ui/core'
import {ThemeDescription} from '../model/ThemeDescription'

export interface DataProps {
  distanceVisualization: boolean
  vibrationsAreEnabled: boolean
  beepsAreEnabled: boolean
  speechIsEnabled: boolean
  showSoundSourceInput: boolean
  showSoundStreamUrl: boolean
  showSoundMetadata: boolean
  showSoundPlayerPanel: boolean
  showToolPanel: boolean
  showPlaybackRateControls: boolean
  showSeekSliderPanel: boolean
  useRealFullScreen: boolean
  currentThemeId: string
  availableThemeDescriptions: ThemeDescription[]
}

export interface DispatchProps {
  setDistanceVisualization: (distanceVisualization: boolean) => void
  enableOrDisableVibrations: (enable: boolean) => void
  enableOrDisableBeeps: (enable: boolean) => void
  enableOrDisableSpeech: (enable: boolean) => void
  setUseRealFullScreen: (enable: boolean) => void
  showOrHideSoundSourceInput: (show: boolean) => void
  showOrHideSoundSoundStreamUrl: (show: boolean) => void
  showOrHideSoundMetadata: (show: boolean) => void
  showOrHideSoundPlayerPanel: (show: boolean) => void
  showOrHideToolPanel: (show: boolean) => void
  showOrHidePlaybackRateControls: (show: boolean) => void
  showOrHideSeekSliderPanel: (show: boolean) => void
  changeTheme: (themeId: string) => void
}

const styles = (theme: Theme) => createStyles({
  root: {
    overflowY: 'auto',
    flex: 1,
    backgroundColor: theme.palette.common.white
  },
})

class RawSettingsList extends Component<DataProps & DispatchProps & WithStyles<typeof styles> & WithTheme> {
  public render() {
    return (
      <List className={this.props.classes.root}>
        {this.createFlagMenuItem('Address box', this.toggleSoundSourceInput, this.props.showSoundSourceInput)}
        {this.createFlagMenuItem('Stream URL panel', this.toggleSoundStreamUrl, this.props.showSoundStreamUrl)}
        {this.createFlagMenuItem('Info panel', this.toggleSoundMetadata, this.props.showSoundMetadata)}
        {this.createFlagMenuItem('Seekbar', this.toggleSeekSliderPanel, this.props.showSeekSliderPanel)}
        {this.createFlagMenuItem('Primary playback controls', this.toggleSoundPlayerPanel, this.props.showSoundPlayerPanel)}
        {this.createFlagMenuItem('Secondary playback controls', this.togglePlaybackRateControls, this.props.showPlaybackRateControls)}
        {this.createFlagMenuItem('Tool panel', this.toggleToolPanel, this.props.showToolPanel)}
        <Divider/>
        {this.createFlagMenuItem('Distance visualization', this.toggleDistanceVisualization, this.props.distanceVisualization)}
        {this.createFlagMenuItem('Beeps', this.toggleBeeps, this.props.beepsAreEnabled)}
        {this.createFlagMenuItem('Vibrations', this.toggleVibrations, this.props.vibrationsAreEnabled)}
        {this.createFlagMenuItem('Speech', this.toggleSpeech, this.props.speechIsEnabled)}
        <Divider/>
        {this.createFlagMenuItem('Real full-screen', this.toggleUseRealFullScreen, this.props.useRealFullScreen)}
        <ListItem>
          <FormControl fullWidth={true}>
            <InputLabel>Theme</InputLabel>
            <Select
              native={true}
              value={this.props.currentThemeId}
              onChange={this.themeChanged}>
              {this.props.availableThemeDescriptions.map(d => <option key={d.id} value={d.id}>{d.name}</option>)}
            </Select>
          </FormControl>
        </ListItem>
      </List>
    )
  }

  private createFlagMenuItem(label: string, onChange: () => void, checked: boolean) {
    return (
      <ListItem>
        <ListItemText primary={label}/>
        <ListItemSecondaryAction>
          <Switch
            onChange={onChange}
            checked={checked}
          />
        </ListItemSecondaryAction>
      </ListItem>
    )
  }

  private toggleSoundSourceInput = () => {
    this.props.showOrHideSoundSourceInput(!this.props.showSoundSourceInput)
  }

  private toggleSoundStreamUrl = () => {
    this.props.showOrHideSoundSoundStreamUrl(!this.props.showSoundStreamUrl)
  }

  private toggleSoundMetadata = () => {
    this.props.showOrHideSoundMetadata(!this.props.showSoundMetadata)
  }

  private toggleSeekSliderPanel = () => {
    this.props.showOrHideSeekSliderPanel(!this.props.showSeekSliderPanel)
  }

  private toggleSoundPlayerPanel = () => {
    this.props.showOrHideSoundPlayerPanel(!this.props.showSoundPlayerPanel)
  }

  private togglePlaybackRateControls = () => {
    this.props.showOrHidePlaybackRateControls(!this.props.showPlaybackRateControls)
  }

  private toggleToolPanel = () => {
    this.props.showOrHideToolPanel(!this.props.showToolPanel)
  }

  private toggleBeeps = () => {
    this.props.enableOrDisableBeeps(!this.props.beepsAreEnabled)
  }

  private toggleVibrations = () => {
    this.props.enableOrDisableVibrations(!this.props.vibrationsAreEnabled)
  }

  private toggleSpeech = () => {
    this.props.enableOrDisableSpeech(!this.props.speechIsEnabled)
  }

  private toggleUseRealFullScreen = () => {
    this.props.setUseRealFullScreen(!this.props.useRealFullScreen)
  }

  private toggleDistanceVisualization = () => {
    this.props.setDistanceVisualization(!this.props.distanceVisualization)
  }

  private themeChanged = (event: any) => {
    this.props.changeTheme(event.target.value)
  }
}

export const SettingsList = withTheme(withStyles(styles)(RawSettingsList))