import * as React from 'react'
import {Component} from 'react'
import {
  Avatar,
  createStyles,
  IconButton,
  ListItemText,
  Menu,
  MenuItem,
  Theme,
  Tooltip,
  withStyles,
  WithStyles,
  WithTheme,
  withTheme
} from '@material-ui/core'
import {SoundMarker} from '../model/SoundMarker'
import CloudDownloadIcon from '@material-ui/icons/CloudDownload'
import DownloadIcon from '@material-ui/icons/SaveAlt'
import LinkIcon from '@material-ui/icons/Link'
import MoreHorizIcon from '@material-ui/icons/MoreHoriz'

import CopyToClipboard from 'react-copy-to-clipboard'
import {RatingDecorator} from './RatingDecorator'
// @ts-ignore
import {ControlRow} from './ControlRow'
import {ControlStack} from './ControlStack'
import {PersonalMarkerIcon} from '../util/layout-util'
import CSSTransition from 'react-transition-group/CSSTransition'

export interface DataProps {
  currentPublicSoundAddress?: string
  soundMarkers: SoundMarker[]
  nearbySoundMarker?: SoundMarker
  isAvailableOffline: boolean
  copyableSoundMarkerUrl?: URL
  downloadUrl?: URL
  downloadFileName?: string
  maxMarkerColoringDistanceInSeconds: number
  isOnline: boolean
  activeSoundMarker?: SoundMarker
  downloadCompleteRemoteFileSupported: boolean
  downloadCroppedFileSupported: boolean
  ufoAlarm: boolean
}

export interface DispatchProps {
  toggleMarker: () => void
  makeAvailableOffline: () => void,
  removeOfflineCopy: () => void
  downloadCompleteRemoteFile: () => void
  downloadReaperItem: () => void
  downloadLiveClip: () => void
  downloadCroppedFile: () => void
  markerUrlCopiedToClipboard: (successful: boolean) => void
  notifyUfoAlarmOver: () => void
}

interface State {
  anchorEl?: HTMLButtonElement
}

const styles = (theme: Theme) => createStyles({
  bigIcon: {
    height: 48,
    width: 48,
  },
  bigIconButton: {
    padding: '4px'
  },
  buttonLink: {
    color: 'inherit'
  },
  root: {
    // We don't want to take too much vertical space just because of the Material UI button animation. But
    // unfortunately negative margin messes up layout on anchor scrolling (everything moves up a bit).
    margin: '0 0',
  },
  ufoGarage: {
    position: 'relative',
    fontSize: 0,
  },
  ufo: {
    position: 'absolute',
    opacity: 0,
    top: 0,
    left: 0,
  },
  ufoVisible: {
    opacity: 1,
  },
  ufoInvisible: {
    opacity: 0,
  },
  ufoHigh: {
    transform: 'translate(0, -50vh)',
  },
  ufoFlying: {
    transition: theme.transitions.create(['transform'], {duration: 1000, easing: 'ease-out'})
  },
  ufoFading: {
    transition: theme.transitions.create(['opacity'], {duration: 1000, easing: 'ease-in'}),
  },
  ufoFlyingAndFading: {
    transition: [
      theme.transitions.create(['transform'], {duration: 1000, easing: 'ease-out'}),
      theme.transitions.create(['opacity'], {duration: 1000, easing: 'ease-in'}),
    ].join(', ')
  },
  primary: {
    color: theme.palette.primary.contrastText,
    backgroundColor: theme.palette.primary.main
  },
  secondary: {
    color: theme.palette.secondary.contrastText,
    backgroundColor: theme.palette.secondary.main
  }
})

function complainThatWrongFileName() {
  return alert('Please download this file first using the button to the left! Otherwise it will end ' +
    'up with the wrong file name.')
}

class RawToolPanel extends Component<DataProps & DispatchProps & WithStyles<typeof styles> & WithTheme, State> {
  public state: State = {
    anchorEl: undefined
  }

  public render() {
    const classes = this.props.classes
    const nearbySoundMarker = this.props.nearbySoundMarker
    return (
      <ControlRow className={classes.root}>
        <ControlStack>
          <ControlRow>
            {this.props.isAvailableOffline ? this.RemoveOfflineSoundButton() : this.MakeAvailableOfflineButton()}
            {this.DownloadButton()}
          </ControlRow>
        </ControlStack>
        <ControlStack>
          <Tooltip title="Create/delete marker">
            <div>
              <IconButton onClick={this.props.toggleMarker} className={classes.bigIconButton}
                          disabled={this.props.currentPublicSoundAddress === undefined}>
                <RatingDecorator rating={nearbySoundMarker ? nearbySoundMarker.rating : undefined}>
                  {this.favoriteIcon}
                </RatingDecorator>
              </IconButton>
            </div>
          </Tooltip>
        </ControlStack>
        <ControlStack>
          <ControlRow>
            {this.CopyToClipboardButton()}
            {this.MenuButton()}
          </ControlRow>
        </ControlStack>
      </ControlRow>
    )
  }

  private createRatingUfo(expectedRating: number, soundMarker: SoundMarker | undefined, className: string) {
    const classes = this.props.classes
    return (
        <CSSTransition in={soundMarker !== undefined && soundMarker.rating === expectedRating}
                       timeout={{enter: 1000, exit: 0}}
                       onEntered={this.props.notifyUfoAlarmOver}
                       onExited={this.props.notifyUfoAlarmOver}
                       classNames={this.props.ufoAlarm ? {
                         enter: `${classes.ufoVisible}`,
                         enterActive: `${classes.ufoFlying} ${classes.ufoHigh}`,
                         enterDone: `${classes.ufoFlyingAndFading} ${classes.ufoHigh} ${classes.ufoInvisible}`,
                         exit: `${classes.ufoFlyingAndFading} ${classes.ufoHigh} ${classes.ufoInvisible}`,
                         exitActive: `${classes.ufoFlyingAndFading} ${classes.ufoHigh} ${classes.ufoInvisible}`,
                         exitDone: `${classes.ufoFlyingAndFading} ${classes.ufoHigh} ${classes.ufoInvisible}`,
                       } : {}}>
            <div className={classes.ufo}>
                <Avatar className={[this.props.classes.bigIcon, className].join(' ')}>
                  {expectedRating}
                </Avatar>
            </div>
        </CSSTransition>
    )
  }

  private get favoriteIcon() {
    const {classes, nearbySoundMarker} = this.props
    const className = [
      classes.bigIcon,
      nearbySoundMarker ? 'pulse' : undefined
    ].filter(it => it).join(' ')
    return (
      <div className={classes.ufoGarage}>
        <>
          <CSSTransition in={nearbySoundMarker !== undefined} timeout={{enter: 1000, exit: 1000}}
                         onEntered={this.props.notifyUfoAlarmOver}
                         onExited={this.props.notifyUfoAlarmOver}
                         classNames={this.props.ufoAlarm ? {
                           enter: `${classes.ufoFlying} ${classes.ufoVisible}`,
                           enterActive: `${classes.ufoFlying} ${classes.ufoHigh}`,
                           enterDone: `${classes.ufoFading} ${classes.ufoHigh} ${classes.ufoInvisible}`,
                           exit: `${classes.ufoFlying} ${classes.ufoVisible}`,
                           exitActive: classes.ufoFlying,
                           exitDone: `${classes.ufoFading} ${classes.ufoInvisible}`,
                         } : {}}>
            <div className={classes.ufo}>
              <PersonalMarkerIcon className={classes.bigIcon}/>
            </div>
          </CSSTransition>
          {this.createRatingUfo(3, nearbySoundMarker, classes.primary)}
          {this.createRatingUfo(4, nearbySoundMarker, classes.primary)}
          {this.createRatingUfo(5, nearbySoundMarker, classes.secondary)}
        </>
        <PersonalMarkerIcon className={className} color={nearbySoundMarker ? 'secondary' : 'inherit'}/>
      </div>
    )
  }

  private CopyToClipboardButton = () => {
    // TODO Might be unnecessarily expensive to put regularly changing soundMarkerUrl in props
    return (
      <Tooltip title="Copy marker to current position as marker URL">
        <div>
          <CopyToClipboard
            onCopy={this.onCopy}
            text={this.props.copyableSoundMarkerUrl ? this.props.copyableSoundMarkerUrl.toString() : ''}>
            <IconButton disabled={this.props.downloadUrl === undefined}>
              <LinkIcon/>
            </IconButton>
          </CopyToClipboard>
        </div>
      </Tooltip>
    )
  }

  private onCopy = (text: string, result: boolean) => {
    this.props.markerUrlCopiedToClipboard(result)
  }

  private DownloadButton = () => {
    return (
      <Tooltip title="Download file to disk">
        <div>
          <this.RawDownloadButton/>
        </div>
      </Tooltip>
    )
  }

  private RawDownloadButton = () => {
    if (!this.props.downloadUrl) {
      return (
        <IconButton disabled={true}>
          <DownloadIcon/>
        </IconButton>
      )
    }
    const buttonEnabled = (this.props.isOnline || this.props.isAvailableOffline)
    const isBlob = this.props.downloadUrl.toString().startsWith('blob')
    if (isBlob) {
      // We have a blob so we can download via link.
      return (
        <a href={buttonEnabled ? this.props.downloadUrl.toString() : undefined}
           download={this.props.downloadFileName}
           className={this.props.classes.buttonLink}
        >
          <IconButton disabled={!buttonEnabled}>
            <DownloadIcon/>
          </IconButton>
        </a>
      )
    } else {
      // We don't have a blob so we cannot download via link. Because if it's not a blob, the browser will ignore
      // the custom downloadFileName. However, this file name is important because an ALC file might refer to it.
      const action = this.props.downloadCompleteRemoteFileSupported
        ? this.props.downloadCompleteRemoteFile
        : complainThatWrongFileName
      return (
        <IconButton disabled={!buttonEnabled} onClick={action}>
          <DownloadIcon/>
        </IconButton>
      )
    }
  }

  private MenuButton = () => {
    const menuIsOpen = this.state.anchorEl !== undefined
    const menuItems = this.createMenuItems()
    return (
      <>
        <IconButton
          aria-owns={menuIsOpen ? 'menu-appbar' : undefined}
          aria-haspopup="true"
          onClick={this.openMenu}
          color="inherit"
          disabled={menuItems.length === 0}
        >
          <MoreHorizIcon/>
        </IconButton>
        <Menu
          id="menu-appbar"
          anchorEl={this.state.anchorEl}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          open={menuIsOpen}
          onClose={this.closeMenu}
        >
          {menuItems}
        </Menu>
      </>
    )
  }

  private createMenuItems = () => {
    const menuItems: JSX.Element[] = []
    if (this.props.downloadUrl !== undefined && this.props.isOnline) {
      menuItems.push(
        <MenuItem key="open-in-new-tab" component={'a'} href={this.props.currentPublicSoundAddress} target="_blank">
          Open file address in new tab
        </MenuItem>
      )
    }
    if (this.props.activeSoundMarker) {
      menuItems.push(
        <MenuItem key="download-live-clip" onClick={this.downloadLiveClip}>
          <ListItemText primary="Download section as Ableton Live clip"
                        secondary="Please download complete file to disk before!"/>
        </MenuItem>
      )
      menuItems.push(
        <MenuItem key="download-reaper-item" onClick={this.downloadReaperItem}>
          <ListItemText primary="Download section as REAPER item"
                        secondary="Please download complete file to disk before!"/>
        </MenuItem>
      )
      if (this.props.downloadCroppedFileSupported) {
        menuItems.push(
          <MenuItem key="download-cropped-file" onClick={this.downloadCropped}>
            <ListItemText primary="Download section as audio file" secondary="Works for CBR MP3s only!"/>
          </MenuItem>
        )
      }
    }
    return menuItems
  }

  private downloadLiveClip = () => {
    this.closeMenu()
    this.props.downloadLiveClip()
  }

  private downloadReaperItem = () => {
    this.closeMenu()
    this.props.downloadReaperItem()
  }

  private downloadCropped = () => {
    this.closeMenu()
    this.props.downloadCroppedFile()
  }

  private openMenu = (event: React.MouseEvent<{}>) => {
    this.setState({
      anchorEl: event.currentTarget as HTMLButtonElement
    })
  }

  private closeMenu = () => {
    this.setState({
      anchorEl: undefined
    })
  }

  private MakeAvailableOfflineButton = () => {
    return (
      <Tooltip title="Download file to offline storage">
        <div>
          <IconButton disabled={this.props.downloadUrl === undefined || !this.props.isOnline}
                      onClick={this.props.makeAvailableOffline}>
            <CloudDownloadIcon/>
          </IconButton>
        </div>
      </Tooltip>
    )
  }

  private RemoveOfflineSoundButton = () => {
    return (
      <Tooltip title="Remove download">
        <div>
          <IconButton onClick={this.removeOfflineCopy}>
            <CloudDownloadIcon color="secondary"/>
          </IconButton>
        </div>
      </Tooltip>
    )
  }

  private removeOfflineCopy = () => {
    if (window.confirm('Really remove download of this file?')) {
      this.props.removeOfflineCopy()
    }
  }
}

export const ToolPanel = withTheme(withStyles(styles)(RawToolPanel))