import * as React from 'react'
import {Component} from 'react'
import {createStyles, IconButton, Theme, withStyles, WithStyles, WithTheme, withTheme} from '@material-ui/core'
import PlayArrowIcon from '@material-ui/icons/PlayArrow'
import FastForwardIcon from '@material-ui/icons/FastForward'
import FastRewindIcon from '@material-ui/icons/FastRewind'
import PauseIcon from '@material-ui/icons/Pause'
import 'rc-slider/assets/index.css'
import SkipPreviousIcon from '@material-ui/icons/SkipPrevious'
import SkipNextIcon from '@material-ui/icons/SkipNext'
import {ControlRow} from './ControlRow'
import {ControlStack} from './ControlStack'

export interface DataProps {
  soundLoaded: boolean
  playing: boolean
}

export interface DispatchProps {
  fastRewind: () => void
  fastForward: () => void
  togglePlayPause: () => void
  skipPrevious: () => void
  skipNext: () => void
}

const styles = (theme: Theme) => createStyles({
  bigIcon: {
    height: 48,
    width: 48,
  },
  iconButton: {
    padding: '4px'
  },
  root: {
    display: 'flex',
    flexDirection: 'column',
    userSelect: 'none',
    // We don't want to take too much vertical space just because of the Material UI button animation. But
    // unfortunately negative margin messes up layout on anchor scrolling (everything moves up a bit).
    margin: '0 0',
  },
  disabled: {
    color: theme.palette.text.disabled
  },
})

class RawSoundPlayerPanel extends Component<DataProps & DispatchProps & WithStyles<typeof styles> & WithTheme> {
  public render() {
    const classes = this.props.classes
    return (
      <div className={classes.root}>
        <ControlRow>
          <ControlStack>
            <IconButton onClick={this.props.skipPrevious}
                        className={classes.iconButton}>
              <SkipPreviousIcon/>
            </IconButton>
          </ControlStack>
          <ControlStack>
            <ControlRow>
              <IconButton onClick={this.props.fastRewind} disabled={!this.props.soundLoaded}
                          className={classes.iconButton}>
                <FastRewindIcon/>
              </IconButton>
              <IconButton onClick={this.props.togglePlayPause}
                          className={classes.iconButton}>
                {
                  this.props.playing
                    ? <PauseIcon className={classes.bigIcon}/>
                    : <PlayArrowIcon className={classes.bigIcon}/>
                }
              </IconButton>
              <IconButton onClick={this.props.fastForward} disabled={!this.props.soundLoaded}
                          className={classes.iconButton}>
                <FastForwardIcon/>
              </IconButton>
            </ControlRow>
          </ControlStack>
          <ControlStack>
            <IconButton onClick={this.props.skipNext}
                        className={classes.iconButton}>
              <SkipNextIcon/>
            </IconButton>
          </ControlStack>
        </ControlRow>
      </div>
    )
  }

}

export const SoundPlayerPanel = withTheme(withStyles(styles)(RawSoundPlayerPanel))