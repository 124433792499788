import * as React from 'react'
import {ReactNode} from 'react'
import {Badge} from '@material-ui/core'
import {BadgeProps} from '@material-ui/core/Badge'

export function RatingDecorator(props: {
  rating: number | undefined
  children: ReactNode
} & BadgeProps) {
  const {rating, children, ...badgeProps} = props
  return (
    <Badge badgeContent={rating} color={rating === 5 ? 'secondary' : 'primary'} {...badgeProps}>
      {children}
    </Badge>
  )
}
