import {SoundMarker} from '../model/SoundMarker'
import {partition} from 'lodash/fp'
import {
  createPublicGoogleDriveAddress,
  getGoogleDriveFilesFromFolderUrl,
  GoogleDriveDocument,
  isGoogleDriveFolderUrl
} from './cloud/google-drive'
import {generateSoundMarkerUrlPath, parseSoundMarkerUrlPath} from './sound-marker-uri-util'
import {extractPlainTextFromMdastNode} from './markdown-util'
import {createDefaultSoundMarker} from './sound-marker-util'

export interface SoundMarkerSectionEntry {
  marker: SoundMarker
}

export interface SoundMarkerSection {
  title: string
  entries: SoundMarkerSectionEntry[]
}

export async function buildSoundMarkerSectionsFromUrlList(urls: URL[]) {
  const [folderUrls, fileUrls] = partition(isGoogleDriveFolderUrl)(urls)
  const folderContentPromises = folderUrls.map(getGoogleDriveFilesFromFolderUrl)
  const folderContents = await Promise.all(folderContentPromises)
  const folderSections = folderContents.map((folderFiles, i) => {
    return {
      title: `Imported sounds from folder ${i + 1}`,
      entries: folderFiles.map(createSectionEntryFromFolderFile)
    } as SoundMarkerSection
  })
  const remainingFilesSection: SoundMarkerSection = {
    title: folderSections.length === 0 ? 'Imported sounds' : 'Remaining imported sounds',
    entries: fileUrls.map(url => {
      return {
        marker: createStartSoundMarker(url)
      }
    })
  }
  if (remainingFilesSection.entries.length === 0) {
    return folderSections
  } else {
    return [
      ...folderSections,
      remainingFilesSection
    ]
  }
}

function createStartSoundMarker(fileUrl: URL, name?: string): SoundMarker {
  return {
    ...createDefaultSoundMarker(fileUrl.toString()),
    name,
  }
}

function createSectionEntryFromFolderFile(folderFile: GoogleDriveDocument): SoundMarkerSectionEntry {
  return {
    marker: createStartSoundMarker(createPublicGoogleDriveAddress(folderFile.id), folderFile.name),
  }
}

export function formatSoundMarkerSectionsAsMarkdown(sections: SoundMarkerSection[]) {
  return sections.map(formatSoundMarkerSectionAsMarkdown).join('\n\n\n')
}

export function formatSoundMarkerSectionAsMarkdown(section: SoundMarkerSection) {
  return formatSoundMarkerTitleAsMarkdown(section.title) + '\n\n' + formatSectionEntriesAsMarkdown(section.entries)
}

function formatSoundMarkerTitleAsMarkdown(title: string) {
  return `## ${title}`
}

function formatSectionEntriesAsMarkdown(entries: SoundMarkerSectionEntry[]) {
  return entries.map(formatSectionEntryAsMarkdown).join('\n')
}

function formatSectionEntryAsMarkdown(entry: SoundMarkerSectionEntry) {
  return `- ${formatSoundMarkerAsMarkdownLink(entry.marker)}`
}

export function formatSoundMarkerAsMarkdownLink(marker: SoundMarker) {
  const markerProps: { [key: string]: any } = {
    rating: marker.rating,
    author: marker.author,
    created: marker.creationTimestamp
  }
  const formattedPropArray = Object.keys(markerProps)
    .filter(key => markerProps[key])
    .map(key => `${key}=${markerProps[key]}`)
  const suffix = formattedPropArray.length > 0 ? `{${formattedPropArray.join(' ')}}` : ''
  return `[${marker.name || ''}](${generateSoundMarkerUrlPath(marker)})${suffix}`
}

export function extractSoundMarkerFromMdastNode(linkNode: any): SoundMarker | undefined {
  if (!linkNode.url) {
    return undefined
  }
  const soundMarker = parseSoundMarkerUrlPath(linkNode.url)
  if (!soundMarker) {
    return undefined
  }
  return {
    ...soundMarker,
    name: extractPlainTextFromMdastNode(linkNode),
    rating: parseRatingExpression(extractPropFromMdastNode(linkNode, 'rating')),
    author: extractPropFromMdastNode(linkNode, 'author'),
    creationTimestamp: parseTimestamp(extractPropFromMdastNode(linkNode, 'created')),
  }
}

function extractPropFromMdastNode(linkNode: any, key: string): string | undefined {
  return linkNode.data && linkNode.data.hProperties ? linkNode.data.hProperties[key] : undefined
}

function parseTimestamp(timestampExpression: string | undefined) {
  if (!timestampExpression) {
    return undefined
  }
  const parsedTimestamp = parseInt(timestampExpression, 10)
  return isNaN(parsedTimestamp) ? undefined : parsedTimestamp
}

function parseRatingExpression(ratingExpression: string | undefined) {
  const rating = Number(ratingExpression)
  return rating >= 3 && rating <= 5 ? rating : undefined
}