import {ApplicationState} from '../store/ApplicationState'
import {connect} from 'react-redux'
import {Dispatch} from 'redux'
import {DataProps, DispatchProps, SoundSourcePanel} from '../components/SoundSourcePanel'
import {commitTypedPublicSoundAddress, publicSoundAddressTyped} from '../store/player/actions'

function mapStateToProps(state: ApplicationState): DataProps {
  return {
    address: state.player.typedPublicSoundAddress,
  }
}

function mapDispatchToProps(dispatch: Dispatch): DispatchProps {
  return {
    addressChanged: address => dispatch(publicSoundAddressTyped({address})),
    commit: () => dispatch(commitTypedPublicSoundAddress()),
  }
}

export const SoundSourcePanelContainer = connect(mapStateToProps, mapDispatchToProps)(SoundSourcePanel)