import * as React from 'react'
import {Component} from 'react'
import {
  Collapse,
  createStyles,
  Hidden,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Theme,
  WithStyles,
  withStyles
} from '@material-ui/core'
import {ExpandLess, ExpandMore} from '@material-ui/icons'
import {StyledBreadcrumb} from './StyledBreadcrumb'
import {SheetType} from '../model/SheetType'

export interface TocEntry {
  label: string
  elementId: string
  subEntries: TocEntry[]
}

export interface DataProps {
  tocEntries: TocEntry[]
  expandedSectionElementIds: string[]
  sheetTitle?: string
  sheetType?: SheetType
  sheetIsLoading: boolean
}

export interface DispatchProps {
  toggleSectionExpanded: (sectionElementId: string) => void
  entryChosen: () => void
}

const styles = (theme: Theme) => createStyles({
  root: {
    overflowY: 'auto'
  },
  nested: {
    paddingLeft: theme.spacing(8)
  },
  smallButton: {
    margin: 0,
    padding: 0,
  },
  breadcrumbAvatar: {
    background: 'none',
    marginRight: -theme.spacing(1.5)
  },
  link: {
    textDecoration: 'none',
    userSelect: 'none',
    '&:focus': {
      outlineStyle: 'none'
    }
  },
  breadcrumbs: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'nowrap',
    padding: 0,
    listStyleType: 'none',
    alignItems: 'center',
    minWidth: 0, // Allow items to have less width than content
  },
  breadcrumb: {
    display: 'flex',
    alignItems: 'center',
  },
  sheetBreadcrumb: {
    minWidth: 0,
    display: 'flex',
  },
})

class RawSheetStructureList extends Component<DataProps & DispatchProps & WithStyles<typeof styles>> {
  public render() {
    const classes = this.props.classes
    return (
      <List className={classes.root} disablePadding={true}>
        <Hidden smUp={true} implementation="css">
          {this.props.sheetTitle &&
          <ListItem dense={true} divider={false} selected={false}>
              <StyledBreadcrumb label={this.props.sheetTitle} color="primary"/>
          </ListItem>
          }
        </Hidden>
        {this.props.tocEntries.map(this.createFirstLevelTocEntryItem)}
      </List>
    )
  }

  private createFirstLevelTocEntryItem = (tocEntry: TocEntry) => {
    return (
      <React.Fragment key={tocEntry.elementId}>
        <ListItem button={true} component="a" href={`#${tocEntry.elementId}`} dense={false} divider={true}
                  onClick={this.props.entryChosen}>
          <ListItemText>
            {tocEntry.label}
          </ListItemText>
          {tocEntry.subEntries.length > 0 &&
          <IconButton className={this.props.classes.smallButton} disableRipple={true}
                      onClick={evt => this.toggleSectionExpanded(evt, tocEntry.elementId)}>
            {this.sectionIsExpanded(tocEntry.elementId) ? <ExpandLess/> : <ExpandMore/>}
          </IconButton>
          }
        </ListItem>
        {tocEntry.subEntries.length > 0 &&
        <Collapse in={this.sectionIsExpanded(tocEntry.elementId)} timeout="auto" unmountOnExit={true}>
            <List dense={true}>
              {tocEntry.subEntries.map(this.createSecondLevelTocEntryItem)}
            </List>
        </Collapse>
        }
      </React.Fragment>
    )
  }

  private sectionIsExpanded = (sectionElementId: string) => {
    return this.props.expandedSectionElementIds.indexOf(sectionElementId) !== -1
  }

  private toggleSectionExpanded = (evt: React.MouseEvent<any>, sectionElementId: string) => {
    evt.preventDefault()
    evt.stopPropagation()
    this.props.toggleSectionExpanded(sectionElementId)
  }

  private createSecondLevelTocEntryItem = (tocEntry: TocEntry) => {
    return (
      <ListItem key={tocEntry.elementId} button={true} component="a" href={`#${tocEntry.elementId}`} dense={true}
                className={this.props.classes.nested} onClick={this.props.entryChosen}>
        <ListItemText>
          {tocEntry.label}
        </ListItemText>
      </ListItem>
    )
  }
}

export const SheetStructureList = withStyles(styles)(RawSheetStructureList)