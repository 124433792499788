import {ApplicationState} from '../ApplicationState'
import {createSelector} from 'reselect'
import {IndexedSoundMarker} from '../../model/IndexedSoundMarker'

export const deviceSoundMarkersWithoutIndexSelector = (state: ApplicationState) => state.marker.soundMarkers

export const deviceSoundMarkersSelector = createSelector(
  deviceSoundMarkersWithoutIndexSelector,
  (deviceSoundMarkersWithoutIndex): IndexedSoundMarker[] => {
    return deviceSoundMarkersWithoutIndex.map((m, i) => {
      return {
        ...m,
        index: i
      }
    })
  }
)