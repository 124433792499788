import {Subject} from 'rxjs'

let installPromptEvent: Event | undefined
const installPromptAvailableSubject = new Subject<void>()
init()

function init() {
  window.addEventListener('beforeinstallprompt', e => {
    // Prevent Chrome 67 and earlier from automatically showing the prompt
    e.preventDefault()
    installPromptEvent = e
    installPromptAvailableSubject.next()
  })
}

export function installPromptAvailable(): Promise<void> {
  return installPromptAvailableSubject.toPromise()
}

export function canBeInstalled() {
  return installPromptEvent !== undefined
}

export function showInstallPrompt() {
  if (!installPromptEvent) {
    return
  }
  (installPromptEvent as any).prompt()
}