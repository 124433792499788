import {Duration} from 'js-joda'
import 'soundmanager2'
import {concat, EMPTY, Observable, Subject, Subscriber, Subscription, timer} from 'rxjs'
import {distinctUntilChanged, ignoreElements} from 'rxjs/operators'
// @ts-ignore
import silenceFile from '../util/sounds/silence.mp3'

type SMSound = soundmanager.SMSound

const soundManager = (window as any).soundManager
soundManager.debugMode = false

// Some browsers such as Safari are too stupid to correlate the play/marker click with the actual
// playing of the audio element, therefore consider it as unwanted auto-play and block it. This function
// should be called on the first user interaction.
export function initAudio() {
  const s = soundManager.createSound({
    url: silenceFile
  })
  sound = s
  s.play()
}

export enum PlayState {
  Playing = 'playing',
  Paused = 'paused'
}

function soundIsLoaded(sound: SMSound) {
  return sound.readyState === 3
}

function soundIsLoading(sound: SMSound) {
  return sound.readyState === 1
}

// TODO SoundManager2 hides quite much of the HTML5 audio API, e.g. rate change and stalled notification.
// Maybe we should use HTML audio directly?
let sound: SMSound | undefined
const playStateChangedSubject = new Subject<PlayState>()
const soundEndReachedSubject = new Subject<void>()
const stalledSubject = new Subject<void>()
const playPositionSubject = new Subject<Duration>()

// Returns a cold observable, so nothing happens until someone subscribes to it. The observable completes as soon as
// the sound has been loaded. It fails with error if an error occurred while loading. It doesn't emit elements.
// We don't take a Promise because we might want to execute some disposal logic if client (e.g. a switchMap)
// decides to cancel (unsubscribe) the current loading processes. That's only possible with Observables.
export function loadAndPlayFrom(streamingUrl: URL, position: Duration, playbackRate: number = 1, volume: number = 100): Observable<never> {
  return new Observable(subscriber => {
    let timerSubscription: Subscription
    // Unfortunately the soundmanager2 type definitions are messed up a bit
    const soundManagerAny = soundManager as any
    soundManagerAny.onready(() => {
      if (sound && soundIsLoaded(sound) && sound.url === streamingUrl.toString()) {
        // Requested sound already loaded. Just seek.
        seek(position)
        if (playState() !== PlayState.Playing) {
          play()
        }
        subscriber.complete()
        return
      }
      // Requested sound not yet loaded
      const loadOptions = createLoadOptions(streamingUrl, playbackRate, volume, position, subscriber)
      if (sound) {
        // Another sound has already been played in this session. Reuse it.
        timerSubscription = unloadOldSoundAndTriggerPlayOfNewOne(sound, position, loadOptions).subscribe()
      } else {
        // First play of a sound in this session
        const s = soundManagerAny.createSound(loadOptions)
        sound = s
        playInternal(s)
      }
    })
    // Return cleanup/disposal function. It is executed when Observable completes/fails or if unsubscribed. If the
    // Observable has completed/failed, there's nothing to clean up, so we are only talking about unsubcribe here.
    // Currently, the only use case involving an unsubscribe is if the user quickly changes his decision and decides
    // to play another sound.
    return () => {
      if (sound && soundIsLoading(sound)) {
        sound.unload()
      }
      if (timerSubscription) {
        timerSubscription.unsubscribe()
      }
    }
  })
}

function playInternal(sound: SMSound) {
  sound.play()
}

export function play() {
  if (!sound) {
    return
  }
  if (sound.playState === 0) {
    playInternal(sound)
  }
  if (sound.paused) {
    sound.resume()
  }
}

export function pause() {
  if (!sound) {
    return
  }
  sound.pause()
}

export function playState() {
  if (!sound) {
    return PlayState.Paused
  }
  if (sound.playState === 0 || sound.paused) {
    return PlayState.Paused
  } else {
    return PlayState.Playing
  }
}

export function playPosition() {
  if (!sound) {
    return Duration.ZERO
  }
  // We just want second precision
  return Duration.ofSeconds(Math.floor(sound.position / 1000))
}

export function soundLength() {
  if (!sound) {
    return Duration.ZERO
  }
  return Duration.ofMillis(sound.duration || sound.durationEstimate || 0)
}

export function setPlaybackRate(playbackRate: number) {
  if (!sound) {
    return
  }
  soundManager.setPlaybackRate('sound0', playbackRate)
}

export function setVolume(volume: number) {
  if (!sound) {
    return
  }
  soundManager.setVolume('sound0', volume)
}

export function seek(position: Duration) {
  if (!sound) {
    return
  }
  sound.setPosition(position.toMillis())
}

export function playStateChanged(): Observable<PlayState> {
  return playStateChangedSubject.asObservable()
}

export function soundEndReached(): Observable<void> {
  return soundEndReachedSubject.asObservable()
}

export function playPositionChanged(): Observable<Duration> {
  return playPositionSubject.asObservable().pipe(
    distinctUntilChanged()
  )
}

export function stalled(): Observable<void> {
  return stalledSubject.asObservable()
}

function unloadOldSoundAndTriggerPlayOfNewOne(sound: SMSound, position: Duration, loadOptions: object): Observable<never> {
  return concat(
    unloadSoundIfNecessary(sound),
    new Observable<never>(subscriber => {
      sound.load(loadOptions)
      // Because we called unload before, we have to explicitly set position (don't know why)
      sound.setPosition(position.toMillis())
      playInternal(sound)
      subscriber.complete()
    })
  )
}

function createLoadOptions(streamingUrl: URL, playbackRate: number, volume: number, position: Duration, subscriber: Subscriber<void>) {
  return {
    url: streamingUrl.toString(),
    volume,
    autoLoad: false,
    playbackRate,
    position: position.toMillis(),
    onload: (success: boolean) => {
      if (success) {
        const soundUntyped = sound as any
        soundUntyped._a.onstalled = () => {
          stalledSubject.next()
        }
        subscriber.complete()
      }
    },
    onplay: () => {
      playStateChangedSubject.next(playState())
    },
    onpause: () => {
      playStateChangedSubject.next(playState())
    },
    onstop: () => {
      playStateChangedSubject.next(playState())
    },
    onresume: () => {
      playStateChangedSubject.next(playState())
    },
    whileplaying: () => {
      if (sound && soundIsLoaded(sound) && !sound.paused) {
        playPositionSubject.next(playPosition())
      }
    },
    onfinish: () => {
      playStateChangedSubject.next(playState())
      soundEndReachedSubject.next()
    },
    onerror: (errorCode: number, description: string) => {
      subscriber.error(description)
    },
  }
}

function unloadSoundIfNecessary(sound: SMSound): Observable<never> {
  // If we don't unload the old sound first, the following scenario can happen:
  // 1. User requests sound A. Loading sound A fails. Error is correctly reported.
  // 2. User requests sound B. Loading sound B fails. Error is *not* reported because onerror not fired.
  if (soundIsLoaded(sound) || soundIsLoading(sound)) {
    sound.unload()
    // We need to wait a bit, otherwise loading will fail
    return timer(50).pipe(
      ignoreElements()
    )
  } else {
    return EMPTY
  }
}