import {ApplicationState} from '../ApplicationState'
import {createSelector} from 'reselect'
import {currentThemeSelector} from '../settings/selectors'
import {InitialLogo} from '../../components/logos/InitialLogo'
import {createElement, SVGProps} from 'react'
import {LoudspeakerLogo} from '../../components/logos/LoudspeakerLogo'
import {SoundMarkerListType} from '../../model/SoundMarkerListType'
import {TopView} from '../../model/TopView'
import {getCurrentSpace, isLocalSpace} from '../../util/space-util'

export const isOnlineSelector = (state: ApplicationState) => state.layout.isOnline
export const authHasBeenCheckedSelector = (state: ApplicationState) => state.layout.authHasBeenChecked
export const signedInUserSelector = (state: ApplicationState) => state.layout.signedInUser
export const logoIndexSelector = (state: ApplicationState) => state.layout.logoIndex
export const topViewSelector = (state: ApplicationState) => state.layout.topView
export const targetedSoundMarkerSelector = (state: ApplicationState) => state.layout.targetedSoundMarker
export const ufoAlarmSelector = (state: ApplicationState) => state.layout.ufoAlarm

// Returns undefined if auth has not been checked yet (right after page load)
export const isSignedInSelector = createSelector(
  [authHasBeenCheckedSelector, signedInUserSelector],
  (authHasBeenChecked, signedInUser) => {
    if (!authHasBeenChecked) {
      return undefined
    }
    return signedInUser !== undefined
  }
)

// Returns undefined if we don't know yet if sign-in is necessary (it's still being checked)
export const signInIsNecessarySelector = createSelector(
  isSignedInSelector,
  isSignedIn => {
    if (isLocalSpace(getCurrentSpace())) {
      return false
    }
    if (isSignedIn === undefined) {
      return undefined
    }
    return !isSignedIn
  }
)

const logos = [
  InitialLogo,
  LoudspeakerLogo,
]

export const currentLogoSelector = createSelector(
  [logoIndexSelector, currentThemeSelector],
  (logoIndex, currentTheme) => {
    const logo = logos[logoIndex % logos.length]
    return (props: SVGProps<SVGSVGElement>) => {
      return createElement(logo, {
        className: props.className,
        theme: currentTheme
      })
    }
  }
)

export const targetedQuickSoundMarkerSelector =
  createContextualTargetedSoundMarkerSelector(SoundMarkerListType.Device, TopView.QuickMarkers)

export const targetedSheetSoundMarkerSelector =
  createContextualTargetedSoundMarkerSelector(SoundMarkerListType.Sheet, TopView.Sheet)

export const targetedAllSheetsSoundMarkerSelector =
  createContextualTargetedSoundMarkerSelector(SoundMarkerListType.Sheet, TopView.AllSheets)

function createContextualTargetedSoundMarkerSelector(requiredListType: SoundMarkerListType, requiredTopView: TopView) {
  return createSelector(
    [topViewSelector, targetedSoundMarkerSelector],
    (topView, targetedSoundMarker) => {
      if (topView !== requiredTopView) {
        return undefined
      }
      if (!targetedSoundMarker || !targetedSoundMarker.listInfo) {
        return undefined
      }
      if (targetedSoundMarker.listInfo.type !== requiredListType) {
        return undefined
      }
      return targetedSoundMarker
    }
  )
}