import {ApplicationState} from '../store/ApplicationState'
import {connect} from 'react-redux'
import {Dispatch} from 'redux'
import {DataProps, DispatchProps, ToolPanel} from '../components/ToolPanel'
import {
  activeSoundMarkerSelector,
  currentSoundIsAvailableOfflineSelector,
  downloadFileNameSelector,
  generatedSoundMarkerUrlSelector,
  nearbyDeviceSoundMarkerSelector,
  publicSoundAddressSelector,
  streamingSoundUrlSelector
} from '../store/player/selectors'
import {toggleOrPushUpSoundMarker} from '../store/marker/actions'
import {
  downloadCurrentMarkerAsCroppedFile,
  downloadCurrentMarkerAsLiveClip,
  downloadCurrentMarkerAsReaperItem,
  downloadCurrentSound,
  makeCurrentSoundAvailableOffline,
  markerUrlCopiedToClipboard,
  removeOfflineCopyOfCurrentSound
} from '../store/player/actions'
import {isOnlineSelector, ufoAlarmSelector} from '../store/layout/selectors'
import {downloadCompleteFileViaStreamingIsSupported, downloadPartialFileIsSupported} from '../util/download-util'
import {enableOrDisableUfoAlarm} from '../store/layout/actions'

function mapStateToProps(state: ApplicationState): DataProps {
  const downloadUrl = streamingSoundUrlSelector(state)
  return {
    currentPublicSoundAddress: publicSoundAddressSelector(state),
    nearbySoundMarker: state.layout.screenIsVisible
      ? nearbyDeviceSoundMarkerSelector(state)
      : undefined,
    soundMarkers: state.marker.soundMarkers,
    isAvailableOffline: currentSoundIsAvailableOfflineSelector(state),
    copyableSoundMarkerUrl: generatedSoundMarkerUrlSelector(state),
    downloadUrl,
    downloadFileName: downloadFileNameSelector(state),
    maxMarkerColoringDistanceInSeconds: state.settings.maxMarkerColoringDistanceInSeconds,
    isOnline: isOnlineSelector(state),
    activeSoundMarker: activeSoundMarkerSelector(state),
    downloadCompleteRemoteFileSupported: downloadCompleteFileViaStreamingIsSupported(),
    downloadCroppedFileSupported: downloadUrl && downloadPartialFileIsSupported(downloadUrl),
    ufoAlarm: ufoAlarmSelector(state),
  }
}

function mapDispatchToProps(dispatch: Dispatch): DispatchProps {
  return {
    toggleMarker: () => dispatch(toggleOrPushUpSoundMarker()),
    makeAvailableOffline: () => dispatch(makeCurrentSoundAvailableOffline()),
    removeOfflineCopy: () => dispatch(removeOfflineCopyOfCurrentSound()),
    downloadCompleteRemoteFile: () => dispatch(downloadCurrentSound()),
    downloadLiveClip: () => dispatch(downloadCurrentMarkerAsLiveClip()),
    downloadReaperItem: () => dispatch(downloadCurrentMarkerAsReaperItem()),
    downloadCroppedFile: () => dispatch(downloadCurrentMarkerAsCroppedFile()),
    markerUrlCopiedToClipboard: successful => dispatch(markerUrlCopiedToClipboard({successful})),
    notifyUfoAlarmOver: () => dispatch(enableOrDisableUfoAlarm(false)),
  }
}

export const ToolPanelContainer = connect(mapStateToProps, mapDispatchToProps)(ToolPanel)