import {createSelector} from 'reselect'
import {pathSelector} from '../router/selectors'
import {matchPath} from 'react-router'
import {extractSheetIdFromPath, Routes} from '../Routes'
import {ApplicationState} from '../ApplicationState'
import {sheetRemark} from '../../util/markdown-util'
import {topViewSelector} from '../layout/selectors'
import {deviceSoundMarkersSelector} from '../marker/selectors'
import {combineSheetMarkersFromAllSheets} from '../../util/sound-marker-util'
import {extractSheetSoundMarkers, extractSheetTitle, extractSheetTocEntries} from '../../util/sheet-markdown-util'
import {mapValues} from 'lodash/fp'
import {SheetType} from '../../model/SheetType'
import {helpSheetId, homeSheetId, isBuiltInSheet} from '../../util/sheet-ids'
import {TopView} from '../../model/TopView'
import {IndexedSoundMarker} from '../../model/IndexedSoundMarker'
import {IndexedSoundMarkerWithListInfo} from '../../model/IndexedSoundMarkerWithListInfo'
import {SoundMarkerListType} from '../../model/SoundMarkerListType'
import {SheetListInfo} from '../../model/ListInfo'
import {getWelcomeSheetContent} from './content'
import * as log from 'loglevel'

export const sheetContentSelector = (state: ApplicationState) => state.sheet.sheetContent

export const sheetInfosSelector = (state: ApplicationState) => state.sheet.sheetInfos

export const currentlyVisibleSheetIdSelector = (state: ApplicationState) => state.sheet.visibleSheetId

export const requestedSheetIdSelector = createSelector(
  pathSelector,
  path => {
    return extractSheetIdFromPath(path)
  }
)

export const isWelcomeSheetSelector = createSelector(
  [currentlyVisibleSheetIdSelector, sheetContentSelector],
  (sheetId, sheetContent) => {
    return sheetId === homeSheetId && sheetContent === getWelcomeSheetContent()
  }
)

export const currentlyVisibleSheetIsReadOnlySelector = createSelector(
  currentlyVisibleSheetIdSelector,
  sheetId => {
    return sheetId !== undefined && isBuiltInSheet(sheetId)
  }
)

export const currentlyVisibleSheetTypeSelector = createSelector(
  currentlyVisibleSheetIdSelector,
  currentSheetId => {
    switch (currentSheetId) {
      case undefined:
        // Undefined means there's no sheet active (currently only when using play URL)
        return undefined
      case homeSheetId:
        return SheetType.HomeSheet
      case helpSheetId:
        return SheetType.HelpSheet
      default:
        return SheetType.OtherSheet
    }
  }
)

export const currentlyVisibleSheetNodeSelector = createSelector(
  sheetContentSelector,
  sheetContent => {
    log.debug('# Parsing...')
    const result = sheetRemark.parse(sheetContent)
    log.debug('Parsing finished')
    return result
  }
)

export const currentlyVisibleSheetTitleSelector = createSelector(
  currentlyVisibleSheetNodeSelector,
  (sheetNode): string | undefined => {
    return extractSheetTitle(sheetNode)
  }
)

export const currentlyVisibleSheetTocEntriesSelector = createSelector(
  currentlyVisibleSheetNodeSelector,
  sheetNode => {
    return extractSheetTocEntries(sheetNode)
  }
)

export const allSheetSoundMarkersSelector = createSelector(
  sheetInfosSelector,
  sheetInfos => {
    return combineSheetMarkersFromAllSheets(sheetInfos)
  }
)


export const currentlyVisibleSheetSoundMarkersSelector = createSelector(
  [currentlyVisibleSheetIdSelector, currentlyVisibleSheetNodeSelector],
  (sheetId, sheetNode) => {
    if (!sheetId) {
      return []
    }
    return qualifySheetSoundMarkers(sheetId, extractSheetSoundMarkers(sheetNode))
  }
)

export function qualifySheetSoundMarkers(sheetId: string, soundMarkers: IndexedSoundMarker[]): IndexedSoundMarkerWithListInfo[] {
  return soundMarkers.map(sm => {
    const listInfo: SheetListInfo = {
      type: SoundMarkerListType.Sheet,
      sheetId,
      index: sm.index,
    }
    return {
      ...sm,
      listInfo
    }
  })
}

export const currentlyVisibleOrAllSheetSoundMarkersSelector = createSelector(
  [topViewSelector, currentlyVisibleSheetSoundMarkersSelector, allSheetSoundMarkersSelector],
  (topView, currentSheetSoundMarkers, allSheetSoundMarkers) => {
    return topView === TopView.AllSheets ? allSheetSoundMarkers : currentSheetSoundMarkers
  }
)

export const numMarkersByPublicSoundAddressSelector = createSelector(
  [currentlyVisibleSheetSoundMarkersSelector, deviceSoundMarkersSelector],
  (sheetSoundMarkers, deviceSoundMarkers) => {
    const map: { [publicSoundAddress: string]: number } = {}
    const allSoundMarkers = [
      ...sheetSoundMarkers,
      ...deviceSoundMarkers
    ]
    allSoundMarkers.forEach(m => {
      if (m.positionInSeconds === undefined) {
        return
      }
      const currentCount = map[m.publicSoundAddress] || 0
      map[m.publicSoundAddress] = currentCount + 1
    })
    return map
  }
)

export const sheetTitlesSelector = createSelector(
  sheetInfosSelector,
  sheetInfos => {
    return mapValues(info => info.title, sheetInfos)
  }
)

export const isInSheetEditModeSelector = createSelector(
  pathSelector,
  path => {
    return matchPath<any>(path, {path: Routes.SheetEdit}) !== null
  }
)