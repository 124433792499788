/* eslint import/no-webpack-loader-syntax: off */
import * as firebase from 'firebase/app'
import 'firebase/database'
import 'firebase/firestore'
import {getCurrentSpace} from './space-util'

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID
}
firebase.initializeApp(firebaseConfig)

export const remoteDb = firebase.firestore()
export const remoteSpaceDocRef = () => remoteDb.collection('spaces').doc(getCurrentSpace())