import {combineReducers} from 'redux'
import {combineEpics, Epic} from 'redux-observable'
import {playerEpic} from './player/epic'
import {playerReducer} from './player/reducer'
import {sheetReducer} from './sheet/reducer'
import {sheetEpic} from './sheet/epic'
import {layoutEpic} from './layout/epic'
import {layoutReducer} from './layout/reducer'
import {settingsReducer} from './settings/reducer'
import {settingsEpic} from './settings/epic'
import {markerReducer} from './marker/reducer'
import {markerEpic} from './marker/epic'
import {controllerReducer} from './controller/reducer'
import {controllerEpic} from './controller/epic'
import * as layoutSelectors from './layout/selectors'
import * as markerSelectors from './marker/selectors'
import * as playerSelectors from './player/selectors'
import * as settingsSelectors from './settings/selectors'
import * as sheetSelectors from './sheet/selectors'

export const rootReducer = combineReducers({
  player: playerReducer,
  marker: markerReducer,
  sheet: sheetReducer,
  controller: controllerReducer,
  layout: layoutReducer,
  settings: settingsReducer
})

export const rootEpic: Epic = combineEpics(
  playerEpic,
  markerEpic,
  sheetEpic,
  controllerEpic,
  layoutEpic,
  settingsEpic
)

export const selectors = {
  ...layoutSelectors,
  ...markerSelectors,
  ...playerSelectors,
  ...settingsSelectors,
  ...sheetSelectors,
}