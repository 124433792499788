import {combineEpics, Epic} from 'redux-observable'
import * as actions from './actions'
import {ActionType} from 'typesafe-actions'
import {ApplicationState} from '../ApplicationState'

const extendedActions = {
  ...actions,
}
type SettingsAction = ActionType<typeof extendedActions>
type SettingsEpic = Epic<SettingsAction, SettingsAction, ApplicationState>

export const settingsEpic = combineEpics(
)