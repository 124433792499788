import * as React from 'react'
import {Component} from 'react'
import DeleteIcon from '@material-ui/icons/Delete'
import OfflinePinIcon from '@material-ui/icons/OfflinePin'
import {AutoSizer, List, ListRowProps} from 'react-virtualized'
import 'react-virtualized/styles.css'
import {
  createStyles,
  IconButton,
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  Theme,
  Typography,
  WithStyles,
  withStyles,
  WithTheme,
  withTheme
} from '@material-ui/core'
import {SoundMarker} from '../model/SoundMarker'
import {calcMarkerDistanceColor, formatDuration, getMarkerIconComponent} from '../util/layout-util'
import {Duration} from 'js-joda'
import {IndexedSoundMarker} from '../model/IndexedSoundMarker'
import {RatingDecorator} from './RatingDecorator'
import {IndexedSoundMarkerWithListInfo} from '../model/IndexedSoundMarkerWithListInfo'
import {findSoundMarkerIndexInListWithGaps} from '../util/sound-marker-util'
import {PlaylistType} from '../model/PlaylistType'

export interface DataProps {
  playlistType: PlaylistType
  currentPublicSoundAddress?: string
  currentPositionInSeconds?: number
  soundMarkers: IndexedSoundMarkerWithListInfo[]
  maxMarkerColoringDistanceInSeconds: number
  offlineSoundAddressSet: Set<string>
  activeSoundMarker?: IndexedSoundMarkerWithListInfo
  currentPlaylistType?: PlaylistType
  targetedSoundMarker?: IndexedSoundMarkerWithListInfo
}

export interface DispatchProps {
  playMarker: (soundMarker: IndexedSoundMarkerWithListInfo) => void
  deleteMarker?: (soundMarker: IndexedSoundMarker) => void
  scrollDone: () => void
}

const styles = (theme: Theme) => createStyles({
  root: {
    height: '100%',
    flex: 1,
  },
  emptyListRoot: {
    flex: 1,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  listItem: {
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.1)'
    }
  },
  primaryListItemText: {
    'whiteSpace': 'nowrap',
    'textOverflow': 'ellipsis',
    'overflow': 'hidden'
  },
  list: {
    outlineStyle: 'none',
  },
  emptyListText: {
    color: theme.palette.action.disabled,
    textAlign: 'center',
  },
})

class RawSoundMarkerList extends Component<DataProps & DispatchProps & WithStyles<typeof styles> & WithTheme> {
  private listRef = React.createRef<List>()

  public render() {
    const classes = this.props.classes
    const listEmpty = this.props.soundMarkers.length === 0
    if (listEmpty) {
      return (
        <div className={classes.emptyListRoot}>
          <Typography variant="h4" className={classes.emptyListText}>No markers to show</Typography>
        </div>
      )
    } else {
      return (
        <div className={classes.root}>
          <AutoSizer>
            {({height, width}) => (
              <List
                {...this.props}
                ref={this.listRef}
                scrollToAlignment="start"
                className={classes.list}
                width={width}
                height={height}
                rowCount={this.props.soundMarkers.length}
                rowHeight={67.5}
                rowRenderer={this.rowRenderer}
              />
            )}
          </AutoSizer>
        </div>
      )
    }
  }

  public componentDidUpdate(prevProps: DataProps) {
    this.scrollToTargetedSoundMarkerIfNecessary()
  }

  private scrollToTargetedSoundMarkerIfNecessary() {
    if (!this.listRef.current) {
      return
    }
    const scrollRowIndex = this.calcScrollRowIndex()
    if (scrollRowIndex === undefined) {
      return
    }
    this.listRef.current.scrollToRow(scrollRowIndex)
    this.props.scrollDone()
  }

  private calcScrollRowIndex() {
    if (!this.props.targetedSoundMarker) {
      return undefined
    }
    return findSoundMarkerIndexInListWithGaps(this.props.targetedSoundMarker, this.props.soundMarkers)
  }

  private rowRenderer = (props: ListRowProps) => {
    const soundMarker = this.props.soundMarkers[props.index]
    const formattedPosition = soundMarker.positionInSeconds === undefined
      ? 'Complete file'
      : formatDuration(Duration.ofSeconds(soundMarker.positionInSeconds))
    const secondaryCaption = formattedPosition + (soundMarker.author ? `, ${soundMarker.author}` : '')
    const activeSoundMarker = this.props.activeSoundMarker
    const soundIsAvailableOffline = this.props.offlineSoundAddressSet.has(soundMarker.publicSoundAddress)
    const isActive = activeSoundMarker !== undefined && soundMarker.index === activeSoundMarker.index
    const activeSoundMarkerIconClass =
      this.props.currentPlaylistType === this.props.playlistType ? 'pulse' : undefined
    const MarkerIconComponent = getMarkerIconComponent(soundMarker, soundMarker.listInfo.type)
    const classes = this.props.classes
    return (
      <div key={props.key} style={props.style}>
        <div style={{backgroundColor: this.determineMarkerBackgroundColor(soundMarker, isActive)}}>
          <ListItem button={true} divider={true}
                    ContainerComponent="div"
                    className={classes.listItem}
                    onClick={() => this.props.playMarker(soundMarker)}>
            <ListItemIcon>
              <RatingDecorator rating={soundMarker.rating}>
                <MarkerIconComponent className={isActive ? activeSoundMarkerIconClass : undefined}/>
              </RatingDecorator>
            </ListItemIcon>
            <ListItemText primary={soundMarker.name || '\u00A0'} secondary={secondaryCaption}
                          classes={{primary: classes.primaryListItemText}}/>
            <ListItemSecondaryAction>
              {soundIsAvailableOffline &&
              <IconButton>
                  <OfflinePinIcon/>
              </IconButton>
              }
              {this.props.deleteMarker &&
              <IconButton aria-label="Delete" onClick={() => this.props.deleteMarker!(soundMarker)}>
                  <DeleteIcon/>
              </IconButton>
              }
            </ListItemSecondaryAction>
          </ListItem>
        </div>
      </div>
    )
  }

  private determineMarkerBackgroundColor = (soundMarker: SoundMarker, isActive: boolean): string => {
    const palette = this.props.theme.palette
    return calcMarkerDistanceColor(soundMarker, palette.background.paper, this.props.currentPublicSoundAddress,
      this.props.currentPositionInSeconds, palette, this.props.maxMarkerColoringDistanceInSeconds, isActive)
  }
}

export const SoundMarkerList = withTheme(withStyles(styles)(RawSoundMarkerList))