import * as React from 'react'
import {createElement} from 'react'
import Chip from '@material-ui/core/Chip'
import Avatar from '@material-ui/core/Avatar'
import DoneIcon from '@material-ui/icons/Done'
import DoneAllIcon from '@material-ui/icons/DoneAll'
import {createStyles, Theme, withStyles, WithStyles, WithTheme, withTheme} from '@material-ui/core'
import 'github-markdown-css'
// @ts-ignore
import reactRenderer from 'remark-react'
// @ts-ignore
import toc from 'remark-toc'
import {calcMarkerDistanceColor, getMarkerIconComponent} from '../util/layout-util'
import {Link} from 'react-router-dom'
// @ts-ignore
import detab from 'detab'
// @ts-ignore
import u from 'unist-builder'
// @ts-ignore
import linkHandler from 'mdast-util-to-hast/lib/handlers/link'
// @ts-ignore
import headingHandler from 'mdast-util-to-hast/lib/handlers/heading'
// @ts-ignore
import paragraphHandler from 'mdast-util-to-hast/lib/handlers/paragraph'
// @ts-ignore
import listItemHandler from 'mdast-util-to-hast/lib/handlers/list-item'
import {sheetRemark} from '../util/markdown-util'
import {IndexedSoundMarker} from '../model/IndexedSoundMarker'
import {SoundMarkerListType} from '../model/SoundMarkerListType'
import {RatingDecorator} from './RatingDecorator'
import {extractSoundMarkerFromMdastNode} from '../util/sound-marker-markdown-util'
import {extractSheetIdFromPath} from '../store/Routes'
import {
  IndexedSoundMarkerWithListInfo,
  IndexedSoundMarkerWithSheetListInfo
} from '../model/IndexedSoundMarkerWithListInfo'
import {PlaylistType} from '../model/PlaylistType'
import * as log from 'loglevel'
// @ts-ignore
import Abcjs from 'react-abcjs'

export interface DataProps {
  sheetTitles: { [sheetId: string]: string | undefined }
  sheetNode: any
  sheetId?: string
  currentPublicSoundAddress?: string
  currentPositionInSeconds?: number
  maxMarkerColoringDistanceInSeconds: number
  filteredSoundMarkerIndexes: Set<number>
  offlineSoundAddressSet: Set<string>
  failedSoundAddressSet: Set<string>
  activeSoundMarker?: IndexedSoundMarkerWithSheetListInfo
  currentPlaylistType?: PlaylistType
  numSheetMarkersByPublicSoundAddress: { [publicSoundAddress: string]: number }
  scrollTop: number
  targetedSoundMarker?: IndexedSoundMarkerWithListInfo
}

export interface DispatchProps {
  playSoundMarker: (soundMarker: IndexedSoundMarkerWithSheetListInfo) => void
  focusedLineChanged: (lineNumber: number) => void
  scrollTopChanged: (scrollTop: number) => void
  scrollDone: () => void
}

const styles = (theme: Theme) => createStyles({
  avatar: {
    width: '24px',
    height: '24px',
  },
  chip: {
    height: 'auto',
  },
  chipDeleteIcon: {
    color: 'rgba(0, 0, 0, 0.4)'
  },
  root: {
    overflowY: 'auto',
  },
  chipLabel: {
    whiteSpace: 'normal'
  },
  marker: {
    opacity: 0.6,
    '&:hover': {
      opacity: 0.7,
    }
  },
  markerOffline: {
    opacity: 0.9,
    '&:hover': {
      opacity: 1.0,
    }
  },
  markerExcluded: {
    opacity: 0.2,
    '&:hover': {
      opacity: 0.3
    },
  },
  // .MuiChip-avatar competes with .MuiAvatar-colorDefault. In production builds the latter wins for some reason.
  // We set color to same value like .MuiChip-avatar.
  chipAvatarColorDefault: {
    color: '#616161'
  }
})

const transformingSheetRemark = sheetRemark()
  .use(toc, {
    heading: 'toc|table[ -]of[ -]contents?|Inhalt',
    maxDepth: 3
  })

type CustomProps = DataProps & DispatchProps & WithStyles<typeof styles> & WithTheme & { className?: string }

class RawSheetMarkdownRenderer extends React.Component<CustomProps> {
  private stringifyingSheetRemark: any
  private currentlyProcessedSoundMarkerIndex = -1
  private rootRef = React.createRef<HTMLDivElement>()

  constructor(props: CustomProps) {
    super(props)
    this.stringifyingSheetRemark = sheetRemark()
      .use(reactRenderer, {
        toHast: {
          handlers: {
            code: toHastCodeBlockHandler,
            link: withNodeAdded(linkHandler),
            heading: withNodeAdded(headingHandler),
            paragraph: withNodeAdded(paragraphHandler),
            listItem: withNodeAdded(listItemHandler),
          }
        },
        sanitize: false, // TODO https://github.com/syntax-tree/hast-util-sanitize => Extend GitHub rules
        remarkReactComponents: {
          a: this.a,
          abc: this.abc,
          h1: this.scrollingToSourceWhenClicked('h1'),
          h2: this.scrollingToSourceWhenClicked('h2'),
          h3: this.scrollingToSourceWhenClicked('h3'),
          h4: this.scrollingToSourceWhenClicked('h4'),
          h5: this.scrollingToSourceWhenClicked('h5'),
          p: this.scrollingToSourceWhenClicked('p'),
          li: this.scrollingToSourceWhenClicked('li'),
        }
      })
  }

  public componentDidMount() {
    if (this.rootRef.current) {
      this.rootRef.current.scrollTop = this.props.scrollTop
    }
  }

  public componentDidUpdate(prevProps: DataProps) {
    this.scrollToTargetedSoundMarkerIfNecessary()
  }

  public componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
    console.log(error)
  }

  private scrollToTargetedSoundMarkerIfNecessary() {
    const sm = this.props.targetedSoundMarker
    if (!sm) {
      return
    }
    if (sm.listInfo.type !== SoundMarkerListType.Sheet) {
      return
    }
    if (sm.listInfo.sheetId !== this.props.sheetId) {
      return
    }
    const soundMarkerElementId = getSoundMarkerElementId(sm.listInfo.index)
    const soundMarkerElement = document.getElementById(soundMarkerElementId)
    if (!soundMarkerElement) {
      return
    }
    if (!this.rootRef.current) {
      return
    }
    this.rootRef.current.scrollTo({
      top: soundMarkerElement.offsetTop,
      behavior: 'smooth'
    })
    this.props.scrollDone()
  }

  public render() {
    this.currentlyProcessedSoundMarkerIndex = 0
    log.debug('# Transforming remark...')
    const transformedSheetNode = transformingSheetRemark.runSync(this.props.sheetNode)
    log.debug('Transforming remark finished')
    log.debug('# Stringifying remark')
    const stringifiedSheet = this.stringifyingSheetRemark.stringify(transformedSheetNode)
    log.debug('Stringifying remark finished')
    return (
      <div id="sheet" onScroll={this.handleScroll} ref={this.rootRef}
           className={[this.props.classes.root, 'markdown-body', this.props.className].filter(it => it).join(' ')}
      >
        {stringifiedSheet}
      </div>
    )
  }

  private handleScroll = (event: React.UIEvent<HTMLDivElement>) => {
    this.props.scrollTopChanged(event.currentTarget.scrollTop)
  }

  private abc = (props: any) => {
    return (
    <Abcjs
      abcNotation={props.value}
      parserParams={{}}
      engraverParams={{ responsive: 'resize' }}
      renderParams={{ viewportHorizontal: false }}
    />
    )
  }

  private scrollingToSourceWhenClicked(name: string) {
    return (props: any) => {
      return createElement(name, this.enrichPropsWithSourceScrolling(props))
    }
  }

  private enrichPropsWithSourceScrolling(props: any) {
    if (!props.node.position || !props.node.position.start.line) {
      return
    }
    const lineNumber = props.node.position.start.line
    const newProps = {
      ...props
    }
    newProps.onClick = () => this.props.focusedLineChanged(lineNumber)
    delete newProps.node
    return newProps
  }

  private a = (props: any) => {
    const {node, ...aProps} = props
    const soundMarkerWithoutIndex = extractSoundMarkerFromMdastNode(node)
    if (!soundMarkerWithoutIndex) {
      // No sound marker link
      const sheetId = extractSheetIdFromPath(props.href)
      if (sheetId) {
        // Link to other sheet
        return (
          <Link to={props.href}>
            {props.children || this.props.sheetTitles[sheetId] || 'Unnamed sheet'}
          </Link>
        )
      } else if (props.href.trim().startsWith('/')) {
        return (
          <Link to={props.href}>
            {props.children}
          </Link>
        )
      } else {
        return (
          // eslint-disable-next-line jsx-a11y/anchor-has-content
          <a {...aProps}/>
        )
      }
    }
    const soundMarker: IndexedSoundMarkerWithSheetListInfo = {
      ...soundMarkerWithoutIndex,
      index: this.currentlyProcessedSoundMarkerIndex,
      listInfo: {
        type: SoundMarkerListType.Sheet,
        sheetId: this.props.sheetId!,
        index: this.currentlyProcessedSoundMarkerIndex
      }
    }
    this.currentlyProcessedSoundMarkerIndex += 1
    const activeSoundMarker = this.props.activeSoundMarker
    const isActive = activeSoundMarker !== undefined && soundMarker.index === activeSoundMarker.index
    const passesFilter = this.props.filteredSoundMarkerIndexes.has(soundMarker.index)
    const availableOffline = this.props.offlineSoundAddressSet.has(soundMarker.publicSoundAddress)
    const chipBackgroundColor = this.determineMarkerBackgroundColor(soundMarker, isActive, passesFilter)
    const classes = this.props.classes
    const secondaryAction = this.determineSecondaryAction(soundMarker, availableOffline, passesFilter)
    return (
      <span
        id={getSoundMarkerElementId(soundMarker.index)}
        className={[
          classes.marker,
          availableOffline && passesFilter && classes.markerOffline,
          !passesFilter && classes.markerExcluded
        ].filter(it => it).join(' ')}
      >
        <RatingDecorator rating={soundMarker.rating}>
          <Chip
            component='span'
            className={this.props.classes.chip}
            style={{
              backgroundColor: chipBackgroundColor,
            }}
            avatar={
              <Avatar
                component='span'
                className={this.props.classes.avatar}
                style={{backgroundColor: chipBackgroundColor}}
                classes={{colorDefault: classes.chipAvatarColorDefault}}
              >
                {this.createSoundMarkerLinkIcon(soundMarker, isActive, passesFilter)}
              </Avatar>
            }
            label={props.children ? props.children[0] : ''}
            onClick={(evt: React.MouseEvent<any>) => this.soundMarkerLinkClicked(evt, soundMarker)}
            onDelete={secondaryAction.op}
            deleteIcon={secondaryAction.icon}
            classes={{
              deleteIcon: classes.chipDeleteIcon,
              label: classes.chipLabel
            }}
          />
        </RatingDecorator>
      </span>
    )
  }

  private noop = (evt: React.MouseEvent<any>) => {
    evt.preventDefault()
  }

  private createSoundMarkerLinkIcon(soundMarker: IndexedSoundMarker, isActive: boolean, passesFilter: boolean) {
    const activeSoundMarkerIconClass =
      isActive && this.props.currentPlaylistType === PlaylistType.Sheet ? 'pulse' : undefined
    const MarkerIconComponent = getMarkerIconComponent(soundMarker, SoundMarkerListType.Sheet)
    return <MarkerIconComponent className={activeSoundMarkerIconClass}/>
  }

  private soundMarkerLinkClicked = (evt: React.MouseEvent<any>, soundMarker: IndexedSoundMarkerWithSheetListInfo) => {
    evt.preventDefault()
    evt.stopPropagation()
    this.props.playSoundMarker(soundMarker)
  }

  private determineMarkerBackgroundColor(soundMarker: IndexedSoundMarker, isActive: boolean, passesFilter: boolean): string {
    const palette = this.props.theme.palette
    const isFailedSound = this.props.failedSoundAddressSet.has(soundMarker.publicSoundAddress)
    const baseColor = isFailedSound ? palette.error.light : palette.grey['200']
    return calcMarkerDistanceColor(soundMarker, baseColor, this.props.currentPublicSoundAddress,
      this.props.currentPositionInSeconds, palette, this.props.maxMarkerColoringDistanceInSeconds,
      isActive)
  }

  private determineSecondaryAction(soundMarker: IndexedSoundMarker, availableOffline: boolean, passesFilter: boolean) {
    if (soundMarker.positionInSeconds === undefined) {
      // File marker
      const numPositionMarkersForFile =
        this.props.numSheetMarkersByPublicSoundAddress[soundMarker.publicSoundAddress] || 0
      if (numPositionMarkersForFile === 0) {
        return {
          icon: undefined,
          op: undefined
        }
      }
      if (numPositionMarkersForFile === 1) {
        return {
          icon: <DoneIcon/>,
          op: this.noop
        }
      } else {
        return {
          icon: <DoneAllIcon/>,
          op: this.noop
        }
      }
    } else {
      // Position marker
      return {
        icon: undefined,
        op: undefined
      }
    }
  }
}

// Customization of: https://github.com/syntax-tree/mdast-util-to-hast/blob/master/lib/handlers/code.js
/* Transform a code block. */
function toHastCodeBlockHandler(h: any, node: any) {
  const value = node.value ? detab(node.value + '\n') : ''
  const lang = node.lang && node.lang.match(/^[^ \t]+(?=[ \t]|$)/)
  const props: any = {}

  if (lang) {
    if (lang[0] === 'abc') {
      return h(node, 'abc', {value})
    }
    props.className = ['language-' + lang]
  }

  return h(node.position, 'pre', [h(node, 'code', props, [u('text', value)])])
}

function withNodeAdded(original: (h: any, node: any, parent: any) => any) {
  return (h: any, node: any, parent: any) => {
    const el = original(h, node, parent)
    el.properties.node = node
    return el
  }
}

function getSoundMarkerElementId(soundMarkerIndex: number) {
  return `marker-${soundMarkerIndex}`
}

export const SheetMarkdownRenderer = withTheme(withStyles(styles)(RawSheetMarkdownRenderer))