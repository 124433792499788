import {ApplicationState} from '../store/ApplicationState'
import {connect} from 'react-redux'
import {Dispatch} from 'redux'
import {playSoundMarker} from '../store/player/actions'
import {soundMarkerDeleted} from '../store/marker/actions'
import {DataProps, DispatchProps, SoundMarkerList} from '../components/SoundMarkerList'
import {
  coarseSoundPositionInSecondsSelector,
  currentPlaylistTypeSelector,
  filteredDeviceSoundMarkersSelector, offlineSoundAddressSetSelector,
  publicSoundAddressSelector,
  qualifiedActiveDeviceSoundMarkerSelector
} from '../store/player/selectors'
import {targetedQuickSoundMarkerSelector} from '../store/layout/selectors'
import {scrollingToTargetedSoundMarkerDone} from '../store/layout/actions'
import {PlaylistType} from '../model/PlaylistType'

function mapStateToProps(state: ApplicationState): DataProps {
  return {
    playlistType: PlaylistType.Device,
    soundMarkers: filteredDeviceSoundMarkersSelector(state),
    currentPublicSoundAddress: publicSoundAddressSelector(state),
    currentPositionInSeconds: state.layout.screenIsVisible && !state.settings.energySavingMode
      ? coarseSoundPositionInSecondsSelector(state)
      : undefined,
    maxMarkerColoringDistanceInSeconds: state.settings.maxMarkerColoringDistanceInSeconds,
    activeSoundMarker: qualifiedActiveDeviceSoundMarkerSelector(state),
    offlineSoundAddressSet: offlineSoundAddressSetSelector(state),
    currentPlaylistType: currentPlaylistTypeSelector(state),
    targetedSoundMarker: targetedQuickSoundMarkerSelector(state),
  }
}

function mapDispatchToProps(dispatch: Dispatch): DispatchProps {
  return {
    playMarker: soundMarker => dispatch(playSoundMarker({soundMarker, playlistType: PlaylistType.Device})),
    deleteMarker: soundMarker => dispatch(soundMarkerDeleted({soundMarker})),
    scrollDone: () => dispatch(scrollingToTargetedSoundMarkerDone()),
  }
}

export const SoundMarkerListContainer = connect(mapStateToProps, mapDispatchToProps)(SoundMarkerList)