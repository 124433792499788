import {Chip, createStyles, Theme, WithStyles, withStyles} from '@material-ui/core'
import * as React from 'react'
import {ChipProps} from '@material-ui/core/Chip'

const styles = (theme: Theme) => createStyles({
  breadcrumbChip: {
    height: 24,
    fontWeight: theme.typography.fontWeightRegular,
    overflow: 'hidden',
    justifyContent: 'flex-start',
  },
  breadcrumbChipLabel: {
    display: 'block',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  },
  removePadding: {
    paddingLeft: 0
  }
})

function CustomBreadcrumb(props: ChipProps & WithStyles<typeof styles>) {
  const {classes, label, ...rest} = props
  return <Chip className={classes.breadcrumbChip} {...rest}
               classes={{
                 label: [classes.breadcrumbChipLabel, label ? undefined : classes.removePadding].filter(it => it).join(' ')
               }}
               label={label}/>
}

export const StyledBreadcrumb = withStyles(styles)(CustomBreadcrumb)