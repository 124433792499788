import * as React from 'react';
import {LogoProps} from './LogoProps'

export function LoudspeakerLogo(props: LogoProps) {
  const {theme, ...remainingProps} = props
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="40 50 45 30" {...remainingProps}>
      <g
        id="layer1">
        <g
          transform="matrix(0,-0.07058442,0.06850056,0,49.796647,60.227307)"
          id="g3810-5-6-2-4"
          style={{display: 'inline'}}>
          <g
            transform="translate(-79.922844,10.294272)"
            style={{fill: '#3f51b5', fillOpacity: 1}}
            id="g3749-2-1-3-9">
            <path
              style={{fill: 'transparent', 'fillOpacity': 1, strokeWidth: 1.2131536}}
              d="M 13.085616,495.05162 C 118.36308,495.27242 274.30912,217.34136 311.97754,101.4209 333.20166,36.096216 305.23968,-35.417974 244.69483,-67.854062 189.51939,-97.416193 100.61222,-115.54556 13.085616,-115.54556 c -87.053478,0 -175.170886,17.929197 -230.283246,47.195319 -61.18782,32.500384 -89.33905,104.867421 -67.88322,170.739231 37.90013,116.3208 192.888995,392.67234 298.166466,392.66263"
              id="path3737-7-6-3-4"/>
          </g>
          <g
            id="g3751-8-4-2-5"/>
          <g
            id="g3753-1-3-4-1"/>
          <g
            id="g3755-37-3-4-9"/>
          <g
            id="g3757-0-4-6-6"/>
          <g
            id="g3759-3-2-2-6"/>
          <g
            id="g3761-9-6-4-9"/>
          <g
            id="g3763-2-4-1-3"/>
          <g
            id="g3765-9-3-8-8"/>
          <g
            id="g3767-2-8-0-5"/>
          <g
            id="g3769-9-2-8-3"/>
          <g
            id="g3771-3-6-5-2"/>
          <g
            id="g3773-5-8-1-2"/>
          <g
            id="g3775-1-8-9-3"/>
          <g
            id="g3777-4-3-2-4"/>
          <g
            id="g3779-76-6-0-9"/>
        </g>
        <g
          transform="matrix(0.26458333,0,0,0.26458333,-228.43714,-3.3316635)"
          id="g11739">
          <path
            transform="matrix(1.3185963,0,0,1.3185963,1038.1287,196.60726)"
            style={{
              fill: theme.palette.secondary.main,
              fillOpacity: 1,
              stroke: '#ffffff',
              strokeWidth: 5.5999999,
              strokeMiterlimit: 4,
              strokeDasharray: 'none',
            }}
            d="m 46.547,75.521 c 0,1.639 -0.947,3.128 -2.429,3.823 -0.573,0.271 -1.187,0.402 -1.797,0.402 -0.966,0 -1.923,-0.332 -2.696,-0.973 L 16.527,59.633 H 4.225 C 1.892,59.635 0,57.742 0,55.409 V 38.576 C 0,36.242 1.892,34.35 4.225,34.35 H 16.528 L 39.626,15.21 c 1.262,-1.046 3.012,-1.269 4.493,-0.569 1.481,0.695 2.429,2.185 2.429,3.823 z"
            id="path6336"/>
          <path
            id="path6336-5"
            d="m 1116.9929,283.02973 c -0.103,0.007 -0.202,0.011 -0.304,0.011 -1.116,0 -2.192,-0.441 -2.987,-1.237 l -0.565,-0.567 c -1.482,-1.479 -1.656,-3.822 -0.408,-5.504 3.164,-4.266 4.834,-9.323 4.834,-14.628 0,-5.706 -1.896,-11.058 -5.484,-15.478 -1.366,-1.68 -1.24,-4.12 0.291,-5.65 l 0.564,-0.565 c 0.844,-0.844 1.975,-1.304 3.199,-1.231 1.192,0.06 2.305,0.621 3.061,1.545 4.977,6.09 7.606,13.484 7.606,21.38 0,7.354 -2.325,14.354 -6.725,20.24 -0.735,0.981 -1.859,1.597 -3.082,1.684 z m 17.468,13.057 c -0.764,0.903 -1.869,1.445 -3.052,1.495 -0.058,0.002 -0.117,0.004 -0.177,0.004 -1.119,0 -2.193,-0.442 -2.988,-1.237 l -0.555,-0.555 c -1.551,-1.55 -1.656,-4.029 -0.246,-5.707 6.814,-8.104 10.568,-18.396 10.568,-28.982 0,-11.011 -4.019,-21.611 -11.314,-29.847 -1.479,-1.672 -1.404,-4.203 0.17,-5.783 l 0.554,-0.555 c 0.822,-0.826 1.89,-1.281 3.115,-1.242 1.163,0.033 2.263,0.547 3.036,1.417 8.818,9.928 13.675,22.718 13.675,36.01 0,12.789 -4.539,25.213 -12.786,34.982 z"
            style={{
              fill: '#ffffff',
              fillOpacity: 1,
              stroke: 'none',
              strokeWidth: 5.5999999,
              strokeMiterlimit: 4,
              strokeDasharray: 'none',
            }}/>
        </g>
      </g>
    </svg>
  )
}

