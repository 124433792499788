import {ApplicationState} from '../ApplicationState'
import {Duration} from 'js-joda'
import {createSelector} from 'reselect'
import {pathSelector, queryStringSelector} from '../router/selectors'
import {
  generateSoundMarkerUrl,
  parseSoundMarkerUrlPath,
  parseSoundMarkerUrlQueryString
} from '../../util/sound-marker-uri-util'
import {
  allSheetSoundMarkersSelector,
  currentlyVisibleOrAllSheetSoundMarkersSelector,
  currentlyVisibleSheetIdSelector,
  currentlyVisibleSheetSoundMarkersSelector,
  qualifySheetSoundMarkers,
  sheetInfosSelector
} from '../sheet/selectors'
import {PositionalSoundMarker, SoundMarker} from '../../model/SoundMarker'
import {deviceSoundMarkersSelector} from '../marker/selectors'
import {SoundMarkerListType} from '../../model/SoundMarkerListType'
import {IndexedSoundMarker} from '../../model/IndexedSoundMarker'
import {isOnlineSelector, topViewSelector} from '../layout/selectors'
import {IndexedSoundMarkerWithListInfo} from '../../model/IndexedSoundMarkerWithListInfo'
import {SoundAddressAndPosition} from '../../model/SoundAddressAndPosition'
import {
  filterEnabledSelector,
  filterSelector,
  markerToggleLookBehindTimeInSecondsSelector,
  markerToggleSluggishnessInSecondsSelector,
} from '../settings/selectors'
import {generateSoundFileName} from '../../util/sound-util'
import {
  createDefaultSoundMarker,
  findSoundMarkerIndexInGaplessList,
  findSoundMarkerIndexInListWithGaps,
  soundMarkerPassesFilter
} from '../../util/sound-marker-util'
import {uniq} from 'lodash/fp'
import {TopView} from '../../model/TopView'
import {DeviceMarkerListInfo} from '../../model/ListInfo'
import {SheetInfo} from '../../model/SheetInfo'
import {PlaylistType} from '../../model/PlaylistType'

export const publicSoundAddressSelector = (state: ApplicationState) => state.player.publicSoundAddress
export const soundLengthInSecondsSelector = (state: ApplicationState) => state.player.soundLengthInSeconds
export const soundMetadataSelector = (state: ApplicationState) => state.player.soundMetadata
export const activeSheetSoundMarkerSelector = (state: ApplicationState) => state.player.activeSheetSoundMarker
export const activeDeviceSoundMarkerSelector = (state: ApplicationState) => state.player.activeDeviceSoundMarker
export const currentPlaylistTypeSelector = (state: ApplicationState) => state.player.currentPlaylistType
export const soundPositionInSecondsSelector = (state: ApplicationState) => state.player.soundPositionInSeconds
export const offlineSoundAddressesSelector = (state: ApplicationState) => state.player.offlineSoundAddresses
export const failedSoundAddressesSelector = (state: ApplicationState) => state.player.failedSoundAddresses
export const offlineSoundQueueSelector = (state: ApplicationState) => state.player.offlineSoundQueue
export const isDownloadingSoundsSelector = (state: ApplicationState) => state.player.isDownloadingSounds

export const nextSoundInOfflineQueueSelector = createSelector(
  offlineSoundQueueSelector,
  offlineSoundQueue => {
    if (offlineSoundQueue.length === 0) {
      return undefined
    }
    return offlineSoundQueue[offlineSoundQueue.length - 1]
  }
)

export const offlineSoundAddressSetSelector = createSelector(
  offlineSoundAddressesSelector,
  offlineSoundAddresses => {
    return new Set(offlineSoundAddresses)
  }
)

export const failedSoundAddressSetSelector = createSelector(
  failedSoundAddressesSelector,
  failedSoundAddresses => {
    return new Set(failedSoundAddresses)
  }
)

export function createFilteredSheetSoundMarkersSelector(
  selectSheetSoundMarkers: (state: ApplicationState) => IndexedSoundMarkerWithListInfo[]) {
  return createSelector(
    [selectSheetSoundMarkers, filterEnabledSelector, filterSelector, offlineSoundAddressSetSelector],
    (sheetSoundMarkers, filterEnabled, filter, offlineSoundAddressSet) => {
      if (!filterEnabled) {
        return sheetSoundMarkers
      }
      return sheetSoundMarkers
        .filter(m => !filterEnabled || soundMarkerPassesFilter(m, filter, offlineSoundAddressSet))
    }
  )
}

export const filteredAllSheetSoundMarkersSelector = createFilteredSheetSoundMarkersSelector(allSheetSoundMarkersSelector)

export const filteredCurrentlyVisibleSheetSoundMarkersSelector = createFilteredSheetSoundMarkersSelector(currentlyVisibleSheetSoundMarkersSelector)

export const filteredCurrentlyVisibleOrAllSheetSoundMarkersSelector = createFilteredSheetSoundMarkersSelector(currentlyVisibleOrAllSheetSoundMarkersSelector)


export const filteredCurrentSheetSoundMarkerIndexesSelector = createSelector(
  filteredCurrentlyVisibleSheetSoundMarkersSelector,
  sheetSoundMarkers => {
    return new Set(sheetSoundMarkers.map(m => m.index))
  }
)


export const qualifiedActiveDeviceSoundMarkerSelector = createSelector(
  activeDeviceSoundMarkerSelector,
  sm => {
    if (!sm) {
      return undefined
    }
    return qualifyDeviceSoundMarker(sm)
  }
)

export const coarseSoundPositionInSecondsSelector = createSelector(
  [soundPositionInSecondsSelector],
  soundPositionInSeconds => Math.round(soundPositionInSeconds / 10) * 10
)

export const currentlyPlayingSheetIdSelector = createSelector(
  [activeSheetSoundMarkerSelector, currentlyVisibleSheetIdSelector],
  (activeSheetSoundMarker, currentlyVisibleSheetId) => {
    if (!activeSheetSoundMarker) {
      return currentlyVisibleSheetId
    }
    return activeSheetSoundMarker.listInfo.sheetId
  }
)

export const currentlyPlayingSheetInfoSelector = createSelector(
  [currentlyPlayingSheetIdSelector, sheetInfosSelector],
  (currentlyPlayingSheetId, sheetInfos): SheetInfo | undefined => {
    if (!currentlyPlayingSheetId) {
      return undefined
    }
    return sheetInfos[currentlyPlayingSheetId]
  }
)

export const currentlyPlayingSheetSoundMarkersSelector = createSelector(
  [currentlyPlayingSheetInfoSelector, currentlyVisibleSheetSoundMarkersSelector],
  (sheetInfo, currentlyVisibleSheetSoundMarkers): IndexedSoundMarkerWithListInfo[] => {
    if (!sheetInfo) {
      return currentlyVisibleSheetSoundMarkers
    }
    return qualifySheetSoundMarkers(sheetInfo.id, sheetInfo.soundMarkers)
  }
)

export const currentlyPlayingOrAllSheetSoundMarkersSelector = createSelector(
  [currentPlaylistTypeSelector, currentlyPlayingSheetSoundMarkersSelector, allSheetSoundMarkersSelector],
  (currentPlaylistType, currentSheetSoundMarkers, allSheetSoundMarkers): IndexedSoundMarkerWithListInfo[] => {
    return currentPlaylistType === PlaylistType.AllSheets ? allSheetSoundMarkers : currentSheetSoundMarkers
  }
)

export const filteredCurrentlyPlayingOrAllSheetSoundMarkersSelector =
  createFilteredSheetSoundMarkersSelector(currentlyPlayingOrAllSheetSoundMarkersSelector)

export const filteredDeviceSoundMarkersSelector = createSelector(
  [deviceSoundMarkersSelector, filterEnabledSelector, filterSelector, offlineSoundAddressSetSelector, isOnlineSelector],
  (deviceSoundMarkers, filterEnabled, filter, offlineSoundAddressSet, isOnline): IndexedSoundMarkerWithListInfo[] => {
    if (!filterEnabled) {
      return qualifyDeviceSoundMarkers(deviceSoundMarkers)
    }
    return qualifyDeviceSoundMarkers(
      deviceSoundMarkers.filter(m => soundMarkerPassesFilter(m, filter, offlineSoundAddressSet))
    )
  }
)

export function qualifyDeviceSoundMarkers(soundMarkers: IndexedSoundMarker[]): IndexedSoundMarkerWithListInfo[] {
  return soundMarkers.map(qualifyDeviceSoundMarker)
}

function qualifyDeviceSoundMarker(sm: IndexedSoundMarker): IndexedSoundMarkerWithListInfo {
  const listInfo: DeviceMarkerListInfo = {
    type: SoundMarkerListType.Device,
    index: sm.index,
  }
  return {
    ...sm,
    listInfo
  }
}

export const filteredCurrentlyVisibleSoundMarkersSelector = createSelector(
  [topViewSelector, filteredDeviceSoundMarkersSelector, filteredCurrentlyVisibleOrAllSheetSoundMarkersSelector],
  (topView, filteredDeviceSoundMarkers, filteredSheetSoundMarkers) => {
    return topView === TopView.QuickMarkers ? filteredDeviceSoundMarkers : filteredSheetSoundMarkers
  }
)


export const activeSoundMarkerSelector = createSelector(
  [currentPlaylistTypeSelector, activeSheetSoundMarkerSelector, qualifiedActiveDeviceSoundMarkerSelector],
  (currentPlaylistType, activeSheetSoundMarker, activeDeviceSoundMarker) => {
    if (currentPlaylistType === undefined ||
      ((currentPlaylistType === PlaylistType.Sheet || currentPlaylistType === PlaylistType.AllSheets) && !activeSheetSoundMarker) ||
      (currentPlaylistType === PlaylistType.Device && !activeDeviceSoundMarker)) {
      return undefined
    }
    return currentPlaylistType === PlaylistType.Device ? activeDeviceSoundMarker : activeSheetSoundMarker
  }
)


export const filteredCurrentlyVisibleSoundMarkersPriorityOrderedSelector = createSelector(
  [filteredCurrentlyVisibleSoundMarkersSelector, activeSoundMarkerSelector],
  (sheetSoundMarkers, activeSheetSoundMarker) => {
    if (!activeSheetSoundMarker) {
      return sheetSoundMarkers
    }
    const tmpMarkers = [...sheetSoundMarkers]
    const frontMarkers = tmpMarkers.splice(activeSheetSoundMarker.index)
    return [
      ...frontMarkers,
      ...tmpMarkers
    ]
  }
)


export const filteredCurrentlyVisibleSoundAddressesSelector = createSelector(
  filteredCurrentlyVisibleSoundMarkersPriorityOrderedSelector,
  soundMarkers => {
    return uniq(soundMarkers.map(m => m.publicSoundAddress))
  }
)

export const filteredCurrentlyVisibleOnlineSoundAddressesSelector = createSelector(
  [filteredCurrentlyVisibleSoundAddressesSelector, offlineSoundAddressSetSelector],
  (discoveredSoundAddresses, offlineSoundAddressSet) => {
    return discoveredSoundAddresses.filter(addr => !offlineSoundAddressSet.has(addr))
  }
)

export const filteredCurrentlyVisibleOfflineSoundAddressesSelector = createSelector(
  [filteredCurrentlyVisibleSoundAddressesSelector, offlineSoundAddressSetSelector],
  (soundAddresses, offlineSoundAddressSet) => {
    return soundAddresses.filter(addr => offlineSoundAddressSet.has(addr))
  }
)

export const filteredCurrentlyVisibleSoundAddressSetSelector = createSelector(
  filteredCurrentlyVisibleSoundMarkersSelector,
  soundMarkers => {
    return new Set(soundMarkers.map(m => m.publicSoundAddress))
  }
)

export const currentPlaylistSelector = createSelector(
  [currentPlaylistTypeSelector, filteredCurrentlyPlayingOrAllSheetSoundMarkersSelector, filteredDeviceSoundMarkersSelector],
  (currentPlaylistType, sheetSoundMarkers, deviceSoundMarkers) => {
    if (currentPlaylistType === undefined) {
      return undefined
    }
    return currentPlaylistType === PlaylistType.Device ? deviceSoundMarkers : sheetSoundMarkers
  }
)

export const currentPlaylistIndexSelector = createSelector(
  [currentPlaylistSelector, activeSoundMarkerSelector],
  (currentPlaylist, activeSoundMarker) => {
    if (!currentPlaylist || !activeSoundMarker) {
      return undefined
    }
    return findSoundMarkerIndexInListWithGaps(activeSoundMarker, currentPlaylist)
  }
)

export const currentPlaylistSizeSelector = createSelector(
  currentPlaylistSelector,
  currentPlaylist => currentPlaylist ? currentPlaylist.length : undefined
)

export const activeSoundMarkerInCurrentSoundSelector = createSelector(
  [publicSoundAddressSelector, activeSoundMarkerSelector],
  (publicSoundAddress, activeSoundMarker): IndexedSoundMarkerWithListInfo | undefined => {
    if (!publicSoundAddress || !activeSoundMarker) {
      return undefined
    }
    if (activeSoundMarker.publicSoundAddress !== publicSoundAddress) {
      return undefined
    }
    if (activeSoundMarker.positionInSeconds === undefined) {
      return undefined
    }
    return activeSoundMarker
  }
)

export const activeSheetSoundMarkerIndexSelector =
  createActiveSoundMarkerIndexSelector(activeSheetSoundMarkerSelector, currentlyPlayingOrAllSheetSoundMarkersSelector)

export const activeDeviceSoundMarkerIndexSelector =
  createActiveSoundMarkerIndexSelector(qualifiedActiveDeviceSoundMarkerSelector, deviceSoundMarkersSelector)

function createActiveSoundMarkerIndexSelector(
  selectActiveSoundMarker: (state: ApplicationState) => IndexedSoundMarkerWithListInfo | undefined,
  selectSoundMarkers: (state: ApplicationState) => SoundMarker[]) {
  return createSelector(
    [selectActiveSoundMarker, selectSoundMarkers],
    (activeSoundMarker, soundMarkers) => {
      if (!activeSoundMarker) {
        return undefined
      }
      return findSoundMarkerIndexInGaplessList(activeSoundMarker, soundMarkers)
    }
  )
}

function createRelativeSoundMarkerSelector(
  direction: number,
  soundMarkersSelector: (state: ApplicationState) => IndexedSoundMarkerWithListInfo[],
  activeSoundMarkerIndexSelector: (state: ApplicationState) => number | undefined) {
  return createSelector(
    [soundMarkersSelector, activeSoundMarkerIndexSelector],
    (soundMarkers, activeSoundMarkerIndex): IndexedSoundMarkerWithListInfo | undefined => {
      if (soundMarkers.length === 0) {
        return undefined
      }
      if (activeSoundMarkerIndex === undefined) {
        // Choose first or last sound marker in list
        const firstOrLastIndex = direction > 0 ? 0 : soundMarkers.length - 1
        return soundMarkers[firstOrLastIndex]
      }
      // Choose previous or next sound marker in list
      return findFirstAvailableSoundMarker(soundMarkers, activeSoundMarkerIndex, direction)
    }
  )
}

function findFirstAvailableSoundMarker(
  soundMarkers: IndexedSoundMarkerWithListInfo[],
  currentIndex: number,
  direction: number
) {
  const startIndex = direction > 0 ? 0 : soundMarkers.length - 1
  for (let i = startIndex; i >= 0 && i < soundMarkers.length; i += direction) {
    const soundMarker = soundMarkers[i]
    if ((direction > 0 && soundMarker.index > currentIndex) || (direction < 0 && soundMarker.index < currentIndex)) {
      return soundMarker
    }
  }
  return undefined
}

export const previousSheetSoundMarkerSelector =
  createRelativeSoundMarkerSelector(-1, filteredCurrentlyPlayingOrAllSheetSoundMarkersSelector, activeSheetSoundMarkerIndexSelector)

export const nextSheetSoundMarkerSelector =
  createRelativeSoundMarkerSelector(+1, filteredCurrentlyPlayingOrAllSheetSoundMarkersSelector, activeSheetSoundMarkerIndexSelector)

export const previousDeviceSoundMarkerSelector =
  createRelativeSoundMarkerSelector(-1, filteredDeviceSoundMarkersSelector, activeDeviceSoundMarkerIndexSelector)

export const nextDeviceSoundMarkerSelector =
  createRelativeSoundMarkerSelector(+1, filteredDeviceSoundMarkersSelector, activeDeviceSoundMarkerIndexSelector)

export const nextSoundMarkerSelector = createSelector(
  [currentPlaylistTypeSelector, nextSheetSoundMarkerSelector, nextDeviceSoundMarkerSelector],
  (currentPlaylist, nextSheetSoundMarker, nextDeviceSoundMarker): IndexedSoundMarkerWithListInfo | undefined => {
    switch (currentPlaylist) {
      case PlaylistType.Sheet:
      case PlaylistType.AllSheets:
        return nextSheetSoundMarker ? {
          ...nextSheetSoundMarker,
        } : undefined
      case PlaylistType.Device:
        return nextDeviceSoundMarker ? {
          ...nextDeviceSoundMarker,
        } : undefined
      default:
        return undefined
    }
  }
)

export const nextSoundMarkerInCurrentSoundSelector = createSelector(
  [publicSoundAddressSelector, nextSoundMarkerSelector],
  (publicSoundAddress, nextSoundMarker) => {
    if (!publicSoundAddress || !nextSoundMarker) {
      return undefined
    }
    if (nextSoundMarker.publicSoundAddress !== publicSoundAddress) {
      return undefined
    }
    return nextSoundMarker
  }
)

export const downloadFileNameSelector = createSelector(
  [publicSoundAddressSelector, soundMetadataSelector],
  (publicSoundAddress, soundMetadata) => {
    if (!publicSoundAddress) {
      return undefined
    }
    return generateSoundFileName(publicSoundAddress, soundMetadata)
  }
)

export const soundLengthSelector = createSelector(
  soundLengthInSecondsSelector,
  soundLengthInSeconds => {
    return Duration.ofSeconds(soundLengthInSeconds)
  }
)

export const soundPositionSelector = createSelector(
  soundPositionInSecondsSelector,
  soundPositionInSeconds => {
    return Duration.ofSeconds(soundPositionInSeconds)
  }
)

export const currentSoundIsAvailableOfflineSelector = createSelector(
  [publicSoundAddressSelector, offlineSoundAddressSetSelector],
  (currentSoundPublicAddress, offlineSoundAddressSet) => {
    if (!currentSoundPublicAddress) {
      return false
    }
    return offlineSoundAddressSet.has(currentSoundPublicAddress)
  }
)

export const currentSoundAddressAndPositionSelector = createSelector(
  [publicSoundAddressSelector, soundPositionInSecondsSelector],
  (publicSoundAddress, soundPositionInSeconds) => {
    if (!publicSoundAddress) {
      return undefined
    }
    return {
      publicSoundAddress,
      positionInSeconds: soundPositionInSeconds
    } as SoundAddressAndPosition
  }
)

export const lookBehindPosInSecondsSelector = createSelector(
  [soundPositionInSecondsSelector, markerToggleLookBehindTimeInSecondsSelector],
  (posInSeconds, lookBehindTimeInSeconds) => {
    return Math.max(posInSeconds - lookBehindTimeInSeconds, 0)
  }
)

// TODO Difference to nearbyDeviceSoundMarkerSelector?
export const toggleableDeviceSoundMarkerSelector = createSelector(
  [publicSoundAddressSelector, lookBehindPosInSecondsSelector, deviceSoundMarkersSelector, markerToggleSluggishnessInSecondsSelector],
  (publicSoundAddress, lookBehindPosInSeconds, deviceSoundMarkers, sluggishnessInSeconds) => {
    if (!publicSoundAddress) {
      return undefined
    }
    const index = deviceSoundMarkers.findIndex(m =>
      m.positionInSeconds !== undefined && m.publicSoundAddress === publicSoundAddress &&
      Math.abs(m.positionInSeconds - lookBehindPosInSeconds) <= sluggishnessInSeconds
    )
    return index >= 0 ? deviceSoundMarkers[index] : undefined
  }
)

export const newSoundMarkerAtCurrentPositionSelector = createSelector(
  currentSoundAddressAndPositionSelector,
  ap => {
    if (!ap) {
      return undefined
    }
    return {
      ...createDefaultSoundMarker(ap.publicSoundAddress),
      positionInSeconds: ap.positionInSeconds
    }
  }
)

export const generatedSoundMarkerUrlSelector = createSelector(
  currentSoundAddressAndPositionSelector,
  currentSoundAddressAndPosition => {
    if (!currentSoundAddressAndPosition) {
      return undefined
    }
    return generateSoundMarkerUrl(currentSoundAddressAndPosition)
  }
)

export const streamingSoundUrlStringSelector = (state: ApplicationState) => state.player.streamingSoundUrl

export const streamingSoundUrlSelector = createSelector(
  streamingSoundUrlStringSelector,
  urlString => {
    return urlString ? new URL(urlString) : undefined
  }
)

export const soundMarkerFromCurrentUrlSelector = createSelector(
  [pathSelector, queryStringSelector],
  (path, queryString) => {
    return parseSoundMarkerUrlPath(path) || parseSoundMarkerUrlQueryString(queryString)
  }
)

export const closestDeviceSoundMarkerSelector = createSelector(
  [deviceSoundMarkersSelector, currentSoundAddressAndPositionSelector],
  (soundMarkers, currentSap) => {
    if (!currentSap) {
      return undefined
    }
    return soundMarkers.reduce((closestMarkerSoFar: SoundMarker | undefined, currentMarker) => {
      if (currentMarker.publicSoundAddress !== currentSap.publicSoundAddress) {
        return closestMarkerSoFar
      }
      if (!closestMarkerSoFar) {
        return currentMarker
      }
      // Device markers always have a position
      const lowestDistanceSoFar = Math.abs(closestMarkerSoFar.positionInSeconds! - currentSap.positionInSeconds)
      const currentDistance = Math.abs(currentMarker.positionInSeconds! - currentSap.positionInSeconds)
      return lowestDistanceSoFar < currentDistance ? closestMarkerSoFar : currentMarker
    }, undefined)
  }
)

export const nearbyDeviceSoundMarkerSelector = createSelector(
  [closestDeviceSoundMarkerSelector, soundPositionInSecondsSelector, markerToggleSluggishnessInSecondsSelector],
  (closestDeviceSoundMarker, soundPositionInSeconds, markerToggleSluggishnessInSeconds) => {
    if (!closestDeviceSoundMarker) {
      return undefined
    }
    if (closestDeviceSoundMarker.positionInSeconds === undefined) {
      return undefined
    }
    if (soundPositionInSeconds === undefined) {
      return undefined
    }
    const distanceToClosestMarker = Math.abs(closestDeviceSoundMarker.positionInSeconds - soundPositionInSeconds)
    if (distanceToClosestMarker > markerToggleSluggishnessInSeconds) {
      return undefined
    }
    return closestDeviceSoundMarker
  }
)

export const currentOrAllSheetSoundMarkersInCurrentSoundSelector =
  createSoundMarkersInCurrentSoundSelector(filteredCurrentlyVisibleOrAllSheetSoundMarkersSelector)

export const deviceSoundMarkersInCurrentSoundSelector =
  createSoundMarkersInCurrentSoundSelector(filteredDeviceSoundMarkersSelector)

function createSoundMarkersInCurrentSoundSelector(selectSoundMarkers: (state: ApplicationState) => SoundMarker[]) {
  return createSelector(
    [publicSoundAddressSelector, selectSoundMarkers],
    (publicSoundAddress, soundMarkers): PositionalSoundMarker[] => {
      if (!publicSoundAddress) {
        return []
      }
      return soundMarkers
        .filter(m => m.positionInSeconds !== undefined && m.publicSoundAddress === publicSoundAddress)
        .map(m => m as PositionalSoundMarker)
    }
  )
}

export const currentlyPlayingSheetTitleSelector = createSelector(
  currentlyPlayingSheetInfoSelector,
  sheetInfo => {
    if (!sheetInfo) {
      return undefined
    }
    return sheetInfo.title
  }
)