import * as actions from './actions'
import {ActionType, getType} from 'typesafe-actions'
import {Reducer} from 'redux'
import {MarkerState} from './MarkerState'
import {SoundMarker, soundMarkersEqual} from '../../model/SoundMarker'
import {IndexedSoundMarker} from '../../model/IndexedSoundMarker'
import {persistReducer} from "redux-persist"
import {mainStorage} from '../../util/main-storage'

type MarkerAction = ActionType<typeof actions>

const initialState: MarkerState = {
  soundMarkers: []
}

const rawMarkerReducer: Reducer<MarkerState, MarkerAction> = (state = initialState, action) => {
  switch (action.type) {
    case getType(actions.soundMarkerAdded): {
      const newMarker = {...action.payload.soundMarker}
      // Don't save stuff which is unnecessary in this context
      delete newMarker.index
      delete newMarker.listInfo
      return {
        ...state,
        soundMarkers: [
          ...state.soundMarkers,
          newMarker
        ]
      }
    }
    case getType(actions.soundMarkerDeleted): {
      const soundMarker = action.payload.soundMarker
      return {
        ...state,
        soundMarkers: withoutSoundMarker(state.soundMarkers, soundMarker)
      }
    }
    case getType(actions.soundMarkerUpdated): {
      const soundMarker = action.payload.soundMarker
      return {
        ...state,
        soundMarkers: [
          ...withoutSoundMarker(state.soundMarkers, soundMarker),
          soundMarker
        ]
      }
    }
    case getType(actions.allSoundMarkersDeleted):
      return {
        ...state,
        soundMarkers: []
      }
    default:
      return state
  }
}

function withoutSoundMarker(soundMarkers: SoundMarker[], soundMarker: IndexedSoundMarker) {
  return soundMarkers.filter((it, i) =>
    !(i === soundMarker.index && soundMarkersEqual(it, soundMarker))
  )
}

export const markerReducer = persistReducer(
  {key: 'marker', storage: mainStorage},
  rawMarkerReducer
)