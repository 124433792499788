import {tryParseUrl} from './url-util'

export function extractUrlsFromText(text: string): URL[] {
  return getLinesFromText(text)
    .map(line => tryParseUrl(line.trim()))
    .filter(url => url) as URL[]
}

function getLinesFromText(text: string) {
  return text.match(/[^\r\n]+/g) || []
}