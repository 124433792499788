import {ListInfo} from './ListInfo'

export interface SoundMarker {
  publicSoundAddress: string
  positionInSeconds?: number
  regionLengthInSeconds?: number
  name?: string
  rating?: number
  author?: string
  index?: number
  listInfo?: ListInfo
  creationTimestamp?: number
}

export interface PositionalSoundMarker extends SoundMarker {
  positionInSeconds: number
}

export function soundMarkersEqual(one: SoundMarker, two: SoundMarker) {
  return one.publicSoundAddress === two.publicSoundAddress &&
    one.positionInSeconds === two.positionInSeconds &&
    one.regionLengthInSeconds === two.regionLengthInSeconds
}