import {createStandardAction} from 'typesafe-actions'
import {UserInfo, UserProfile} from '../../model/UserProfile'
import {SnackbarMessage} from '../../model/SnackbarMessage'
import {TopView} from '../../model/TopView'
import {IndexedSoundMarkerWithListInfo} from '../../model/IndexedSoundMarkerWithListInfo'

export const handleError = createStandardAction('layout/handleError')<string>()
export const handleInfo = createStandardAction('layout/handleInfo')<string>()
export const handleSnackbarMessageChangeRequest = createStandardAction('layout/handleSnackbarMessageChangeRequest')<SnackbarMessage>()
export const snackbarMessageChanged = createStandardAction('layout/snackbarMessageChanged')<SnackbarMessage>()
export const clearSnackbarMessage = createStandardAction('layout/clearSnackbarMessage')<void>()
export const titleChanged = createStandardAction('layout/titleChanged')<{ title?: string }>()
export const onlineStateChanged = createStandardAction('layout/onlineStateChanged')<{ isOnline: boolean }>()
export const showSwipeableDrawer = createStandardAction('layout/showSwipeableDrawer')<boolean>()
export const togglePersistentDrawer = createStandardAction('layout/togglePersistentDrawer')<void>()
export const showFilterPanel = createStandardAction('layout/showFilterPanel')<boolean>()
export const installPromptAvailable = createStandardAction('layout/installPromptAvailable')<void>()
export const showInstallPrompt = createStandardAction('layout/showInstallPrompt')<void>()
export const setScreenIsVisible = createStandardAction('layout/setScreenIsVisible')<boolean>()
export const signedIn = createStandardAction('layout/signedIn')<{ userInfo: UserInfo }>()
export const signedOut = createStandardAction('layout/signedOut')<void>()
export const signIn = createStandardAction('layout/signIn')<{ userProfile: UserProfile }>()
export const signOut = createStandardAction('layout/signOut')<void>()
export const switchToNextLogo = createStandardAction('layout/switchToNextLogo')<void>()
export const enteredTopView = createStandardAction('layout/enteredTopView')<{ topView: TopView }>()
export const goToSoundMarker = createStandardAction('layout/goToSoundMarker')<{
  soundMarker: IndexedSoundMarkerWithListInfo
  // Goes to sheet if we are on all-sheets playlist
  goToSource: boolean
}>()
export const goToActiveSoundMarker = createStandardAction('layout/goToActiveSoundMarker')<void>()
export const soundMarkerTargeted = createStandardAction('layout/soundMarkerTargeted')<{
  soundMarker: IndexedSoundMarkerWithListInfo
  topView: TopView
}>()
export const scrollingToTargetedSoundMarkerDone = createStandardAction('layout/scrollingToTargetedSoundMarkerDone')<void>()
export const enableOrDisableUfoAlarm = createStandardAction('layout/enableOrDisableUfoAlarm')<boolean>()
export const initAudio = createStandardAction('layout/initAudio')()
export const setFullScreen = createStandardAction('layout/setFullScreen')<boolean>()