/* eslint import/no-webpack-loader-syntax: off */
import * as React from 'react'
import {createStyles, Theme, withStyles, WithStyles, WithTheme, withTheme, withWidth} from '@material-ui/core'
import {isWidthUp, WithWidth} from '@material-ui/core/withWidth'
import {SoundMarker} from '../model/SoundMarker'
import 'simplemde/dist/simplemde.min.css'
import {extractUrlsFromText} from '../util/url-list-util'
import {
  buildSoundMarkerSectionsFromUrlList,
  formatSoundMarkerAsMarkdownLink,
  formatSoundMarkerSectionAsMarkdown,
  formatSoundMarkerSectionsAsMarkdown,
  SoundMarkerSection
} from '../util/sound-marker-markdown-util'
import {createNewSheet} from '../services/SheetService'
import {createSheetViewPath} from '../store/Routes'
import SimpleMDE from 'simplemde'
import {Editor, EditorChange, Doc} from 'codemirror'
import {TextChange} from '../model/TextChange'


export interface DataProps {
  focusedLineNumber?: number
  text?: string
  sheetId?: string
  newSoundMarkerAtCurrentPosition?: SoundMarker
  soundMarkers: SoundMarker[]
}

export interface DispatchProps {
  contentChanged: (sheetId: string, content: string, change: TextChange) => void
  deleteAllSoundMarkers: () => void
}

const styles = (theme: Theme) => createStyles({})

// Must always be wrapped in another component, otherwise unmount will not work correctly
class RawSheetEditor extends React.Component<DataProps & DispatchProps & WithStyles<typeof styles> & WithWidth & WithTheme> {
  private textareaRef = React.createRef<HTMLTextAreaElement>()
  private codeMirror?: Editor = undefined

  public render() {
    return (
      <textarea ref={this.textareaRef}/>
    )
  }

  public componentDidMount() {
    const markupToolbarItems = [
      'bold', 'italic', 'strikethrough', 'heading', '|',
      'quote', 'unordered-list', 'ordered-list', '|',
      'link', 'image', 'table', '|',
      'guide'
    ]
    const jamToolbarItems = [
      {
        name: 'insert-sound-marker-to-current-position',
        action: this.insertSoundMarkerToCurrentPosition,
        className: 'fa fa-music',
        title: 'Insert sheet marker to current position'
      },
      {
        name: 'import-device-sound-markers',
        action: this.importDeviceSoundMarkers,
        className: 'fa fa-bars',
        title: 'Import list markers'
      },
      {
        name: 'import-sounds-from-clipboard',
        action: this.importSoundsFromClipboard,
        className: 'fa fa-clipboard',
        title: 'Insert file markers from clipboard (one address per line)'
      },
      {
        name: 'insert-link-to-new-sheet',
        action: this.insertLinkToNewSheet,
        className: 'fa fa-file',
        title: 'Insert link to new sheet'
      },
    ]
    const toolbarItems = isWidthUp('sm', this.props.width)
      ? [...markupToolbarItems, '|', ...jamToolbarItems]
      : jamToolbarItems
    const simplemde = new SimpleMDE({
      element: this.textareaRef.current!,
      indentWithTabs: false,
      spellChecker: false,
      hideIcons: ['preview', 'fullscreen', 'side-by-side'],
      toolbar: toolbarItems,
      promptURLs: false,
      status: false
    })
    this.codeMirror = simplemde.codemirror as Editor
    this.codeMirror.on('change', (instance, change) => {
      if (!this.props.sheetId || !this.codeMirror) {
        return
      }
      if (change.origin === 'setValue') {
        return
      }
      const textChange = convertToTextChange(this.codeMirror.getDoc(), change)
      this.props.contentChanged(this.props.sheetId, simplemde.value(), textChange);
    })
    this.codeMirror.setValue(this.props.text || '')
  }

  public componentDidUpdate(prevProps: DataProps) {
    if (this.props.sheetId !== prevProps.sheetId) {
      if (this.codeMirror) {
        this.codeMirror.setValue(this.props.text || '')
      }
    }
    if (this.props.focusedLineNumber && this.props.focusedLineNumber !== prevProps.focusedLineNumber) {
      this.scrollToLine(this.props.focusedLineNumber)
    }
  }

  private importSoundsFromClipboard = async () => {
    const nav = navigator as any
    if (!nav.clipboard) {
      return
    }
    const textInClipboard = await nav.clipboard.readText()
    const urls = extractUrlsFromText(textInClipboard)
    const soundMarkerSections = await buildSoundMarkerSectionsFromUrlList(urls)
    const textToBeInserted = formatSoundMarkerSectionsAsMarkdown(soundMarkerSections)
    this.insertTextAndPlaceCursorAt(textToBeInserted, 0)
  }

  private importDeviceSoundMarkers = () => {
    const section: SoundMarkerSection = {
      title: 'Markers imported from device',
      entries: this.props.soundMarkers.map(marker => {
        return {
          marker
        }
      })
    }
    const text = formatSoundMarkerSectionAsMarkdown(section)
    this.insertTextAndPlaceCursorAt(text, 0)
    setTimeout(() => {
      if (window.confirm('Delete all markers from device now?')) {
        this.props.deleteAllSoundMarkers()
      }
    }, 100)
  }

  private insertSoundMarkerToCurrentPosition = () => {
    // TODO Could be a performance problem to put frequently changing things in props
    if (!this.props.newSoundMarkerAtCurrentPosition) {
      return
    }
    const text = formatSoundMarkerAsMarkdownLink(this.props.newSoundMarkerAtCurrentPosition)
    this.insertTextAndPlaceCursorAt(text, 1)
  }

  private insertLinkToNewSheet = async () => {
    const newSheetId = await createNewSheet()
    if (!newSheetId) {
      return
    }
    const pathToNewSheet = createSheetViewPath(newSheetId)
    const text = `[](${pathToNewSheet})`
    this.insertTextAndPlaceCursorAt(text, 1)
  }

  private insertTextAndPlaceCursorAt(text: string, relativePosition: number) {
    if (!this.codeMirror) {
      return
    }
    const doc = this.codeMirror.getDoc()
    const cursor = doc.getCursor()
    doc.replaceRange(text, cursor)
    doc.setCursor({
      line: cursor.line,
      ch: cursor.ch + relativePosition
    })
    this.codeMirror.focus()
  }

  private scrollToLine(lineNumber: number) {
    if (!this.codeMirror) {
      return
    }
    const lineCh = {line: lineNumber - 1, ch: 0}
    const t = this.codeMirror.charCoords(lineCh, "local").top
    this.codeMirror.scrollTo(null, t - 5)
    const doc = this.codeMirror.getDoc()
    doc.setSelection(lineCh, {line: lineNumber, ch: 0})
  }
}

function convertToTextChange(doc: Doc, change: EditorChange): TextChange {
  const start = doc.indexFromPos(change.from)
  return {
    start,
    delLength: change.removed
      ? change.removed.map(s => s.length).reduce((a, b) => a + b) + change.removed.length - 1
      : 0,
    insText: change.text.join('\n')
  }
}


export const SheetEditor = withTheme(withWidth()(withStyles(styles)(RawSheetEditor)))