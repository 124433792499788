import * as React from 'react'
import {Component} from 'react'
import Slider, {Handle} from 'rc-slider'
import Tooltip from 'rc-tooltip'
import {createStyles, Theme, withStyles, WithStyles, WithTheme, withTheme} from '@material-ui/core'
import {Duration} from 'js-joda'
import 'rc-slider/assets/index.css'
import 'rc-tooltip/assets/bootstrap.css'
import {SoundMarkerListType} from '../model/SoundMarkerListType'
import ArrowLeftIcon from '@material-ui/icons/ChevronLeft'
import ArrowRightIcon from '@material-ui/icons/ChevronRight'
import {formatDuration, getMarkerIconComponent, getMarkerIconComponentByListType} from '../util/layout-util'
import {PositionalSoundMarker} from '../model/SoundMarker'
import {IndexedSoundMarkerWithListInfo} from '../model/IndexedSoundMarkerWithListInfo'
import {PlaylistType} from '../model/PlaylistType'

export interface DataProps {
  soundLoaded: boolean
  currentSoundPosition: Duration
  soundLength: Duration
  activeSoundMarker?: IndexedSoundMarkerWithListInfo
  currentPlaylistType?: PlaylistType
  loading: boolean
  soundMarkersInSheet: PositionalSoundMarker[]
  soundMarkersOnDevice: PositionalSoundMarker[]
}

export interface DispatchProps {
  seekToSoundPosition: (soundPosition: Duration) => void
}

interface State {
  sliderIsGrabbed: boolean
  sliderGrabPosition: number
}

const styles = (theme: Theme) => createStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
    userSelect: 'none'
  },
  disabled: {
    color: theme.palette.text.disabled
  },
  overlay: {
    fontFamily: 'sans-serif'
  },
  slider: {
    marginBottom: 0,
    padding: '0 0 20px 0',
    '& .rc-slider-mark': {
      top: '9px'
    },
    '& .rc-slider-handle': {
      '&:active': {
        boxShadow: `0 0 5px ${theme.palette.primary.main}`
      }
    }
  },
})

const SheetMarkerIcon = getMarkerIconComponentByListType(SoundMarkerListType.Sheet)
const inactiveSheetMarkerIcon = <SheetMarkerIcon fontSize="inherit"/>
const DeviceMarkerIcon = getMarkerIconComponentByListType(SoundMarkerListType.Device)
const inactiveDeviceMarkerIcon = <DeviceMarkerIcon fontSize="inherit"/>

class RawSeekSliderPanel extends Component<DataProps & DispatchProps & WithStyles<typeof styles> & WithTheme, State> {
  private alreadyHandlingSliderReleased = false

  public state: State = {
    sliderIsGrabbed: false,
    sliderGrabPosition: 0,
  }

  public render() {
    const theme = this.props.theme
    return (
      <Slider
        handle={this.handle}
        className={this.props.classes.slider}
        handleStyle={{
          borderColor: theme.palette.primary.light,
          visibility: this.sliderLengthIsCorrect ? 'visible' : 'hidden'
        }}
        dotStyle={{
          visibility: 'hidden'
        }}
        trackStyle={{
          backgroundColor: theme.palette.primary.light,
          visibility: this.sliderLengthIsCorrect ? 'visible' : 'hidden'
        }}
        onBeforeChange={this.handleSliderGrabbed}
        onChange={this.handleSliderChanged}
        onAfterChange={this.handleSliderReleased}
        min={0}
        max={this.props.soundLength.seconds()}
        marks={this.positionSliderMarks}
        value={this.state.sliderIsGrabbed ? this.state.sliderGrabPosition : this.currentSoundPositionInSeconds.seconds()}
      />
    )
  }

  private handle = (props: any) => {
    const {value, dragging, ...restProps} = props
    const formattedDuration = formatDuration(Duration.ofSeconds(value))
    return (
      <Tooltip
        overlay={formattedDuration}
        visible={dragging}
        placement="top"
        overlayClassName={this.props.classes.overlay}
      >
        <Handle value={value} {...restProps} />
      </Tooltip>
    );
  }


  private get sliderLengthIsCorrect() {
    return this.props.soundLoaded && !this.props.loading
  }

  private get currentSoundPositionInSeconds() {
    return Duration.ofSeconds(this.props.currentSoundPosition.seconds())
  }


  private get positionSliderMarks() {
    const marks = {}
    if (!this.sliderLengthIsCorrect) {
      return marks
    }
    const currentPos = this.props.currentSoundPosition
    if (!currentPos) {
      return marks
    }
    // Inactive sound marker marks
    this.props.soundMarkersOnDevice.forEach(m => this.addMarkForInactiveSoundMarker(marks, m, inactiveDeviceMarkerIcon))
    this.props.soundMarkersInSheet.forEach(m => this.addMarkForInactiveSoundMarker(marks, m, inactiveSheetMarkerIcon))
    // Active sound marker marks
    const activeMarker = this.props.activeSoundMarker
    if (!activeMarker) {
      return marks
    }
    const startInSeconds = activeMarker.positionInSeconds
    const endInSeconds = startInSeconds !== undefined && activeMarker.regionLengthInSeconds !== undefined
      ? startInSeconds + activeMarker.regionLengthInSeconds
      : undefined
    const MarkerIcon = getMarkerIconComponent(activeMarker, activeMarker.listInfo.type)
    const markerIcon = <MarkerIcon fontSize="inherit"/>
    if (startInSeconds !== undefined && endInSeconds === undefined) {
      this.addMarkForActiveSoundMarker(marks, startInSeconds, markerIcon)
    } else if (startInSeconds !== undefined && endInSeconds !== undefined) {
      this.addMarkForActiveSoundMarker(marks, startInSeconds, <span>{markerIcon}<ArrowRightIcon
        fontSize="inherit"/></span>)
      this.addMarkForActiveSoundMarker(marks, endInSeconds, <span><ArrowLeftIcon
        fontSize="inherit"/>{markerIcon}</span>)
    }
    return marks
  }

  private get inactiveMarkerColor() {
    return this.props.theme.palette.grey['400']
  }

  private get activeMarkerColor() {
    return this.props.theme.palette.action.active
  }


  private addMarkForInactiveSoundMarker(marks: any, soundMarker: PositionalSoundMarker, label: any) {
    const activeMarker = this.props.activeSoundMarker
    if (activeMarker && activeMarker.positionInSeconds === soundMarker.positionInSeconds) {
      return
    }
    marks[soundMarker.positionInSeconds] = {
      label,
      style: {
        color: this.inactiveMarkerColor
      }
    }
  }

  private handleSliderGrabbed = (value: number) => {
    this.setState({
      sliderIsGrabbed: true,
      sliderGrabPosition: value
    })
  }

  private handleSliderChanged = (value: number) => {
    this.setState({
      sliderGrabPosition: value
    })
  }

  private handleSliderReleased = (value: number) => {
    // Reentrancy check (see below)
    if (this.alreadyHandlingSliderReleased) {
      return
    }
    this.alreadyHandlingSliderReleased = true
    // Don't let the slider handle keep focus because that will cause the slider to move later
    // on key presses and when clicking somewhere else. This is super annoying. However, blurring
    // the element triggers onAfterChange again, so we need the above reentrancy check.
    if (document.activeElement instanceof HTMLElement) {
      document.activeElement.blur()
    }
    // Execute the actual logic
    this.setState({
      sliderIsGrabbed: false
    })
    this.props.seekToSoundPosition(Duration.ofSeconds(value))
    this.alreadyHandlingSliderReleased = false
  }

  private addMarkForActiveSoundMarker(marks: any, positionInSeconds: number, label: any) {
    marks[positionInSeconds] = {
      label,
      style: {
        color: this.activeMarkerColor,
        fontWeight: 'bold',
        zIndex: 1
      }
    }
  }
}

export const SeekSliderPanel = withTheme(withStyles(styles)(RawSeekSliderPanel))