import {saveAs} from 'file-saver'
// @ts-ignore
import streamSaver from 'streamsaver'

export function downloadCompleteFileViaStreamingIsSupported() {
  return streamSaver.supported
}

export async function downloadCompleteFileViaStreaming(url: URL, fileName: string) {
  const res = await fetch(url.toString())
  downloadFetchResponseViaStreaming(res, fileName)
}

export function downloadPartialFileIsSupported(url: URL) {
  const isBlob = url.toString().startsWith('blob')
  return isBlob || streamSaver.supported
}

export async function downloadPartialFile(url: URL, fileName: string, startInBytes: number, lengthInBytes: number) {
  const endInBytes = startInBytes + lengthInBytes
  const isBlob = url.toString().startsWith('blob')
  if (isBlob) {
    // Offline files can be cropped very easily and fast
    const res = await fetch(url.toString())
    const blob = await res.blob()
    const croppedBlob = blob.slice(startInBytes, endInBytes)
    saveAs(croppedBlob, fileName)
  } else {
    // Remote files should be requested via range query in order to not load the complete file
    const res = await fetch(url.toString(), {
      headers: {
        'Range': `bytes=${startInBytes}-${endInBytes}`
      }
    })
    if (res.status === 206) {
      // Range request successful
      downloadFetchResponseViaStreaming(res, fileName)
    } else {
      // Range request not supported. Must seek manually!
      // TODO This is a very rare case. Either print an error message or indicate that this might take a bit for
      // markers which are not at the beginning of the file.
      downloadFetchResponsePartiallyViaStreaming(res, fileName, startInBytes, lengthInBytes)
    }
  }
}

function downloadFetchResponseViaStreaming(res: Response, fileName: string) {
  if (!res.body) {
    return
  }
  const contentLength = res.headers.get('Content-Length')
  const queuingStrategy = new CountQueuingStrategy({ highWaterMark: 1 })
  const fileStream = streamSaver.createWriteStream(fileName, queuingStrategy, contentLength || undefined)
  const anyRes = res as any
  if (!anyRes.body.pipeTo) {
    const writer = fileStream.getWriter()
    const reader = res.body.getReader()
    const pump = () => reader.read().then(({value, done}) => {
      if (done) {
        writer.close()
      } else {
        writer.write(value).then(pump)
      }
    })
    // Start the reader
    pump()
    return
  }
  anyRes.body.pipeTo(fileStream)
}

function downloadFetchResponsePartiallyViaStreaming(res: Response, fileName: string, startInBytes: number,
                                                    lengthInBytes: number) {
  if (!res.body) {
    return
  }
  const queuingStrategy = new CountQueuingStrategy({highWaterMark: 1})
  const fileStream = streamSaver.createWriteStream(fileName, queuingStrategy, lengthInBytes)
  const writer = fileStream.getWriter()
  const reader = res.body.getReader()
  const endInBytes = startInBytes + lengthInBytes
  let totalBytesReceived = 0
  const pump = () => reader.read().then(({value, done}) => {
    if (done) {
      writer.close()
      return
    }
    totalBytesReceived += value.length
    if (totalBytesReceived < startInBytes) {
      pump()
      return
    }
    writer.write(value).then(() => {
      if (totalBytesReceived >= endInBytes) {
        reader.cancel()
        writer.close()
        return
      }
      pump()
    })
  })
  // Start the reader
  pump()
}