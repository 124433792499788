export enum TopView {
  AllSheets = 'AllSheets',
  Sheet = 'Sheet',
  QuickMarkers = 'QuickMarkers',
  Settings = 'Settings',
}

export function getTopViewLabel(topView: TopView) {
  switch (topView) {
    case TopView.AllSheets:
      return 'Sheet markers'
    case TopView.Sheet:
      return 'Sheets'
    case TopView.QuickMarkers:
      return 'List markers'
    case TopView.Settings:
    default:
      return 'Settings'
  }
}

export function getTopViewIndex(topView: TopView) {
  switch (topView) {
    case TopView.AllSheets:
      return 0
    case TopView.Sheet:
      return 1
    case TopView.QuickMarkers:
      return 2
    case TopView.Settings:
    default:
      return 3
  }
}

export function getTopViewByIndex(index: number) {
  switch (index) {
    case 0:
      return TopView.AllSheets
    case 1:
      return TopView.Sheet
    case 2:
      return TopView.QuickMarkers
    case 3:
    default:
      return TopView.Settings
  }
}
