import * as React from 'react'
import {Component, ReactNode} from 'react'
import {createStyles, Hidden, Theme, withStyles, WithStyles} from '@material-ui/core'
import 'rc-slider/assets/index.css'

export interface DataProps {
  children: ReactNode[]
}

export interface State {
  indexOfDisplayedChild: number
}

const styles = (theme: Theme) => createStyles({
  switchPanel: {
    minWidth: 0,
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
  }
})

class RawLimitedSpacePanel extends Component<DataProps & WithStyles<typeof styles>, State> {
  private filteredChildren: ReactNode[] = []

  public state: State = {
    indexOfDisplayedChild: 0
  }

  public render() {
    return (
      <>
        <Hidden smUp={true} implementation="js">
          <div className={this.props.classes.switchPanel} onClick={this.switchChild}>
            {this.selectedChild}
          </div>
        </Hidden>
        <Hidden xsDown={true} implementation="js">
          {this.filteredChildren}
        </Hidden>
      </>
    )
  }

  public componentWillReceiveProps(nextProps: DataProps) {
    this.filteredChildren = nextProps.children.filter(it => it)
  }

  private get selectedChild() {
    const idx = this.state.indexOfDisplayedChild
    return this.filteredChildren[Math.min(idx, this.filteredChildren.length - 1)]
  }

  private switchChild = () => {
    this.setState(prevState => {
      return {
        indexOfDisplayedChild: (prevState.indexOfDisplayedChild + 1) % this.filteredChildren.length
      }
    })
  }
}

export const LimitedSpacePanel = withStyles(styles)(RawLimitedSpacePanel)