import {ApplicationState} from '../store/ApplicationState'
import {connect} from 'react-redux'
import {Dispatch} from 'redux'
import {DataProps, DispatchProps, SoundInfoPanel} from '../components/SoundInfoPanel'
import {
  activeSoundMarkerSelector,
  currentlyPlayingSheetTitleSelector,
  currentPlaylistIndexSelector,
  currentPlaylistSizeSelector,
  soundLengthSelector,
  soundPositionSelector
} from '../store/player/selectors'
import {Duration} from 'js-joda'

function mapStateToProps(state: ApplicationState): DataProps {
  return {
    metadata: state.player.soundMetadata,
    soundLoaded: state.player.soundLoaded,
    loading: state.player.loading,
    currentSoundPosition: state.layout.screenIsVisible ? soundPositionSelector(state) : Duration.ZERO,
    activeSoundMarker: activeSoundMarkerSelector(state),
    soundLength: soundLengthSelector(state),
    currentPlaylistIndex: currentPlaylistIndexSelector(state),
    currentPlaylistSize: currentPlaylistSizeSelector(state),
    currentlyPlayingSheetTitle: currentlyPlayingSheetTitleSelector(state),
  }
}

function mapDispatchToProps(dispatch: Dispatch): DispatchProps {
  return {
  }
}

export const SoundInfoPanelContainer = connect(mapStateToProps, mapDispatchToProps)(SoundInfoPanel)