import {defer, EMPTY, forkJoin, fromEvent, merge, Observable} from 'rxjs'
import {daydreamServiceUuid, obtainEventsFromDaydreamController} from '../util/controller/daydream'
import {ControllerChangeEvent, createConnectionEvent} from '../model/controller/ControllerChangeEvent'
import {mapTo, shareReplay, switchMap} from 'rxjs/operators'
import {ControllerConnectionState} from '../model/controller/ControllerConnectionState'

export function controllersAreSupported() {
  return navigator.bluetooth !== undefined
}

export function connectToController(disconnectRequested$: Observable<any>): Observable<ControllerChangeEvent> {
  const device$ = defer(() => obtainControllerDevice()).pipe(
    shareReplay()
  )
  const connectingEvent$ = device$.pipe(
    mapTo(createConnectionEvent(ControllerConnectionState.Connecting))
  )
  const disconnectedEvent$ = device$.pipe(
    switchMap(dev => fromEvent(dev as any, 'gattserverdisconnected')),
    mapTo(createConnectionEvent(ControllerConnectionState.Disconnected))
  )
  const connectedGattServer$ = device$.pipe(
    switchMap(connectToGattServer),
    shareReplay()
  )
  const disconnectRequestedHandler$ = forkJoin(connectedGattServer$, disconnectRequested$).pipe(
    switchMap(array => {
      const gatt = array[0]
      if (gatt.connected) {
        gatt.disconnect()
      }
      return EMPTY
    })
  )
  const controllerSpecificEvent$ = obtainEventsFromDaydreamController(connectedGattServer$)
  return merge(
    connectingEvent$,
    disconnectedEvent$,
    disconnectRequestedHandler$,
    controllerSpecificEvent$
  )
}


async function obtainControllerDevice(): Promise<BluetoothDevice> {
  if (!navigator.bluetooth) {
    throw Error("Browser doesn't support bluetooth web API")
  }
  if (navigator.bluetooth.getAvailability) {
    const bluetoothIsAvailable = await navigator.bluetooth.getAvailability()
    if (!bluetoothIsAvailable) {
      throw Error('Bluetooth not available')
    }
  }
  const deviceIdKey = 'controllerDeviceId'
  const deviceId = sessionStorage.getItem(deviceIdKey) || undefined
  const device = await requestController(deviceId)
  sessionStorage.setItem(deviceIdKey, device.id)
  return device
}

async function requestController(deviceId: string | undefined): Promise<BluetoothDevice> {
  if (!deviceId) {
    return requestControllerByFilter()
  }
  const device = await queryControllerByDeviceId(deviceId)
  if (!device) {
    return requestControllerByFilter()
  }
  return device
}

async function queryControllerByDeviceId(deviceId: string): Promise<BluetoothDevice | undefined> {
  try {
    const nav = navigator as any
    if (!nav.permissions) {
      return undefined
    }
    const result = await nav.permissions.query({
      name: 'bluetooth',
      deviceId
    })
    return result.devices.length === 1 && result.devices[0]
  } catch (error) {
    // Permission type 'bluetooth' probably not defined in Browser (currently not supported in Chrome)
    return undefined
  }
}

async function requestControllerByFilter(): Promise<BluetoothDevice> {
  const device = await navigator.bluetooth.requestDevice({
    filters: [
      {
        services: [daydreamServiceUuid]
      }
    ]
  })
  if (!device) {
    throw Error("Couldn't connect to controller")
  }
  return device
}


async function connectToGattServer(dev: BluetoothDevice) {
  const gatt = dev.gatt
  if (!gatt) {
    throw Error("Couldn't access GATT server")
  }
  if (gatt.connected) {
    return gatt
  } else {
    return gatt.connect()
  }
}

