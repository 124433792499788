import * as actions from './actions'
import {
  loadOrCleanUpSheet,
  scrollTopChanged,
  sheetContentChanged,
  sheetContentCleared,
  sheetInfosLoadingFinished,
  sheetInfoUpdated,
  sheetLoadingStarted, visibleSheetChanged
} from './actions'
import {ActionType, isActionOf} from 'typesafe-actions'
import {ApplicationState} from '../ApplicationState'
import {combineEpics, Epic} from 'redux-observable'
import {
  buffer,
  debounceTime,
  distinctUntilChanged,
  filter,
  map,
  mapTo, mergeMap,
  share,
  skip,
  switchMap,
  tap
} from 'rxjs/operators'
import {concat, defer, EMPTY, from, Observable, of} from 'rxjs'
import {handleError, handleInfo, onlineStateChanged} from '../layout/actions'
import {createSheetEditPath, createSheetViewPath, Routes} from '../Routes'
import {push} from 'connected-react-router'
import {
  currentlyVisibleSheetIdSelector,
  currentlyVisibleSheetSoundMarkersSelector,
  currentlyVisibleSheetTitleSelector,
  isWelcomeSheetSelector,
  requestedSheetIdSelector
} from './selectors'
import {checkIfOnline} from '../../util/layout-util'
import * as sheetService from '../../services/SheetService'
import {sheetRemark} from '../../util/markdown-util'
import {extractSheetSoundMarkers, extractSheetTitle} from '../../util/sheet-markdown-util'
import {
  getBuiltInSheetContent,
  getInitialHomeSheetContent,
  getInitialSheetContent,
  getWelcomeSheetContent
} from './content'
import {homeSheetId, isBuiltInSheet} from '../../util/sheet-ids'
import {signInIsNecessarySelector} from '../layout/selectors'
import * as log from 'loglevel'
import {SheetChange} from '../../model/SheetChange'
import {merge} from 'rxjs/internal/observable/merge'


const extendedActions = {
  ...actions,
  handleError,
  handleInfo,
  onlineStateChanged,
  push
}
type SheetAction = ActionType<typeof extendedActions>
type SheetEpic = Epic<SheetAction, SheetAction, ApplicationState>

const loadOrCleanUpSheetEpic: SheetEpic = (action$, state$) => action$.pipe(
  filter(isActionOf(actions.loadOrCleanUpSheet)),
  switchMap(action => {
    return loadOrCleanUpSheetInternal(state$.value)
  })
)

function loadOrCleanUpSheetInternal(state: ApplicationState) {
  const sheetId = requestedSheetIdSelector(state)
  if (!sheetId) {
    return of(sheetContentCleared())
  }
  const builtInSheetContent = getBuiltInSheetContent(sheetId)
  if (builtInSheetContent) {
    return of(
      sheetLoadingStarted(),
      visibleSheetChanged({sheetContent: builtInSheetContent, sheetId}),
    )
  }
  let signInIsNecessary = signInIsNecessarySelector(state)
  if (signInIsNecessary === undefined || signInIsNecessary) {
    return EMPTY
  }
  const isOnline = checkIfOnline()
  return concat(
    of(onlineStateChanged({isOnline})),
    of(sheetLoadingStarted()),
    from(sheetService.getOfflineSheetContent(sheetId)).pipe(
      switchMap((sheetContent): Observable<SheetAction> => {
        if (sheetContent !== undefined) {
          return of(visibleSheetChanged({sheetId, sheetContent}))
        } else if (sheetId === homeSheetId) {
          return of(visibleSheetChanged({sheetId, sheetContent: getWelcomeSheetContent()}))
        } else {
          return of(sheetContentCleared())
        }
      })
    )
  )
}

const initSheetDataEpic: SheetEpic = (action$, state$) => action$.pipe(
  filter(isActionOf(actions.initSheetData)),
  switchMap(() => {
    return concat(
      defer(() => loadOrCleanUpSheetInternal(state$.value)),
      defer(() => updateAllSheetInfosInternal()),
      of(sheetInfosLoadingFinished()),
    )
  })
)

const processUpdatedSheetsEpic: SheetEpic = (action$, state$) => action$.pipe(
  filter(isActionOf(actions.processUpdatedSheets)),
  switchMap(action => {
    const visibleSheetId = currentlyVisibleSheetIdSelector(state$.value)
    return merge(
      updateSheetInfosInternal(action.payload.sheetIds),
      visibleSheetId && action.payload.sheetIds.includes(visibleSheetId) ? of(loadOrCleanUpSheet()) : EMPTY
    )
  })
)

function updateAllSheetInfosInternal() {
  return from(sheetService.getLocalSheetIds()).pipe(
    mergeMap(updateSheetInfosInternal)
  )
}

function updateSheetInfosInternal(sheetIds: string[]) {
  log.debug('# Updating sheet infos...')
  return sheetService.getSheetContents(sheetIds).pipe(
    map(({sheetId, content}) => updateSheetInfoInternal(sheetId, content)),
    tap({
      complete: () => log.debug('Updating sheet infos finished')
    })
  )
}

function updateSheetInfoInternal(sheetId: string, content: string) {
  const sheetNode = sheetRemark.parse(content)
  return sheetInfoUpdated({
    sheetId,
    info: {
      id: sheetId,
      title: extractSheetTitle(sheetNode),
      soundMarkers: extractSheetSoundMarkers(sheetNode)
    }
  })
}

const handleScrollEpic: SheetEpic = (action$, state$) => action$.pipe(
  filter(isActionOf(actions.handleScroll)),
  debounceTime(500),
  map(action => {
    return scrollTopChanged(action.payload)
  })
)

// Update from editor
const updateSheetContentEpic: SheetEpic = (action$, state$) => {
  const sharedAction$ = action$.pipe(
    filter(isActionOf(actions.updateSheetContent)),
    share()
  )
  return sharedAction$.pipe(
    buffer(sharedAction$.pipe(debounceTime(1000))),
    switchMap(actions => {
      // We need to save locally here because sheet service is not called again
      const lastAction = actions[actions.length - 1]
      const sheetChanges: SheetChange[] = actions.map(a => ({
        sheetId: a.payload.sheetId,
        textChange: a.payload.change
      }))
      sheetService.saveSheetChanges(sheetChanges)
      // TODO What if actions come from different sheets? Unlikely but you know ...
      const {sheetContent, sheetId} = lastAction.payload
      return of(sheetContentChanged({sheetContent, sheetId}))
    })
  )
}

// Executed *after* sheetContent changed in store. That's important. Otherwise the selectors would get stale data.
const sheetContentChangedEpic: SheetEpic = (action$, state$) => action$.pipe(
  filter(isActionOf(actions.sheetContentChanged)),
  switchMap(action => {
    const sheetId = action.payload.sheetId
    if (!sheetId || isBuiltInSheet(sheetId)) {
      return EMPTY
    }
    return of(
      sheetInfoUpdated({
        sheetId,
        info: {
          id: sheetId,
          title: currentlyVisibleSheetTitleSelector(state$.value),
          soundMarkers: currentlyVisibleSheetSoundMarkersSelector(state$.value)
        }
      })
    )
  })
)

const createNewSheetEpic: SheetEpic = (action$, state$) => action$.pipe(
  filter(isActionOf(actions.createNewSheet)),
  switchMap(action => {
    const requestedSheetId = requestedSheetIdSelector(state$.value)
    if (!requestedSheetId) {
      return EMPTY
    }
    return from(sheetService.createNewSheet(requestedSheetId)).pipe(
      // TODO Filter maybe not necessary anymore in future
      filter(sheetId => sheetId !== undefined),
      switchMap(() => of(
        sheetContentChanged({
          sheetId: requestedSheetId,
          sheetContent: getInitialSheetContent(requestedSheetId)
        }),
        push(createSheetEditPath(requestedSheetId))
      ))
    )
  })
)

const createHomeSheetEpic: SheetEpic = (action$, state$) => action$.pipe(
  filter(isActionOf(actions.createHomeSheet)),
  switchMap(action => {
    const homeSheetContent = getInitialHomeSheetContent()
    return from(sheetService.createNewSheet(homeSheetId, homeSheetContent)).pipe(
      // TODO Filter maybe not necessary anymore in future
      filter(sheetId => sheetId !== undefined),
      mapTo(push(createSheetViewPath(homeSheetId)))
    )
  })
)

const enterSheetEditModeEpic: SheetEpic = (action$, state$) => action$.pipe(
  filter(isActionOf(actions.enterSheetEditMode)),
  switchMap(action => {
    const currentSheetId = currentlyVisibleSheetIdSelector(state$.value)
    if (!currentSheetId) {
      return EMPTY
    }
    if (currentSheetId === homeSheetId && isWelcomeSheetSelector(state$.value)) {
      return of(push(Routes.CreateHomeSheet))
    }
    return of(push(createSheetEditPath(currentSheetId)))
  })
)

const refreshEpic: SheetEpic = (action$, state$) => action$.pipe(
  filter(isActionOf(actions.refresh)),
  filter(() => {
    sheetService.sync()
    return false
  })
)

const leaveSheetEditModeEpic: SheetEpic = (action$, state$) => action$.pipe(
  filter(isActionOf(actions.leaveSheetEditMode)),
  switchMap(action => {
    const currentSheetId = requestedSheetIdSelector(state$.value)
    if (!currentSheetId) {
      return EMPTY
    }
    sheetService.commitAndSync(currentSheetId)
    return of(push(createSheetViewPath(currentSheetId)))
  })
)


const loadOrCleanUpSheetWheneverSheetUrlChangesEpic: SheetEpic = (action$, state$) => state$.pipe(
  map(state => requestedSheetIdSelector(state)),
  distinctUntilChanged(),
  // First sheet ID is handled by initSheetData right on startup, so this is only for
  // all subsequent ones!
  skip(1),
  map(() => loadOrCleanUpSheet())
)

export const sheetEpic = combineEpics(
  loadOrCleanUpSheetEpic,
  createNewSheetEpic,
  enterSheetEditModeEpic,
  leaveSheetEditModeEpic,
  updateSheetContentEpic,
  loadOrCleanUpSheetWheneverSheetUrlChangesEpic,
  initSheetDataEpic,
  sheetContentChangedEpic,
  handleScrollEpic,
  createHomeSheetEpic,
  processUpdatedSheetsEpic,
  refreshEpic,
)