import * as actions from './actions'
import {controllerConnectionStateChanged, disconnectController, magicButtonPressed} from './actions'
import {ActionType, isActionOf} from 'typesafe-actions'
import {ApplicationState} from '../ApplicationState'
import {combineEpics, Epic} from 'redux-observable'
import {buffer, catchError, debounceTime, filter, map, mapTo, share, startWith, switchMap, take} from 'rxjs/operators'
import {concat, EMPTY, merge, Observable, of, timer} from 'rxjs'
import {handleError, handleInfo} from '../layout/actions'
import {ControllerConnectionState} from '../../model/controller/ControllerConnectionState'
import {ControllerButtonChangeEvent, ControllerTouchpadChangeEvent} from '../../model/controller/ControllerChangeEvent'
import {
  changePlaybackRate,
  playNextDeviceSoundMarker,
  playNextSheetSoundMarker,
  playPreviousDeviceSoundMarker,
  playPreviousSheetSoundMarker,
  seekRelatively,
  togglePlayPause
} from '../player/actions'
import {toggleSoundMarker} from '../marker/actions'
import {ControllerChangeEventType} from '../../model/controller/ControllerChangeEventType'
import {ControllerButton} from '../../model/controller/ControllerButton'
import {ControllerTouchpadAxis} from '../../model/controller/ControllerTouchpadAxis'
import * as controllerService from '../../services/ControllerService'

const extendedActions = {
  ...actions,
  handleInfo,
  handleError,
  seekRelatively,
  playNextSheetSoundMarker,
  playPreviousSheetSoundMarker,
  playNextDeviceSoundMarker,
  playPreviousDeviceSoundMarker,
  togglePlayPause,
  toggleSoundMarker,
  changePlaybackRate
}
type ControllerAction = ActionType<typeof extendedActions>
type ControllerEpic = Epic<ControllerAction, ControllerAction, ApplicationState>

function getConnectionInfoMessage(connectionState: ControllerConnectionState) {
  switch (connectionState) {
    case ControllerConnectionState.Disconnected:
      return 'Controller disconnected'
    case ControllerConnectionState.Connecting:
      return 'Connecting controller...'
    case ControllerConnectionState.Connected:
      return 'Controller connected'
  }
}

enum SwipeDirection {
  Left,
  Right,
  Up,
  Down
}

const magicButtonPressedEpic: ControllerEpic = (action$, state$) => {
  const magicButtonPressedAction$ = action$.pipe(
    filter(isActionOf(actions.magicButtonPressed)),
    share()
  )
  return magicButtonPressedAction$.pipe(
    buffer(magicButtonPressedAction$.pipe(debounceTime(500))),
    switchMap(clicks => {
      if (clicks.length === 1) {
        return of(togglePlayPause())
      } else if (clicks.length >= 2) {
        return of(toggleSoundMarker({
          rating: convertNumClicksToRating(clicks.length)
        }))
      } else {
        return EMPTY as Observable<ControllerAction>
      }
    })
  )
}

const connectControllerEpic: ControllerEpic = (action$, state$) => action$.pipe(
  filter(isActionOf(actions.connectController)),
  switchMap(action => {
    const disconnectInvoked$ = action$.pipe(filter(isActionOf(disconnectController))).pipe(
      take(1)
    )
    const daydreamChangeEvents$ = controllerService.connectToController(disconnectInvoked$).pipe(
      share()
    )
    const touchpadButtonEvents$ = daydreamChangeEvents$.pipe(
      filter(evt => evt.type === ControllerChangeEventType.Button && evt.button === ControllerButton.Touchpad),
      map(evt => evt as ControllerButtonChangeEvent),
      share()
    )
    const magicButtonPressedEvents$ = touchpadButtonEvents$.pipe(
      filter(evt => evt.buttonIsPressed),
      mapTo(magicButtonPressed())
    )
    const touchpadChanges$ = daydreamChangeEvents$.pipe(
      filter(evt => evt.type === ControllerChangeEventType.Touchpad),
      map(evt => evt as ControllerTouchpadChangeEvent),
      share()
    )
    const letTouchpadChangesThrough$ = touchpadButtonEvents$.pipe(
      startWith(true),
      switchMap(() => concat(
        // Button event arrived. Don't let through for now.
        of(false),
        // But let them through again after some time passed without another button press (each new incoming button
        // press will restart the timer because we use switchMap, not mergeMap)
        timer(500).pipe(
          mapTo(true)
        )
      )),
    )
    const isolatedTouchpadChanges$ = letTouchpadChangesThrough$.pipe(
      switchMap(letThrough => letThrough ? touchpadChanges$ : EMPTY),
      share()
    )
    const isolatedTouchpadXChanges$ = isolatedTouchpadChanges$.pipe(
      filter(evt => evt.axis === ControllerTouchpadAxis.X),
      map(evt => evt.value)
    )
    const isolatedTouchpadYChanges$ = isolatedTouchpadChanges$.pipe(
      filter(evt => evt.axis === ControllerTouchpadAxis.Y),
      map(evt => evt.value)
    )
    const touchpadXSwipes$ = filterSwipes(isolatedTouchpadXChanges$).pipe(
      map(dir => dir < 0 ? SwipeDirection.Left : SwipeDirection.Right)
    )
    const touchpadYSwipes$ = filterSwipes(isolatedTouchpadYChanges$).pipe(
      map(dir => dir < 0 ? SwipeDirection.Up : SwipeDirection.Down)
    )
    const touchpadSwipeReactions$ = merge(touchpadXSwipes$, touchpadYSwipes$).pipe(
      switchMap(swipeDirection => {
        switch (swipeDirection) {
          case SwipeDirection.Left:
            return of(seekRelatively({direction: -1}))
          case SwipeDirection.Right:
            return of(seekRelatively({direction: +1}))
          default:
            return EMPTY as Observable<ControllerAction>
        }
      })
    )
    const otherReactions$ = daydreamChangeEvents$.pipe(
      switchMap(evt => {
        switch (evt.type) {
          case ControllerChangeEventType.Connection: {
            return of(
              handleInfo(getConnectionInfoMessage(evt.connectionState)),
              controllerConnectionStateChanged({
                connectionState: evt.connectionState
              }),
            )
          }
          case ControllerChangeEventType.Button: {
            if (!evt.buttonIsPressed) {
              return EMPTY
            }
            switch (evt.button) {
              case ControllerButton.VolumeUp:
                return of(playPreviousSheetSoundMarker())
              case ControllerButton.VolumeDown:
                return of(playNextSheetSoundMarker())
              case ControllerButton.App:
                return of(playPreviousDeviceSoundMarker())
              case ControllerButton.Home:
                return of(playNextDeviceSoundMarker())
              default:
                return EMPTY as Observable<ControllerAction>
            }
          }
          default:
            return EMPTY
        }
      }),
    )
    return merge(
      touchpadSwipeReactions$,
      magicButtonPressedEvents$,
      otherReactions$
    ).pipe(
      catchError(error => of(
        handleError(error.toString()),
        controllerConnectionStateChanged({connectionState: ControllerConnectionState.Disconnected}),
      )),
    )
  })
)

function convertNumClicksToRating(numClicks: number) {
  if (numClicks < 3) {
    return undefined
  } else if (numClicks < 5) {
    return numClicks
  } else {
    return 5
  }
}

// Resulting number represents direction of swipe on the corresponding axis: -1 or +1
function filterSwipes(values$: Observable<number>): Observable<number> {
  const sharedValues$ = values$.pipe(share())
  return sharedValues$.pipe(
    // 0 signals end of gesture
    filter(value => value !== 0),
    buffer(sharedValues$.pipe(filter(value => value === 0))),
    switchMap(values => {
      if (values.length < 5) {
        // Very short swipes are not considered
        return EMPTY
      }
      let firstDirection = 0
      // Don't consider last values. It will be zero because Daydream automatically resets to zero.
      for (let i = 1; i < values.length - 1; i++) {
        const difference = values[i] - values[i - 1]
        if (difference === 0) {
          // Two consecutive equal values don't look like a swipe
          return EMPTY
        }
        const direction = Math.sign(difference)
        if (firstDirection === 0) {
          firstDirection = direction
        } else if (direction !== firstDirection) {
          // Direction changes don't look like a swipe
          return EMPTY
        }
      }
      return of(firstDirection)
    })
  )
}

export const controllerEpic = combineEpics(
  connectControllerEpic,
  magicButtonPressedEpic,
)