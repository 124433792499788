import {Reducer} from 'redux'
import {ActionType, getType} from 'typesafe-actions'
import * as actions from './actions'
import {SettingsState} from './SettingsState'
import {PlaylistMode} from '../../model/PlaylistMode'
import {SoundMarkerFilter} from '../../model/SoundMarkerFilter'
import {persistReducer} from 'redux-persist'
import {mainStorage} from '../../util/main-storage'

type SettingsAction = ActionType<typeof actions>

const clearedFilter: SoundMarkerFilter = {
  minRating: 0,
  searchExpression: undefined,
  availableOffline: false,
  author: undefined,
  soundMarkerType: undefined,
}

const initialSettingsState: SettingsState = {
  showSoundSourceInput: true,
  showSoundStreamUrl: false,
  showSoundMetadata: true,
  showSeekSliderPanel: true,
  showSoundPlayerPanel: true,
  showPlaybackRateControls: false,
  showToolPanel: true,
  playlistMode: PlaylistMode.Off,
  filterEnabled: false,
  filter: clearedFilter,
  markerToggleSluggishnessInSeconds: 10,
  maxMarkerColoringDistanceInSeconds: 120,
  markerToggleLookBehindTimeInSeconds: 3,
  playPreviousMarkerIfWithinSeconds: 3,
  seekDistanceInSeconds: 5,
  vibrationsAreEnabled: true,
  beepsAreEnabled: true,
  speechIsEnabled: true,
  teaserLengthInSeconds: 30,
  energySavingMode: false,
  themeId: 'red-and-orange',
  useRealFullScreen: false,
}

const rawSettingsReducer: Reducer<SettingsState, SettingsAction> = (state = initialSettingsState, action) => {
  switch (action.type) {
    case getType(actions.showOrHideSoundSourceInput):
      return {
        ...state,
        showSoundSourceInput: action.payload
      }
    case getType(actions.showOrHideSoundSoundStreamUrl):
      return {
        ...state,
        showSoundStreamUrl: action.payload
      }
    case getType(actions.showOrHideSoundMetadata):
      return {
        ...state,
        showSoundMetadata: action.payload
      }
    case getType(actions.showOrHideSeekSliderPanel):
      return {
        ...state,
        showSeekSliderPanel: action.payload
      }
    case getType(actions.showOrHideSoundPlayerPanel):
      return {
        ...state,
        showSoundPlayerPanel: action.payload
      }
    case getType(actions.showOrHideToolPanel):
      return {
        ...state,
        showToolPanel: action.payload
      }
    case getType(actions.showOrHidePlaybackRateControls):
      return {
        ...state,
        showPlaybackRateControls: action.payload
      }
    case getType(actions.setPlaylistMode):
      return {
        ...state,
        playlistMode: action.payload.playlistMode
      }
    case getType(actions.setFilterEnabled):
      return {
        ...state,
        filterEnabled: action.payload.filterEnabled
      }
    case getType(actions.clearFilter):
      return {
        ...state,
        filter: clearedFilter
      }
    case getType(actions.setFilterMinRating):
      return {
        ...state,
        filter: {
          ...state.filter,
          minRating: action.payload.minRating
        }
      }
    case getType(actions.setFilterSearchExpression):
      return {
        ...state,
        filter: {
          ...state.filter,
          searchExpression: action.payload.searchExpression
        }
      }
    case getType(actions.setFilterAuthor):
      return {
        ...state,
        filter: {
          ...state.filter,
          author: action.payload.author
        }
      }
    case getType(actions.setFilterAvailableOffline):
      return {
        ...state,
        filter: {
          ...state.filter,
          availableOffline: action.payload.offlineMode
        }
      }
    case getType(actions.setFilterSoundMarkerType):
      return {
        ...state,
        filter: {
          ...state.filter,
          soundMarkerType: action.payload.soundMarkerType
        }
      }
    case getType(actions.setEnergySavingMode):
      return {
        ...state,
        energySavingMode: action.payload.energySavingMode
      }
    case getType(actions.enableOrDisableBeeps):
      return {
        ...state,
        beepsAreEnabled: action.payload
      }
    case getType(actions.enableOrDisableVibrations):
      return {
        ...state,
        vibrationsAreEnabled: action.payload
      }
    case getType(actions.enableOrDisableSpeech):
      return {
        ...state,
        speechIsEnabled: action.payload
      }
    case getType(actions.setUseRealFullScreen):
      return {
        ...state,
        useRealFullScreen: action.payload
      }
    case getType(actions.changeTheme):
      return {
        ...state,
        themeId: action.payload.themeId
      }
    default:
      return state
  }
}

export const settingsReducer = persistReducer(
  {key: 'settings', storage: mainStorage},
  rawSettingsReducer
)