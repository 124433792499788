// There are three different playlist types
import {SoundMarkerListType} from './SoundMarkerListType'

export enum PlaylistType {
  Sheet = 'Sheet', // plays sheet markers of a certain sheet (corresponds to SoundMarkerListType Sheet)
  Device = 'Device', // plays device markers
  AllSheets = 'AllSheets', // plays all sheet markers (corresponds to SoundMarkerListType Sheet)
}

export function convertPlaylistToMarkerListType(playlistType: PlaylistType | undefined) {
  switch (playlistType) {
    case PlaylistType.AllSheets:
    case PlaylistType.Sheet:
      return SoundMarkerListType.Sheet
    case PlaylistType.Device:
      return SoundMarkerListType.Device
    default:
      return undefined
  }
}