import {Reducer} from 'redux'
import {ActionType, getType} from 'typesafe-actions'
import * as actions from './actions'
import {LayoutState} from './LayoutState'
import {checkIfOnline} from '../../util/layout-util'
import {TopView} from '../../model/TopView'
import {isIOS, isSafari} from 'react-device-detect'
import {initAudio} from '../../services/SoundPlayer'

type LayoutAction = ActionType<typeof actions>

const initialState: LayoutState = {
  audioIsReady: !isIOS && !isSafari,
  snackbarOpen: false,
  isOnline: checkIfOnline(),
  persistentDrawerIsOpen: true,
  swipeableDrawerIsOpen: false,
  filterPanelIsOpen: false,
  installPromptAvailable: false,
  screenIsVisible: !document.hidden,
  authHasBeenChecked: false,
  logoIndex: 0,
  topView: TopView.Sheet,
  ufoAlarm: false,
  isFullScreen: false,
}

export const layoutReducer: Reducer<LayoutState, LayoutAction> = (state = initialState, action) => {
  switch (action.type) {
    case getType(actions.signedIn):
      return {
        ...state,
        signedInUser: action.payload.userInfo,
        authHasBeenChecked: true
      }
    case getType(actions.signedOut):
      return {
        ...state,
        signedInUser: undefined,
        authHasBeenChecked: true
      }
    case getType(actions.snackbarMessageChanged):
      return {
        ...state,
        snackbarOpen: true,
        snackbarMessage: action.payload
      }
    case getType(actions.clearSnackbarMessage):
      return {
        ...state,
        snackbarOpen: false,
      }
    case getType(actions.onlineStateChanged):
      return {
        ...state,
        isOnline: action.payload.isOnline
      }
    case getType(actions.showSwipeableDrawer):
      return {
        ...state,
        swipeableDrawerIsOpen: action.payload
      }
    case getType(actions.togglePersistentDrawer):
      return {
        ...state,
        persistentDrawerIsOpen: !state.persistentDrawerIsOpen,
      }
    case getType(actions.showFilterPanel):
      return {
        ...state,
        filterPanelIsOpen: action.payload
      }
    case getType(actions.installPromptAvailable):
      return {
        ...state,
        installPromptAvailable: true
      }
    case getType(actions.setScreenIsVisible):
      return {
        ...state,
        screenIsVisible: action.payload
      }
    case getType(actions.switchToNextLogo):
      return {
        ...state,
        logoIndex: state.logoIndex + 1
      }
    case getType(actions.enteredTopView):
      return {
        ...state,
        topView: action.payload.topView
      }
    case getType(actions.soundMarkerTargeted): {
      return {
        ...state,
        topView: action.payload.topView,
        targetedSoundMarker: action.payload.soundMarker
      }
    }
    case getType(actions.scrollingToTargetedSoundMarkerDone):
      return {
        ...state,
        targetedSoundMarker: undefined
      }
    case getType(actions.enableOrDisableUfoAlarm):
      return {
        ...state,
        ufoAlarm: action.payload
      }
    case getType(actions.initAudio):
      initAudio()
      return {
        ...state,
        audioIsReady: true
      }
    case getType(actions.setFullScreen):
      return {
        ...state,
        isFullScreen: action.payload
      }
    default:
      return state
  }
}