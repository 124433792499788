import * as React from 'react';
import {LogoProps} from './LogoProps'

export function InitialLogo(props: LogoProps) {
  const {theme, ...remainingProps} = props
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 200 200" {...remainingProps}>
      <circle cx="100" cy="100" r="90" fill="#f0f0f0" strokeWidth="15"
              stroke={theme.palette.primary.main}/>
      <polygon points="70, 55 70, 145 145, 100" fill={theme.palette.primary.main}/>
    </svg>
  )
}