import * as React from 'react'
import {Component} from 'react'
import {
  Button,
  createStyles,
  Divider,
  FormControl,
  FormControlLabel,
  FormLabel,
  InputLabel,
  List,
  ListItem,
  ListItemText,
  Radio,
  RadioGroup,
  Select,
  Switch,
  TextField,
  Theme,
  Typography,
  WithStyles,
  withStyles,
  WithTheme,
  withTheme
} from '@material-ui/core'
import {ControlRow} from './ControlRow'
import {SoundMarkerType} from '../model/SoundMarkerType'
import {ControlStack} from './ControlStack'

export interface DataProps {
  filterEnabled: boolean
  filterIsActive: boolean
  availableOffline: boolean
  minRating: number
  searchExpression?: string
  author?: string
  soundMarkerType?: SoundMarkerType
}

export interface DispatchProps {
  setFilterEnabled: (filterEnabled: boolean) => void
  setAvailableOffline: (availableOffline: boolean) => void
  setMinRating: (minRating: number) => void
  setSearchExpression: (searchExpression: string | undefined) => void
  setAuthor: (author: string | undefined) => void
  setSoundMarkerType: (soundMarkerType: SoundMarkerType | undefined) => void
  clear: () => void
}

const styles = (theme: Theme) => createStyles({
  headingDivider: {
    height: '2px',
    backgroundColor: theme.palette.primary.main,
  },
})

class RawFilterPanel extends Component<DataProps & DispatchProps & WithStyles<typeof styles> & WithTheme> {
  public render() {
    return (
      <div>
        <List dense={true}>
          <ListItem>
            <ControlRow style={{width: '100%'}}>
              <ControlStack style={{alignItems: 'flex-start'}}>
                <FormControlLabel
                  control={
                    <Switch
                      onChange={this.toggleFilterEnabled}
                      checked={this.props.filterEnabled}
                    />
                  }
                  label=""
                />
              </ControlStack>
              <ControlStack>
                <Typography variant="h6">Filter</Typography>
              </ControlStack>
              <ControlStack style={{alignItems: 'flex-end'}}>
                <Button disabled={!this.props.filterIsActive} color="secondary" variant="text"
                        onClick={this.clearFilter}>
                  Clear
                </Button>
              </ControlStack>
            </ControlRow>
          </ListItem>
          <Divider className={this.props.classes.headingDivider}/>
          <ListItem>
            <ListItemText>
              <FormControlLabel
                control={
                  <Switch
                    color="primary"
                    disabled={!this.props.filterEnabled}
                    onChange={this.toggleAvailableOffline}
                    checked={this.props.availableOffline}
                  />
                }
                label="Downloaded only"
              />
            </ListItemText>
          </ListItem>
          <ListItem>
            <FormControl fullWidth={true} disabled={!this.props.filterEnabled}>
              <InputLabel>Rated at least</InputLabel>
              <Select
                native={true}
                value={this.props.minRating}
                onChange={this.minRatingChanged}>
                <option value={0}/>
                {[1, 2, 3, 4, 5].map(i => <option key={i} value={i}>{i}</option>)}
              </Select>
            </FormControl>
          </ListItem>
          <ListItem>
            <FormControl fullWidth={true}>
              <TextField
                disabled={!this.props.filterEnabled}
                label="Name"
                onChange={this.searchExpressionChanged}
                fullWidth={true}
                value={this.props.searchExpression || ''}
              />
            </FormControl>
          </ListItem>
          <ListItem>
            <FormControl fullWidth={true}>
              <TextField
                disabled={!this.props.filterEnabled}
                label="Author"
                onChange={this.authorChanged}
                fullWidth={true}
                value={this.props.author || ''}
              />
            </FormControl>
          </ListItem>
          <ListItem>
            <FormControl disabled={!this.props.filterEnabled}>
              <FormLabel>Marker scope</FormLabel>
              <RadioGroup name="marker-type" value={this.props.soundMarkerType || ''}
                          onChange={this.soundMarkerTypeChanged}>
                <FormControlLabel
                  value=""
                  control={<Radio color="primary"/>}
                  label="All"
                />
                <FormControlLabel
                  value={SoundMarkerType.Position}
                  control={<Radio color="primary"/>}
                  label="Position markers"
                />
                <FormControlLabel
                  value={SoundMarkerType.File}
                  control={<Radio color="primary"/>}
                  label="File markers"
                />
              </RadioGroup>
            </FormControl>
          </ListItem>
        </List>
      </div>
    )
  }

  private clearFilter = () => {
    this.props.clear()
  }

  private toggleFilterEnabled = () => {
    this.props.setFilterEnabled(!this.props.filterEnabled)
  }

  private toggleAvailableOffline = () => {
    this.props.setAvailableOffline(!this.props.availableOffline)
  }

  private minRatingChanged = (event: any) => {
    this.props.setMinRating(parseInt(event.target.value, 10))
  }

  private searchExpressionChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.props.setSearchExpression(event.target.value || undefined)
  }

  private authorChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.props.setAuthor(event.target.value || undefined)
  }

  private soundMarkerTypeChanged = (event: React.ChangeEvent<{}>) => {
    this.props.setSoundMarkerType((event.target as any).value as SoundMarkerType || undefined)
  }

}

export const FilterPanel = withTheme(withStyles(styles)(RawFilterPanel))