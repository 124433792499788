import {ThemeDescription} from '../model/ThemeDescription'

export const themeDescriptions: ThemeDescription[] = [
  {
    id: 'blue-and-pink',
    name: 'Blue and pink',
    themeOptions: {
      palette: {
        primary: {
          main: '#01579b',
        },
        secondary: {
          // main: '#ff8f00',
          main: '#f50057',
        },
      },
    }
  },
  {
    id: 'blue-and-orange',
    name: 'Blue and orange',
    themeOptions: {
      palette: {
        primary: {
          main: '#01579b',
        },
        secondary: {
          // main: '#ff8f00',
          main: '#e65100',
        },
      },
    }
  },
  {
    id: 'red-and-orange',
    name: 'Red and orange',
    themeOptions: {
      palette: {
        primary: {
          main: '#7f0000',
        },
        secondary: {
          main: '#fbc02d',
        },
      },
    }
  },
  {
    id: 'grey-and-orange',
    name: 'Grey and orange',
    themeOptions: {
      palette: {
        primary: {
          main: '#37474f',
        },
        secondary: {
          main: '#ff8f00',
        },
      },
    }
  },
  {
    id: 'gray-and-orange-2',
    name: 'Gray and orange 2',
    themeOptions: {
      palette: {
        primary: {
          main: '#424242',
        },
        secondary: {
          main: '#ff6f00',
        },
      },
    }
  },
  {
    id: 'blue-and-red',
    name: 'Blue and red',
    themeOptions: {
      palette: {
        primary: {
          main: '#546e7a',
        },
        secondary: {
          main: '#f50057',
        },
      },
    }
  },
  {
    id: 'blue-and-red-2k',
    name: 'Blue and red 2',
    themeOptions: {
      palette: {
        primary: {
          main: '#01579b',
        },
        secondary: {
          main: '#c62828',
        },
      },
    }
  },
]

export function getThemeDescription(themeId: string) {
  return themeDescriptions.find(it => it.id === themeId)!
}